import React, {Component} from "react";
import {connect} from "react-redux";
import './index.css';
import Header from "../../../components/Header";
import CardAdditionAction from "../../../components/CardAdditionAction"
import CardModulesEvaluations from "../../../components/CardModulesEvaluations"
import ModeratorCardLatestComments from "../../../components/ModeratorCardLatestComments"
import {
    coursesCannotDelete,
    deleteCourse,
    loadAllCategories,
    loadAllComments,
    loadAllCourses
} from "../../../redux/moderator/courses-list/action-creators";
import {
    addCourse,
    clearElementCourse,
    loadCourseContent
} from "../../../redux/moderator/configure-course/actions-creators"
import AddCourseModal from "./components/add-course-modal";
import history from "../../../../utils/history";
import icon_edit from "./assets/icon-pencil.svg";
import icon_delete from "./assets/icon-trash.svg";
import {Translation} from 'react-i18next';
import Alert from "../../../components/Alert";
import {closeAlert, pushAlert} from "../../../redux/admin/clients-list/action-creators";
import i18n from "../../../../i18n";
import {uid} from "uid";
import Loader from "../../../components/Loader";

class ModeratorList extends Component {

    INITIAL_STATE = {
        is_create_course_modal_open: false,
        search_string: "",
        currentPage: 1,
        todosPerPage: 15,
        alert: "none"
    }

    state = {...this.INITIAL_STATE}

    componentDidMount() {
        // Dispatch load clients action
        this.props.loadAllCourses();
        this.props.loadAllCategories();
        this.props.loadAllComments();
    }

    handleClientCardClick = (title, id) => {
        history.push(`/moderator/course/${id}`);
    }

    handleAddClientClick = () => {
        history.push("/moderator/create_course");
        this.props.clearElementCourse();
        // this.setState({
        //     is_create_course_modal_open: true
        // });
    }

    handleAddClientModalCancel = () => {
        this.setState({
            is_create_course_modal_open: false
        });
    }

    handleAddClientModalSubmit = () => {
        const {addClient} = this.props;
        // Dispatch action to add client


        this.handleAddClientModalCancel();
    }

    handleAddCourseSubmit = (data) => {
        this.props.addCourse(data)
    }

    //Implement Search
    handleHeaderSearchChange = (search_string) => {
        
        this.setState({
            ...this.state,
            search_string: search_string,
            currentPage: 1,
            
        });
    }

    handleCourseEditOptionClick = (course_id) => {
        // Redirect to course configuration page with course id as param
        this.props.loadCourseContent(course_id);

        history.push(`/moderator/edit_course/${course_id}`, {course_id})
    }

    handleCourseDeleteOptionClick = (course_id) => {
        this.props.deleteCourse(course_id, () => {



           this.props.pushAlert(
               uid(),
               "success",
               i18n.t("moderator.courses_list.messages.delete_courses_success")
           )

        }, () => {

            // if(sessionExist){
            //     this.props.coursesCannotDelete(course_id);
            // }

            this.props.pushAlert(
                uid(),
                "danger",
                i18n.t("moderator.courses_list.messages.delete_courses_failed")
            )

        })
    }


    handleClick = (number) => {
        this.setState({
            currentPage: Number(number)
        });
    }

    render() {
        const {courses_list, category_list, comments_list,loading, courses_cannot_deleted} = this.props.moderator_courses_list;
        const {search_string} = this.state;
        const {current_user} = this.props.login;

        // Filter courses list based on search string
        const filtered_courses_list = courses_list.filter(obj => obj.course?.title?.toLowerCase().includes(search_string.toLowerCase()));


        const {currentPage, todosPerPage} = this.state;

        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = filtered_courses_list.slice(indexOfFirstTodo, indexOfLastTodo);


        return (
            <Translation>

                {t => {


                    const header_search_data = {
                        placeholder: t("header.search"),
                        search_string: search_string,
                        onChange: this.handleHeaderSearchChange
                    }

                    const renderTodos = currentTodos.map((anObjectMapped, index) => {

                        const course_card_options = [
                            {
                                name: t("moderator.edit_course_text"),
                                icon: icon_edit,
                                click_handler: () => current_user.expired ? null: this.handleCourseEditOptionClick(anObjectMapped.course.id)
                            },
                            {
                                name: t("moderator.delete_course_text"),
                                icon: icon_delete,
                                click_handler: () => current_user.expired ? null: this.handleCourseDeleteOptionClick(anObjectMapped.course.id)
                            }
                        ];

                        return <CardModulesEvaluations
                                    key={index}
                                    image={anObjectMapped.course.image_path}
                                    name={anObjectMapped.course.title}
                                    options={course_card_options}
                                    showOption={true}
                                    color={anObjectMapped.course.category.color}
                                    category={anObjectMapped.course.category.title}
                                    description={anObjectMapped.course.description}
                                    creation_date={anObjectMapped.course.creation_date}
                                    number_of_evaluations={anObjectMapped.number_of_evaluations}
                                    number_of_modules={anObjectMapped.number_of_modules}
                                    onClick={() => {
                                            this.handleClientCardClick(anObjectMapped.course.title, anObjectMapped.course.id)
                                        }
                                    }
                                />


                    });

                    // Logic for displaying page numbers
                    const pageNumbers = [];
                    for (let i = 1; i <= Math.ceil(filtered_courses_list.length / todosPerPage); i++) {
                        pageNumbers.push(i);
                    }

                    const renderPageNumbers = pageNumbers.map(number => {
                        return (
                            <div
                                key={number}
                                id={number}
                                onClick={() => {
                                    this.handleClick(number)
                                }}
                                style={number !== this.state.currentPage ? {
                                    border: "1px solid var(--secondary-color)",
                                    borderRadius: 5,
                                    cursor: "pointer",
                                    height: 30,
                                    width: 30,
                                    margin: 4,
                                    display: "flex"

                                } : {
                                    border: "1px solid var(--primary-color)",
                                    borderRadius: 5,
                                    cursor: "pointer",
                                    height: 30,
                                    width: 30,
                                    margin: 4,
                                    display: "flex",
                                    backgroundColor: "var(--primary-color)",
                                    color: "#fff"
                                }}>

                            <span style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                flex: 1,
                            }}>{number}</span>
                            </div>
                        );
                    });

                    return (
                        <div className="moderator_list_page">
                            <Loader isOpen={loading} />
                            <div className="page_header"style={{ backgroundImage: `url(${this.props.Config.main_image})` }}>
                                <Header user='moderator' searchData={header_search_data}/>

                                {this.props.admin_clients_list.alerts?.map(alert => {
                                    return <Alert key={alert.id} type={alert.type} destination={'login'}
                                                  message={alert.message} onClose={() => {
                                        this.props.closeAlert(alert.id)
                                    }}/>
                                })}

                            </div>
                            <div className="page_body">
                                <div className="content">
                                    <CardAdditionAction current_user={current_user}
                                                        onClick={this.handleAddClientClick}/>
                                    {

                                        renderTodos
                                    }
                                </div>
                                <div className="moderator_list_page_last_comments">
                                    <ModeratorCardLatestComments comments_list={comments_list}/>
                                </div>
                            </div>
                            <AddCourseModal isOpen={this.state.is_create_course_modal_open} category={category_list}
                                            onClick={this.handleAddCourseSubmit}
                                            onCancel={this.handleAddClientModalCancel}/>
                            <div>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flex: 1,
                                    height: 60,
                                    marginTop: "10px",

                                }}>
                                    {renderPageNumbers}
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Translation>
        )
    }
}

const mapStateToProps = state => {
    const {moderator_courses_list, moderator_configure_course, login, admin_clients_list, Config} = state;
    return {moderator_courses_list, moderator_configure_course, login, admin_clients_list, Config};

};

const mapActionsToProps = {
    loadAllCourses,
    deleteCourse,
    pushAlert,
    loadAllCategories,
    loadAllComments,
    addCourse,
    loadCourseContent,
    clearElementCourse,
    closeAlert
};

export default connect(mapStateToProps, mapActionsToProps)(ModeratorList);
