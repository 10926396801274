import React, {Component, useState} from "react";
import './index.css'
import addDocument from "./assets/add-document.svg"
import imageCloud from "./assets/icon-upload-cloud.svg"
import axios from "axios";
import DeleteElementsModal from "../../modules/moderator/configure-course/components/delete-elements-modal";

class DocumentsUploader extends Component {
    constructor(props) {
        super(props);
        this.documentsUploadInput = React.createRef();
        this.state = {
            file: [],
            raw: '',
            preview: '',
            fileUploaded : '',
            is_open_delete:false,
            current_delete_item:null
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
        });

        const file_data = new FormData();
        const files = e.target.files;
        let i = 0;
        for (i ; i < files.length; i++) {
            file_data.append(`doc[${i}]`, files[i])
        }



        i = 0
        for (const [key, value] of file_data.entries()) {
            const options = {
                onUploadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    let percent = Math.floor( loaded * 100 / total)
                    console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                    if (percent < 100){
                        this.setState.file.push({
                            percent
                        })
                    }
                }
            }
            // axios.post('https://www.nowedge.io/lms-php-api/staging/upload_document.php', value, options).then(response => {
            //     console.log(response);
            // })
            this.state.file.push(
                {
                    id: i,
                    name: value.name,
                    value: value,
                    options: options,
                }
            )
            // this.setState({ file: [...this.state.file, {
            //         id: i,
            //         value: value,
            //         options: options
            //     }] }) //simple value

            // console.log(this.state.file)
            i++;
        }


        //Upload 1 file
        const fileData  = new FormData;
        fileData.append('doc', e.target.files[0])
        axios.post('https://www.nowedge.io/lms-php-api/staging/upload_document.php', fileData).then(response => {
            this.props.onDocumentChange(e.target.files[0].name , response.data.message);
            this.setState({
                fileUploaded: response.data.message
            })
        })

    }

    handleDocumentsChange = (e) => {
        const {onDocumentsChange} = this.props;

        onDocumentsChange(e.target.files);
    };

    handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDocumentsDrop = (e) => {
        const {onDocumentsChange} = this.props;

        e.preventDefault();
        e.stopPropagation();

        onDocumentsChange(e.dataTransfer.files);
    }

    onDocumentDelete_ = ()=>{
        this.setState({
            is_open_delete:false
        })
        this.props.onDocumentDelete(this.state.current_delete_item)
    }


    render() {
        const {items,onDocumentDelete , activeDelete = true, showUploader =true}  = this.props;

        let documents = [];

        if(items instanceof FileList){
            for (let i = 0; i < items.length; i++) {
                documents.push(items[i]);
            }
        }else{
            documents = [...items];
        }


        return <>
            <DeleteElementsModal  isOpen={this.state.is_open_delete} onCancel={()=> this.setState({is_open_delete:false}) } onDelete={()=>this.onDocumentDelete_()} />

            <div className="NE_documents_uploader">
                {showUploader &&
                    <div className="drop_zone"
                         onDragOver={this.handleDragOver}
                         onDrop={this.handleDocumentsDrop}
                    >
                        {/*<label htmlFor="upload-button">*/}
                        <img src={addDocument} alt="Add document icon"/>
                        <h1>Attached Documents</h1>
                        <p>Drag &amp; drop or <span onClick={() => {this.documentsUploadInput.current.click();}}>browse</span> your file here</p>
                        <input ref={this.documentsUploadInput} id="upload-button" type="file" accept=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.pptm,.xlsm" multiple onChange={this.handleDocumentsChange} hidden />
                        {/* </label> */}
                        {/*  */}
                    </div>
                }

                <div className="upload_vault">
                    {documents?.map((item,index) => {
                        return (
                            <div className="upload_item" key={index}>
                                <div className="upload_item_icon">
                                    <img src={imageCloud} alt="Upload"/>
                                </div>

                                <div className="upload_item_content">
                                    <div className="upload_item_header">
                                        <h1>{item.title ? item.title : (item.name ? item.name: "")}</h1>
                                        {activeDelete && <button onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                                current_delete_item:item,
                                                is_open_delete:true
                                            })
                                        }}>Cancel</button>}
                                    </div>

                                    {/*<div className="upload_item_progress">*/}
                                    {/*    <div className="progress_bar">*/}
                                    {/*        <div className="progress_level" style={{width:"65%"}}></div>*/}
                                    {/*    </div>*/}

                                    {/*    <div className="progress_infos">*/}
                                    {/*        <p className="progress_title">Uploading…</p>*/}
                                    {/*        <p className="progress_percentage">64%</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        );
                    })}
                </div>
                {/*{file.map((obj) =>{*/}
                {/*    return(*/}
                {/*        <div className="upload_vault" key={obj.id}>*/}
                {/*            <div className="upload_item">*/}
                {/*                <div className="upload_item_icon">*/}
                {/*                    <img src={imageCloud} alt="Upload icon"/>*/}
                {/*                </div>*/}
                {/*                <div className="upload_item_content">*/}
                {/*                    <div className="upload_item_header">*/}
                {/*                        <h1>{obj.name}</h1>*/}
                {/*                        <button>Cancel</button>*/}
                {/*                    </div>*/}
                {/*                    <div className="upload_item_progress">*/}
                {/*                        <div className="progress_bar">*/}
                {/*                            <div className="progress_level" style={{width: `64%`}}/>*/}
                {/*                        </div>*/}
                {/*                        <div className="progress_infos">*/}
                {/*                            <p className="progress_title">Uploading…</p>*/}
                {/*                            <p className="progress_percentage">14</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*})}*/}
            </div>
        </>
    }
}
export default DocumentsUploader;
