import React,{Component} from "react";
import './index.css'
import Avatar from './assets/avatar.png';
import Avatar2 from './assets/JzGCIRY.png'
import Avatar3 from './assets/ZL4ONi9.png'
import IconCheck from './assets/Icon-Check.svg';
import {uid} from "uid";

class RadioAvatar extends Component{

    state = {
        items:[],
        selected_item: null
    };

    selectItem = (e, item) => {
        const {avatar} = this.props
        const image_id = uid()
        // this.setState({
        //     ...this.state,
        //     selected_item: item
        // });
        // console.log(this.state)
        const image =  this.dataURLtoFile(item, image_id)
        avatar(image);

    }

     dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
    }


    render() {
        return (
            <>
                <div className="NE_radio_avatar">
                    <input type="radio" name="profile_avatar" id="profile_avatar" onChange={(e)=>{this.selectItem(e, Avatar)}}/>
                    <label htmlFor="profile_avatar">
                        <img src={Avatar} alt="Avatar" />
                        <div className="checkbox_container">
                            <div className="checkbox">
                                <img src={IconCheck} alt="Check icon" />
                            </div>
                        </div>
                    </label>
                </div>
                <div className="NE_radio_avatar" style={{marginLeft: '20px'}} >
                    <input type="radio" name="profile_avatar" id="profile_avatar_2" onChange={(e)=>{this.selectItem(e, Avatar)}}/>
                    <label htmlFor="profile_avatar_2">
                        <img src={Avatar2} alt="Avatar" />
                        <div className="checkbox_container">
                            <div className="checkbox">
                                <img src={IconCheck} alt="Check icon" />
                            </div>
                        </div>
                    </label>
                </div>
                <div className="NE_radio_avatar" style={{marginLeft: '20px'}} >
                    <input type="radio" name="profile_avatar" id="profile_avatar_3" onChange={(e)=>{this.selectItem(e ,Avatar)}}/>
                    <label htmlFor="profile_avatar_3">
                        <img src={Avatar3} alt="Avatar" />
                        <div className="checkbox_container">
                            <div className="checkbox">
                                <img src={IconCheck} alt="Check icon" />
                            </div>
                        </div>
                    </label>
                </div>
                <div className="NE_radio_avatar" style={{marginLeft: '20px'}}>
                    <input type="radio" name="profile_avatar" id="profile_avatar_4" onChange={(e)=>{this.selectItem(e, Avatar)}}/>
                    <label htmlFor="profile_avatar_4">
                        <img src={Avatar} alt="Avatar" />
                        <div className="checkbox_container">
                            <div className="checkbox">
                                <img src={IconCheck} alt="Check icon" />
                            </div>
                        </div>
                    </label>
                </div>
            </>
        );
    }
}
export default RadioAvatar;