import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_CHANGE_PROPS,
  CLOSE_ALERT, PUSH_ALERT
} from "./action-definitions";

// Initial state
const INIT_STATE = {
  // Userinfo,
  email: "",
  loading: false,
  alerts:[]
};

// Reducer
const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return { ...state, loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: true,
        loading: false,
        error: null
      };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };
    case RESET_CHANGE_PROPS:
      return { ...state, [action.payload.propName]: action.payload.propValue };
    case PUSH_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ]
      };

    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
      };
    default:
      return { ...state };
  }
};

export default Auth;
