import React, { createContext, useContext, useState, PropsWithChildren } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";


const defaultThemeState = {
    mode: "light",
    setMode: () => { }
};

const ThemeContext = createContext(defaultThemeState);

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const config = useSelector(state => state.Config);

    const [mode, setMode] = useState("dark");

    useEffect(() => {
        document.title = +config.client_id === 1 ? "NowEdge - A Learning Experience " : config.name;

        const logo = document.querySelector('#logo');

        // logo.href = config.logo;
        logo.setAttribute("href", config.logo ? config.logo : config.favicon);

        document.body.style.setProperty(
            "--primary-color", config["primary-color"]
        );
        document.body.style.setProperty(
            "--secondary-color", config["secondary-color"]
        );
    }, [config.client_id])



    return <ThemeContext.Provider value={{ mode, setMode }}>
        {children}
    </ThemeContext.Provider>
};