import React, {Component, useEffect, useState} from "react";
import './index.css'

const Dropdown2 = ({items , onChange=()=>null , label,placeholder, value, route, isValid,def})=> {
    const [is_open, setIsOpen] = useState(false);
    const [listItems, setItems] = useState([]);
    const [selected_item, setSelectedItem] = useState(null);

    useEffect(() => {
        setItems(items)
    }, [items]);


   const toggleDropdown = () => {
       setIsOpen(!is_open)

    }

   const selectItem = (item) => {


        setSelectedItem(_=>item);
        setIsOpen(_=>false);
        onChange(item);
    }

        let class_name = "NE_dropdown ";
        class_name += is_open ? "NE_dropdown_open" : "";


        return(
            <div className={class_name}>
                <label>{label}</label>

                <div className="dropdown" onClick={toggleDropdown}>
                    {route === 'userupdate' ?
                            <p>{selected_item ?  selected_item.name : value}</p>
                             :  value ?
                                <p>{ value.name || value.title }</p>
                              :
                             <p>{selected_item ? selected_item.name || selected_item.title : placeholder}</p>
                    }
                    <div className="down_button" />
                </div>

                <div className="dropdown_options">
                    {listItems?.map(item => {
                        let item_class = "dropdown_option ";
                        item_class += item.disabled ? "dropdown_option_disabled " : "";
                        item_class += item.id === selected_item?.id || item.id ===  value?.id ? "dropdown_option_selected" : "";

                        return <div key={item.id} className={item_class} onClick={() => {selectItem(item)}}>{item.name|| item.title}</div>;
                    })}
                </div>

                <p>This is a message</p>
            </div>
        );

}
export default Dropdown2;
