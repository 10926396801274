import Joi from "@hapi/joi";

// export default Joi.object({
//     full_name: Joi.string().alphanum().min(3).required(),
//     type: Joi.string().min(3).required(),
//     phone: Joi.string().pattern(new RegExp("^\+[0-9]{1,3} [0-9]{4,}$")).required(),
//     email: Joi.string().email().required(),
//     address: Joi.string().min(3).required(),
// });
const lang = navigator.language || 'fr'; 
export const image_size_schema = Joi.number()
  .integer()
  .max(5000000);
export const full_name_schema = Joi.string()
  .min(3)
  .required();
export const title_schema = Joi.string()
  .min(5)
  .required();

const validateDescription = (language) => {
  let myvar;
  if (language.startsWith("fr")) {
    myvar = Joi.string()
      .min(5)
      .required()
      .messages({
        'string.min': 'La description doit comporter au moins 5 caractères.',
        'any.required': 'La description est obligatoire.',
      })
      .empty('', { 'string.empty': 'La description ne peut pas être vide.' });
  }
  else {
    myvar = Joi.string()
      .min(5)
      .required()
      .messages({
        'string.min': 'The description must be at least 5 characters long.',
        'any.required': 'The description is required.',
      })
      .empty('', { 'string.empty': 'Description cannot be empty.' });
  }
  return myvar;
}
  
const validateEmail = (language) => {
  let myvar;
  if (language.startsWith("fr")) {
    myvar = Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'string.email': 'Veuillez entrez une adresse e-mail valide',
        'string.empty': `L'email ne peut pas être un champ vide.`,
        'any.required': `L'email est un champ obligatoire`
      });
  }
  else {
    myvar = Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'string.email': 'Please enter a valid email address',
        'string.empty': `The email cannot be an empty field.`,
        'any.required': `Email is a required field`
      });
  }
  return myvar;
}
  
const validatePassword = (language) => {
  let myvar;
  if (language.startsWith("fr")) {
    myvar = Joi.string()
  .alphanum()
  .min(5)
  .required()
  .messages({
        'string.empty': `Veuillez remplir le champ du mot de passe avant de continuer!`,
        'string.min': `Le mot de passe doit avoir une longueur minimale de 5`,
        'any.required': `Le mot de passe est un champ obligatoire`
  })
  }
  else {
    myvar = Joi.string()
  .alphanum()
  .min(5)
  .required()
  .messages({
        'string.empty': `Please fill in the password field before continuing!`,
        'string.min': `The password must have a minimum length of 5`,
        'any.required': `Password is a required field`
  })
  }
  return myvar;
  }
   
const validateSessionTitle = (language) => {
  let myvar;
  if (language.startsWith("fr")) {
    myvar = Joi.string()
  .min(3)
  .required()
    .messages({
        'string.empty': `Veuillez remplir le champ du nom de la session avant de continuer!`,
        'string.min': `Le nom de la session doit avoir une longueur minimale de 3`,
        'any.required': `Le nom de la session est un champ obligatoire.`
    })
  }
  else {
    myvar = Joi.string()
  .min(3)
  .required()
    .messages({
        'string.empty': `Please fill out the session name field before continuing!`,
        'string.min': `The session name must have a minimum length of 3`,
        'any.required': `The session name is a required field.`
    })
  }
  return myvar;
  }
 
export const description_schema = validateDescription(lang);
   

export const language_schema = Joi.string()
  .min(1)
  .required();
export const type_schema = Joi.string()
  .min(3)
  .required();
export const phone_schema = Joi.string()
  // .pattern(new RegExp("^\\+[0-9]{1,3} [0-9]{4,}$"))
  .required();
export const email_schema = validateEmail(lang);

export const address_schema = Joi.string()
  .min(3)
  .required();
export const date_schema = Joi.date().required();
export const password_schema = validatePassword(lang)
export const session_title_schema = validateSessionTitle(lang)
