import React from 'react';
import PropTypes from 'prop-types';
import Switch from "../../../../../components/Switch";
import {useDispatch} from "react-redux";
import {editItemCourse} from "../../../../../redux/moderator/configure-course/actions-creators";

function PublishedElement({element , type}) {

    const dispatch =  useDispatch();
    const setPublished = (data)=>{

        const _item = {
            id:element.id ,
            title:element.title,
            description:element.description,
            element_order:element.element_order,
            published: data
        }

        dispatch(editItemCourse(_item,type))
    }

    return (
        <div className={"switch-btn"}>
            <Switch init={element?.published ?? true} onChange={setPublished} />
        </div>
    );
}

PublishedElement.propTypes = {
    element:PropTypes.object.isRequired,
    type:PropTypes.oneOf(['module', 'document','evaluation','business_game']).isRequired,
};
PublishedElement.defaultProps = {

};

export default PublishedElement;
