import React, {Component} from "react";
import {Translation} from "react-i18next";
import './index.css';
import IconInput from "../IconInput";
import Dropdown2 from "../Dropdown2";
import LargeButton from "../LargeButton";
import icon_edit from "./assets/icon-pencil.svg";
import icon_delete from "./assets/icon-trash.svg";
import SessionItem from "./components/SessionItem";
import SessionStates from "../../statics/session_states";
import i18n from "../../../i18n";


class SessionsSidebar extends Component{

    handleEditSession = (session) => {
        this.props.onEditSessionOptionClick(session);
    }

    handleDeleteSession = (session) => {
        this.props.onDeleteSessionOptionClick(session);
    }

    render() {
        const {sessionsList,selectedSession,onSearchChange,onFilterChange, onCreateSessionClick,onSessionItemClick , current_user} = this.props;

        return <Translation>
            {t => {
                // Prepare sessions dropdown items
                const sessionsDropdownItems = SessionStates.map(sessionState => {
                    return {
                        ...sessionState,
                        name: t(sessionState.i18n_key),
                        disabled:false
                    };
                });

                return (
                    <div className="NE_SessionsSideBar">
                        <div className="body">
                            <IconInput placeholder={t("moderator.training-sessions.statics.sessions-sidebar.fields.search_placeholder")} onChange={onSearchChange} />
                            <Dropdown2 label={t("moderator.training-sessions.statics.sessions-sidebar.fields.filter_label")} placeholder={t("moderator.training-sessions.statics.sessions-sidebar.fields.filter_placeholder")} items={sessionsDropdownItems} onChange={(selected_item) => {onFilterChange(selected_item)}} />

                            <ul className="sessions_list">
                                {sessionsList?.map(session => {
                                    let session_options = [];

                                    if(session.is_editable && !current_user.expired){
                                        session_options = [
                                            {
                                                name: t("moderator.training-sessions.statics.sessions-sidebar.edit_option"),
                                                icon: icon_edit,
                                                data: session,
                                                click_handler: this.handleEditSession,
                                            },
                                            {
                                                name: t("moderator.training-sessions.statics.sessions-sidebar.delete_option"),
                                                icon: icon_delete,
                                                data: session,
                                                click_handler:  this.handleDeleteSession,
                                            }
                                        ];
                                    }

                                    return (
                                        <SessionItem key={session.id} session={session} isSelected={selectedSession?.id === session.id} options={session_options} onClick={onSessionItemClick} />
                                    );
                                })}

                            </ul>
                        </div>

                        <div className="footer">
                            <LargeButton value={t("moderator.training-sessions.statics.sessions-sidebar.create_session_button")} button="dark" onClick={(e)=>{
                                if(!current_user.expired) onCreateSessionClick(e)
                            }} />
                        </div>
                    </div>
                );
            }}
        </Translation>
    }
}
export default SessionsSidebar;
