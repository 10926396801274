import React,{Component} from "react";
import './index.css'
import * as moment from "moment";
import lens from '../../assets/Icon-Lens.svg';
import list from '../../assets/list.svg';
import awards from '../../assets/awards.svg';
import CollapsableCardWithCheckboxes from "../../../../../components/CollapsableCardWithCheckboxes";
import {
    CloneCourseElements,
    loadAllCourses,
    loadCourseById,
    loadCourseElements
} from "../../../../../redux/moderator/courses-list/action-creators";
import {connect} from "react-redux";
import history from "../../../../../../utils/history";
import { Translation } from "react-i18next";



class CloneElementModal extends Component{

// {
//     "id": null,
//     "quizzes_ids" : [],
//     "notions_ids" : []
// }
    state = {
        search_string:"",
        selectElement:false,
        course_id:"",
        cloneElements:{
            course_id: null,
            modules: [],
            documents_ids : [],
            games_ids : [],
            evaluations_ids : []
        }
    };

    onSelectElement = (elem)=>{

        this.props.loadCourseElements(elem.course.id);
        this.setState({
            selectElement:true,
            course_id:elem.course.id,
            search_string:""
        });
    };

    onSelectChange = (elem,origin,isChecked, index)=>{


        console.log("-----option----",elem,origin , isChecked);


        let {course_id,modules ,documents_ids , games_ids ,evaluations_ids} = this.state.cloneElements;

        course_id= this.props.current_course.id ;

        if(origin.reference_object === "module"){

            let module_index = modules.findIndex((elm)=>elm.id === origin.element_id);

             if(module_index===-1)
             {
                 modules.push({
                     id:origin.element_id,
                     "quizzes_ids" : [],
                     "notions_ids" : []
                 });

                 module_index = modules.length-1;
             }

             let {  notions_ids , quizzes_ids } = modules[module_index];

             console.log(typeof elem);
            if ( elem == null){

                quizzes_ids=[];
                notions_ids=[];

                if (isChecked) {

                    quizzes_ids.push(...origin.quizzes.map(elm=>elm.id));
                    notions_ids.push(...origin.notions.map(elm=>elm.id));

                    modules[module_index] = {
                        id: origin.element_id,
                        notions_ids,
                        quizzes_ids
                    }

                }else {

                    modules =   modules.filter((elm)=>elm.id !== origin.element_id);
                }

            }else {
                if(elem.type ==="notions"){
                    if(isChecked) notions_ids.push(elem.id);
                    else notions_ids = notions_ids.filter((id)=> id !== elem.id );
                }
                else {
                    if(isChecked) quizzes_ids.push(elem.id);
                    else quizzes_ids = quizzes_ids.filter((id)=> id !== elem.id );

                }
                modules[module_index] = {
                    id: origin.element_id,
                    notions_ids,
                    quizzes_ids
                }

            }

            modules =  modules.filter((elm)=> elm.notions_ids.length > 0 || elm.quizzes_ids.length > 0    )

        }
        if(origin.reference_object === "documents"){
            if(isChecked) documents_ids.push(origin.element_id);
            else documents_ids = documents_ids.filter((id)=> id !== origin.element_id );
        }

        if(origin.reference_object === "evaluation"){
            if(isChecked) evaluations_ids.push(origin.element_id);
            else evaluations_ids = evaluations_ids.filter((id)=> id !== origin.element_id );
        }

        if(origin.reference_object === "game"){
            if(isChecked) games_ids.push(origin.element_id);
            else games_ids = games_ids.filter((id)=> id !== origin.element_id );
        }

        console.log("-----setState----",{
            ...this.state.cloneElements,
            modules,
            documents_ids,
            evaluations_ids,
            games_ids
        });

        this.setState({
            cloneElements:{
                ...this.state.cloneElements,
                course_id,
                modules,
                documents_ids,
                evaluations_ids,
                games_ids
            }
        })
    };

    list_tl = [
        {
            "element_id": 2,
            "element_title": "module intro",
            "reference_object": "module",
            "notions": [
                {
                    "id": 4,
                    "title": "notion4"
                },
                {
                    "id": 5,
                    "title": "notion5"
                }
            ],
            "quizzes": [
                {
                    "id": 9,
                    "title": "quiz9"
                },
                {
                    "id": 10,
                    "title": "quiz10"
                }
            ]
        },
        {
            "element_id": 6,
            "element_title": "doc4",
            "reference_object": "documents"
        },
        {
            "element_id": 1,
            "element_title": "StratEdge",
            "reference_object": "game"
        },
        {
            "element_id": 5,
            "element_title": "eval5",
            "reference_object": "evaluation"
        }
    ];



    render() {
        const {isOpen, onCancel , courses_list = []} = this.props;

        let class_name = "configure_course_modal_wrapper ";
        class_name += isOpen ? "configure_course_modal_open" : "";

        const  filteredData = courses_list.filter( elem => elem.course.title?.toLowerCase().includes(this.state.search_string) );
        const  filterCourseElement = this.props.course_elements.filter( elem => elem.element_title?.toLowerCase().includes(this.state.search_string) );
        //this.list_tl
        return(
                <div className={class_name} onClick={(e) => {
                    if(e.target.className === class_name){
                        onCancel();
                        // this.clearLocalState();
                    }
                }}>

                <div className="NE_body">
                    <div className="NE_text">
                        <h3 className="NE_text_body">Clone Element</h3>
                        <h3 className="NE_text_body _close_button" onClick={()=>{
                            onCancel();
                        }} >x</h3>
                    </div>
                    <div className="NE_body_center" />
                    <div className="NE_search_bar">
                        <div className="NE_icon_input NE_icon_input_success NE_icon_input_error">
                            <div className="input_wrapper">
                                <input type="text" id="test" placeholder="Search" value={this.state.search_string} onChange={(e)=>{
                                    this.setState({
                                        search_string: e.target.value
                                    });
                                }}  />
                                <img src={lens} alt="Input icon" />
                            </div>
                        </div>
                    </div>
                    {this.state.selectElement ?
                       <>
                           <div className="collaps-content">
                               {
                                   filterCourseElement.map((elem,index)=>{

                                       return <CollapsableCardWithCheckboxes
                                                                               key={index}
                                                                               index={index}
                                                                               element={elem}
                                                                               onSelectChange={this.onSelectChange}
                                              />

                                   })
                               }
                           </div>
                           <div className="form-actions_mt">
                               <div className="form-action ">
                                   <button
                                       onClick={()=>{
                                           this.setState({
                                               selectElement:false,
                                               search_string:""
                                           })
                                       }}
                                       width="180px" height="56px" className="NE_button_large NE_button_outline" type="reset">Cancel</button>
                               </div>
                                <div className="form-action mr-0 ">
                                    <Translation>
                                        {t =>
                                            <button className="NE_button_large NE_button_dark"
                                                onClick={() => {
                                                    this.props.CloneCourseElements(this.state.cloneElements, () => {
                                                        this.props.loadAllCourses();
                                                        history.push("/moderator");
                                                    });
                                                }}
                                            >{t("moderator.edit_course.import_button")}</button>
                                        }</Translation>
                               </div>
                           </div>
                       </>
                        :
                        <div className="NE_body_content">

                            {filteredData.map((val)=><div className="NE_business_game_card " onClick={ ()=> this.onSelectElement(val) } >
                                <div className="card_header">
                                    <div className="card_image">
                                    <span className={"category"}>
                                        {val.course.category.title}
                                    </span>
                                        <img src={val.course.image_path} alt="" />
                                    </div>
                                </div>
                                <div className="card_body_clone">
                                    <div className="comment_date" >
                                        <span>from {moment(val.course.creation_date).format("YYYY-MM-DD")}</span>
                                        <span>to {moment(val.course.creation_date).format("YYYY-MM-DD")}</span>
                                    </div>
                                    <h1>{val.course.title}</h1>
                                    <p>{val.course.description}</p>
                                </div>
                                <div className="card_footer">
                                    <div>
                                        <img src={list} alt={""}/>
                                        <span className="number" >2</span>
                                        <span className="text_">Module</span>
                                    </div>
                                    <div>
                                        <img src={awards} alt={""} />
                                        <span className="number">2</span>
                                        <span className="text_">Evaluation</span>
                                    </div>
                                </div>
                            </div>)
                            }

                        </div>

                    }

                </div>
                    </div>
        );
    }
}


const mapStateToProps = state => {
    const { moderator_courses_list : {course_elements} , moderator_configure_course:{current_course} } = state;
    return {course_elements , current_course};
};

const mapActionsToProps = {
    loadCourseElements,
    CloneCourseElements,
    loadAllCourses
};

export default connect(mapStateToProps, mapActionsToProps)(CloneElementModal);
