import {
  LOAD_ALL_CLIENTS,
  LOAD_ALL_CLIENTS_SUCCESS,
  LOAD_ALL_CLIENTS_ERROR,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_ERROR,
  CHANGE_CURRENT_CLIENT_PROP_VALUE,
  CHANGE_CURRENT_CLIENT_VALUE,
  CHANGE_CURRENT_CLIENT_VALIDATION_PROP_VALUE,
  CLEAR_CURRENT_CLIENT,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_ERROR,
  PUSH_ALERT,
  CLOSE_ALERT,
  ADD_CLIENT,
  EDIT_CLIENT
} from "./action-definitions";

import { findIndex } from "lodash";

// Initial state
const INIT_STATE = {
  clients_list: [],
  current_client: {
    image: null,
    full_name: "",
    type: "",
    phone: "",
    email: "",
    address: "",
    start_date: "",
    expiry_date: ""
  },
  current_client_validation: {
    image: {
      is_valid: false,
      error: ""
    },
    full_name: {
      is_valid: false,
      error: ""
    },
    type: {
      is_valid: false,
      error: ""
    },
    phone: {
      is_valid: false,
      error: ""
    },
    email: {
      is_valid: false,
      error: ""
    },
    address: {
      is_valid: false,
      error: ""
    },
    expiry_date: {
      is_valid: false,
      error: ""
    }
  },
  alerts: [],
  is_loading: false
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_ALL_CLIENTS:
      return { ...state, is_loading: true };

    case LOAD_ALL_CLIENTS_SUCCESS:
      return { ...state, clients_list: [...action.payload], is_loading: false };

    case LOAD_ALL_CLIENTS_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case ADD_CLIENT:
      return { ...state, is_loading: true };

    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        clients_list: [...state.clients_list, { ...action.payload }],
        is_loading: false
      };

    case ADD_CLIENT_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case EDIT_CLIENT:
      return { ...state, is_loading: true };

    case EDIT_CLIENT_SUCCESS:
      // Find index of the client to be updated
      const client_index = findIndex(
        state.clients_list,
        client => client.id === action.payload.id
      );

      // Create an updated list of clients
      const updatedClientsList = [...state.clients_list];
      updatedClientsList.splice(client_index, 1, action.payload);

      return {
        ...state,
        clients_list: [...updatedClientsList],
        is_loading: false
      };

    case EDIT_CLIENT_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case CHANGE_CURRENT_CLIENT_PROP_VALUE:
      return {
        ...state,
        current_client: {
          ...state.current_client,
          [action.payload.propName]: action.payload.propValue
        }
      };

    case CHANGE_CURRENT_CLIENT_VALIDATION_PROP_VALUE:
      return {
        ...state,
        current_client_validation: {
          ...state.current_client_validation,
          [action.payload.propName]: action.payload.propValue
        }
      };

    case CHANGE_CURRENT_CLIENT_VALUE:
      return { ...state, current_client: { ...action.payload } };

    case CLEAR_CURRENT_CLIENT:
      return {
        ...state,
        current_client: { ...INIT_STATE.current_client },
        current_client_validation: { ...INIT_STATE.current_client_validation }
      };

    case PUSH_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ]
      };

    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
      };

    default:
      return { ...state };
  }
};

export default reducer;
