import React, { Component, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translation } from "react-i18next";

import "./index.css";

const WonModal = ({ show, close = () => null, onClickOK = () => null }) => {
  let class_name = "modal_wrapper NE_won-modal-container NE_won-modal-container_points ";
  class_name += show ? "modal_open" : "";

  return (
    <Translation>
      {(t) => 
    <div
      className={class_name}
      onClick={(e) => {
        if (e.target.className === class_name) {
          // this.clearLocalState();
          close();
        }
      }}
      style={{
        zIndex: 3,
      }}
    >
      <div className="modal_body_won">
        <div className="NE_reward_modal">
              <h1>{t("participant.cours_import_sucess")}</h1>
              <p>{t("participant.bonne_cont")}</p>
          <button onClick={onClickOK}>Ok</button>
        </div>
      </div>
        </div>
         }
    </Translation>
  );
      
};

export default WonModal;
