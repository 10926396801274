import React, { useState } from "react";
import './index.css'
import icon from './assets/icon.svg';
import icon_bus from "./assets/icon_bus.svg"
import { deleteGame } from "../../../../../redux/moderator/configure-course/actions-creators";
import { useDispatch } from "react-redux";
import DeleteElementsModal from "../../../configure-course/components/delete-elements-modal";
import PublishedElement from "../PublishedElement/PublishedElement";
import arrowUp from "../DocumentsCard/assets/arrow-up.svg";
import arrowDown from "../DocumentsCard/assets/arrow-down.svg";
import useMoveElementCourse from "../../../../../../utils/moveElementCourse";


const BusinessCard = (props) => {
    const { isOpen = true, editMode } = props;

    const [listElements, setListElements] = useState([]);
    const [is_open_delete, setIsOpenDelete] = useState(false);

    const [is_open, setIsOpen] = useState(isOpen);
    const [moveElement, indexItem, length] = useMoveElementCourse(props)

    const onAddElement = (type) => (e) => {

        setListElements([...listElements, {
            type
        }])
    }
    const toggleCollapse = () => {
        setIsOpen(!is_open);
    }

    const deleteEvaluationButton = () => {
        setIsOpenDelete(false)

        const { element, onSave } = props;

        dispatch(deleteGame(element.id, element._id));
        setIsOpen(!is_open);

    }

    const { element, onSave } = props;
    console.log("editelement",element)

    let class_name = "NE_collapsable_card_with_icon quiz_creation_card ";
    class_name += is_open ? "NE_collapsable_card_with_icon_open" : "";

    const dispatch = useDispatch()
    return <>
        <DeleteElementsModal isOpen={is_open_delete} onCancel={() => setIsOpenDelete(false)}
            onDelete={deleteEvaluationButton} />

        <div className="NE_collapsable_cards_container NE_collapsable_cards_container_open">
            <div className="header">
                <div className="title">
                    <span className={"d-flex"} >
                        {<img src={arrowUp} className={`arrow`} alt={""} onClick={() => indexItem !== 0 ? moveElement("down") : null} />}
                        {<img src={arrowDown} className={`arrow `} alt={""} onClick={() => length - 1 !== indexItem ? moveElement("up") : null} />}
                    </span>
                    <img src={icon} alt="Container icon" />
                    <h2>{element?.title}</h2>
                </div>

                {!props.current_course.publish && <div className="delete_element_content">
                    <div className={"btn_public"}>
                        <PublishedElement element={element} type={"business_game"} />
                    </div>

                    {/* {editMode && <> */}

                    <span className="btn_delete_element" onClick={() => {
                        setIsOpenDelete(true)
                    }}>
                    </span>
                    {/* </>} */}


                    <span className="btn_collapse" onClick={() => {
                        toggleCollapse()
                    }} />
                </div>}

                {/*<button onClick={() => {*/}
                {/*    */}
                {/*    dispatch(deleteGame(element.id, element._id))*/}
                {/*}*/}
                {/*}>Delete*/}
                {/*</button>*/}
                {/*<span className="btn_collapse"/>*/}
            </div>
            <div className="collapse_bus_game">
                <img src={icon_bus} />
                <p>{element?.name}</p>
            </div>


        </div>
    </>

}
export default BusinessCard;
