export const LOAD_ALL_CLIENTS = "ADMIN.CLIENTS-LIST.LOAD_ALL_CLIENTS";
export const LOAD_ALL_CLIENTS_SUCCESS =
  "ADMIN.CLIENTS-LIST.LOAD_ALL_CLIENTS_SUCCESS";
export const LOAD_ALL_CLIENTS_ERROR =
  "ADMIN.CLIENTS-LIST.LOAD_ALL_CLIENTS_ERROR";
export const ADD_CLIENT = "ADMIN.CLIENTS-LIST.ADD_CLIENT";
export const ADD_CLIENT_SUCCESS = "ADMIN.CLIENTS-LIST.ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_ERROR = "ADMIN.CLIENTS-LIST.ADD_CLIENT_ERROR";
export const CHANGE_CURRENT_CLIENT_PROP_VALUE =
  "ADMIN.CLIENTS-LIST.CHANGE_CURRENT_CLIENT_PROP_VALUE";
export const CHANGE_CURRENT_CLIENT_VALUE =
  "ADMIN.CLIENTS-LIST.CHANGE_CURRENT_CLIENT_VALUE";
export const CHANGE_CURRENT_CLIENT_VALIDATION_PROP_VALUE =
  "ADMIN.CLIENTS-LIST.CHANGE_CURRENT_CLIENT_VALIDATION_PROP_VALUE";
export const CLEAR_CURRENT_CLIENT = "ADMIN.CLIENTS-LIST.CLEAR_CURRENT_CLIENT";
export const EDIT_CLIENT = "ADMIN.CLIENTS-LIST.EDIT_CLIENT";
export const EDIT_CLIENT_SUCCESS = "ADMIN.CLIENTS-LIST.EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_ERROR = "ADMIN.CLIENTS-LIST.EDIT_CLIENT_ERROR";
export const PUSH_ALERT = "ADMIN.CLIENTS-LIST.PUSH_ALERT";
export const CLOSE_ALERT = "ADMIN.CLIENTS-LIST.CLOSE_ALERT";
