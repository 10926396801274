import {
  GET_PROFILE_POINT,
  GET_PROFILE_POINT_SUCCESS,
  GET_PROFILE_POINT_ERROR
} from "./action-definitions";

export const getProfilePoints = () => ({
  type: GET_PROFILE_POINT,
  payload: null
});

export const getProfilePointsSuccess = profilePoints => ({
  type: GET_PROFILE_POINT_SUCCESS,
  payload: profilePoints
});

export const getProfilePointsError = errors => ({
  type: GET_PROFILE_POINT_ERROR,
  payload: errors
});
