import React, {Component} from "react";
import {
    changeLoginValidationValues,
    changeLoginValues,
    loginFacebook,
    loginUser
} from "../../../redux/login/action-creators";
import {connect} from "react-redux";

class FacebookLogin extends Component{
    constructor(props) {
        super(props);
        this.state = {
            code : ''
        }
    }
    componentDidMount() {
        const {loginFacebook} = this.props
        const code = new URLSearchParams(this.props.location.search.substring(1)).get(
            "code"
        );
        this.setState({
            code
        })
        if (code){
            //Dispatch Action
            loginFacebook(code)
        }
    }

    render() {
        const {code} = this.state
        return(
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { login } = state;
    return { login };
};

const mapActionsToProps = {
    loginFacebook
};
// export default Login;
export default connect(mapStateToProps, mapActionsToProps)(FacebookLogin);