import React, {Component} from 'react';
import "./index.css";

class Loader extends Component {
    render() {
        const {isOpen} = this.props;

        let class_name = "NE_loader ";
        class_name += isOpen ? "NE_loader_open" : "";

        return (
            <div className={class_name}>
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
}

export default Loader;
