import React, { Component } from "react";
import { connect } from "react-redux";

import './index.css';
import NormalInput from "../../../../../components/NormalInput";
import NormalButton from "../../../../../components/NormalButton";
import Dropdown from "../../../../../components/Dropdown"
import LargeButton from "../../../../../components/LargeButton";
import CoursePublishOverview from "../../../../../components/CoursePublishOverview";

import add_picture from "./assets/add-picture.svg";
import {
  address_schema, date_schema,
  email_schema,
  full_name_schema, image_size_schema,
  phone_schema,
  type_schema
} from "../../../../../validation/validations";
import ImagesUploader from "../../../../../components/ImagesUploader";
import axios from "axios";
import Textarea from "../../../../../components/Textarea";
import { addCourse } from "../../../../../redux/moderator/configure-course/actions-creators";
import { Translation } from "react-i18next";

class AddCourseModal extends Component {

  constructor(props) {
    super(props);
  }

  INITIAL_STATE = {
    image: null,
    category: '',
    categoryId: '',
    fields: {
      title: "",
      description: "",

    },
  };

  state = { ...this.INITIAL_STATE }


  clearLocalState = () => {
    this.setState({ ...this.INITIAL_STATE });
  }

  isFormValid = () => {
    const { image, title, category, description } = this.state.fieldsValidation;

    return image.is_valid && title.is_valid && category.is_valid && description.is_valid;
  };

  changeFieldValue = (fieldName, fieldValue) => {
    // Validate and Set the field value in the local state
    switch (fieldName) {
      case 'image':
        this.setState({
          ...this.state,
          fields: { ...this.state.fields, [fieldName]: fieldValue },
        });

        break;

      case 'title':
        this.setState({
          ...this.state,
          fields: { ...this.state.fields, [fieldName]: fieldValue },
        });
        break;

      case 'description':
        this.setState({
          ...this.state,
          fields: { ...this.state.fields, [fieldName]: fieldValue },
        });
        break;

      case 'category':
        this.setState({
          ...this.state,
          fields: { ...this.state.fields, [fieldName]: fieldValue },
        });
        break;

      default:
        break
    }
  }



  handleImageChange = (raw) => {
    this.setState({
      ...this.state,
      'image': raw
    });

    const { changeRegisterValues } = this.props
    // changeRegisterValues("image", data);
  }


  handleSubmit = () => {
    this.props.onClick(this.state);
    // this.props.onClick(image);
  }

  getDataFromChildDropDown = (id, data) => {
    this.setState({
      category: data,
      categoryId: id
    })
  }

  render() {
    // Get components props
    const { isOpen, onCancel } = this.props;

    // Get local state variables
    const { fields, fieldsValidation } = this.state;

    let class_name = "moderator_add_moderator_modal_wrapper ";
    class_name += isOpen ? "moderator_add_moderator_modal_open" : "";

    // Try to get preview image
    const preview = fields.image ? URL.createObjectURL(fields.image) : null;

    const category_list = this.props.category;

    return (
      <Translation>
        { t =>
          <>
            <div className={class_name} onClick={(e) => {
              if (e.target.className === class_name) {
                this.clearLocalState();
                onCancel();
              }
            }}>
              <div className="modal_body">
                <div className="create_course_form_wrapper">
                  <h1>{t("moderator.create_course.create_course_text")}</h1>
                  <div className="form-field">
                    <NormalInput onChange={(e) => { this.changeFieldValue('title', e.target.value) }} value={fields.title} 
                    label={t("moderator.create_course.course_title_text")} type="text" 
                    id="title" 
                    placeholder={t("moderator.create_course.course_title_placeholder")} />
                  </div>
                  <div className="form-field">
                    <Dropdown onChange={(e) => { 
                      this.changeFieldValue('category', e.target.value) }} 
                    label={t("moderator.create_course.course_category_text")} 
                    onChange={this.getDataFromChildDropDown} 
                    category={category_list} id="category" />
                  </div>
                  <div className="form-field">
                    <Textarea onChange={(e) => { this.changeFieldValue("description", e.target.value) }} value={fields.description} 
                    label={t("moderator.create_course.course_desc_text")} type="text" 
                    id="description" 
                    placeholder={t("moderator.create_course.course_desc_placeholder")} />
                  </div>
                  <div className="form-field">
                    <div className="NE_images_uploader">
                      <ImagesUploader client='moderator' onImageChange={this.handleImageChange} />
                    </div>
                  </div>
                  <div className="form-actions">
                    <div className="form-action">
                      <NormalButton value={t("moderator.create_course.cancel_button")} 
                      onClick={this.props.onCancel} />
                    </div>
                    <div className="form-action">
                      <NormalButton button="dark" value={t("moderator.create_course.create_button")} 
                      onClick={this.handleSubmit} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </>
        }
      </Translation>
    );
  }
}

const mapStateToProps = state => {
  const { moderator_courses_list, moderator_configure_course } = state;

  return { moderator_courses_list, moderator_configure_course };
};

const mapActionsToProps = {
  addCourse
};

export default connect(mapStateToProps, mapActionsToProps)(AddCourseModal);
