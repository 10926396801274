import React, {useEffect, useRef, useState} from "react";
import './index.css'


function CheckBoxComponent({name, value, tick, onCheck , id}) {
    return <div className="NE_checkbox">
        <input
            // type="checkbox"
            // name="r11"
            id={id}
            name={name}
            type="checkbox"
            value={value}
            checked={tick || false}
            onChange={onCheck}

        />
        <label
            htmlFor={id}
        >
            {value}
        </label>
    </div>;
}

function CollapsableCardWithCheckboxes({
                                           open,
                                           collapsibleClassName = "collapsible-card-edonec",
                                           headerClassName = "collapsible-header-edonec",
                                           titleClassName = "title-text-edonec",
                                           iconButtonClassName = "collapsible-icon-button-edonec",
                                           contentClassName = "collapsible-content-edonec",
                                           contentContainerClassName = "collapsible-content-padding-edonec",
                                           children,
                                           header,
                                           element,
                                           index,
                                           onSelectChange=()=>null
                                       }) {




    const [isOpen, setIsOpen] = useState(open);
    const [height, setHeight] = useState(
        open ? undefined : 0
    );
    const [state, setState] = useState({
        isAllSelected: false,
        checkList: [ ]
    });

    const ref = useRef(null);

    const handleFilterOpening = () => {
        if (element?.reference_object === "module")
        setIsOpen((prev) => !prev);
    };
    useEffect(() => {
        if (!height || !isOpen || !ref.current) return undefined;
        // @ts-ignore
        const resizeObserver = new ResizeObserver((el) => {
            setHeight(el[0].contentRect.height );
        });
        resizeObserver.observe(ref.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [height, isOpen]);
    useEffect(() => {
        console.log(ref.current?.getBoundingClientRect().height);
        if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
        else setHeight(0);
    }, [isOpen]);



    const onCheckBoxChange  = (checkName, isChecked , option) => {
        let isAllChecked = (checkName === 'all' && isChecked);
        let isAllUnChecked = (checkName === 'all' && !isChecked);
        const checked = isChecked;

        const checkList = state.checkList.map((elem, index) => {
            if(isAllChecked || elem.id+""+ element.element_id === checkName) {
                return {...elem,checked};

            } else if (isAllUnChecked) {
                return {...elem,checked: false}
            }

            return elem;
        }) || [];

        let isAllSelected;

        if(checkList.length>0)
            isAllSelected= (checkList.findIndex((item) => item.checked === false) === -1) || isAllChecked;
        else
            isAllSelected =isAllChecked;


        onSelectChange(option ,element , isChecked , index);

        setState({
            checkList,
            isAllSelected
        });

    };

    useEffect(() => {

        if (element?.reference_object === "module")
        setState({
            ...state,
            checkList:[...element.notions.map((e)=>({...e,checked:false,type:"notions"})),...element.quizzes.map((e)=>({...e,checked:false,type:"quizzes"}))]
        })
    }, [element]);

    return <>
        <div
            className={`NE_collapsable_card_with_checkboxes NE_collapsable_card_with_checkboxes_open ${collapsibleClassName}`}>
            <div className={`header ${headerClassName}`}>
                <CheckBoxComponent id={element?.element_id} name="select-all" value={element?.element_title} tick={state.isAllSelected} onCheck={(e) => onCheckBoxChange('all', e.target.checked)} />
                <span
                    className={` btn_collapse ${
                        isOpen
                            ? "rotate-center-edonec down"
                            : "rotate-center-edonec up"
                    }`}

                    onClick={handleFilterOpening}
                />
            </div>

            <div className={`body ${contentClassName}`} style={{height,}}>
                <ul className="notions_list" ref={ref}>
                    {state.checkList.map((option, index) => {
                        return (
                            <li>
                                  <CheckBoxComponent
                                      id={`${index}-${option.id}-${element?.element_id}`}
                                      key={index+element?.element_id}
                                      name={option.title}
                                      value={option.title}
                                      tick={option.checked}
                                      onCheck={(e) => onCheckBoxChange(option.id+""+element.element_id, e.target.checked ,option )} />
                            </li>
                        );
                    })}

                </ul>
            </div>
        </div>
    </>
}

export default CollapsableCardWithCheckboxes;
