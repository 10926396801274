export const SET_DATA = "editCourse/SET_DATA";
export const LOAD_DATA = "editCourse/LOAD_DATA";

export const IS_LOADING = "editCourse/IS_LOADING";

export const DELETE_ATTACHED_DOCUMENTS = "editCourse/DELETE-ATTACHED-DOCUMENTS";
export const DELETE_ATTACHED_DOCUMENTS_SUCCESS =
  "editCourse/DELETE-ATTACHED-DOCUMENTS-SUCCESS";
export const DELETE_ATTACHED_DOCUMENTS_FAILED =
  "editCourse/DELETE-ATTACHED-DOCUMENTS-FAILED";

export const ADD_ATTACHED_DOCUMENT = "editCourse/ADD-ATTACHED-DOCUMENT";
export const ADD_ATTACHED_DOCUMENT_SUCCESS =
  "editCourse/ADD-ATTACHED-DOCUMENT-SUCCESS";
export const ADD_ATTACHED_DOCUMENT_FAILED =
  "editCourse/ADD-ATTACHED-DOCUMENT-FAILED";

export const EDIT_ATTACHED_DOCUMENT = "editCourse/EDIT-ATTACHED-DOCUMENT";
export const EDIT_ATTACHED_DOCUMENT_SUCCESS =
  "editCourse/EDIT-ATTACHED-DOCUMENT-SUCCESS";
export const EDIT_ATTACHED_DOCUMENT_FAILED =
  "editCourse/EDIT-ATTACHED-DOCUMENT-FAILED";

export const EDIT_EVALUATION = "editCourse/EDIT-EVALUATION";
export const EDIT_EVALUATION_SUCCESS = "editCourse/EDIT-EVALUATION-SUCCESS";
export const EDIT_EVALUATION_FAILED = "editCourse/EDIT-EVALUATION-FAILED";

export const EDIT_QUIZ = "editCourse/EDIT-QUIZ";
export const EDIT_QUIZ_SUCCESS = "editCourse/EDIT-QUIZ-SUCCESS";
export const EDIT_QUIZ_FAILED = "editCourse/EDIT-QUIZ-FAILED";
export const DELETE_EVALUATION_OPTION = "editCourse/DELETE-EVALUATION-OPTION";

export const EDIT_NOTION = "editCourse/EDIT-NOTION";
export const EDIT_NOTION_SUCCESS = "editCourse/EDIT-NOTION-SUCCESS";
export const EDIT_NOTION_FAILED = "editCourse/EDIT-NOTION-FAILED";

export const DELETE_MODULE = "editCourse/DELETE_MODULE";
export const DELETE_MODULE_SUCCESS = "editCourse/DELETE_MODULE_SUCCESS";
export const DELETE_MODULE_FAILED = "editCourse/DELETE_MODULE_FAILED";

export const DELETE_DOCUMENTS = "editCourse/DELETE_DOCUMENTS";
export const DELETE_DOCUMENTS_SUCCESS = "editCourse/DELETE_DOCUMENTS_SUCCESS";
export const DELETE_DOCUMENTS_FAILED = "editCourse/DELETE_DOCUMENTS_FAILED";

export const DELETE_EVALUATION = "editCourse/DELETE_EVALUATION";
export const DELETE_EVALUATION_SUCCESS = "editCourse/DELETE_EVALUATION_SUCCESS";
export const DELETE_EVALUATION_FAILED = "editCourse/DELETE_EVALUATION_FAILED";

export const DELETE_QUIZ = "editCourse/DELETE_QUIZ";
export const DELETE_QUIZ_SUCCESS = "editCourse/DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAILED = "editCourse/DELETE_QUIZ_FAILED";

export const DELETE_NOTION = "editCourse/DELETE_NOTION";
export const DELETE_NOTION_SUCCESS = "editCourse/DELETE_NOTION_SUCCESS";
export const DELETE_NOTION_FAILED = "editCourse/DELETE_NOTION_FAILED";
export const ADD_ELEMENT = "editCourse/ADD_ELEMENT";


export const ADD_ELEMENT_COURSE = "editCourse/ADD_ELEMENT_COURSE";
export const ADD_ELEMENT_COURSE_FAILED = "editCourse/ADD_ELEMENT_COURSE_FAILED";
export const ADD_ELEMENT_COURSE_SUCCESS = "editCourse/ADD_ELEMENT_COURSE_SUCCESS";

export const UP_DOWN_ELEMENT_COURSE = "editCourse/UP_DOWN_ELEMENT_COURSE";

