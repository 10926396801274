export const LOGIN_USER = "LOGIN.LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN.LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN.LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const LOGOUT_USER = "LOGIN.LOGOUT_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";
export const LOGIN_CHANGE_PROPS = "LOGIN.CHANGE_PROPS";
export const LOGIN_CHANGE_VALIDATION_PROPS = "LOGIN.CHANGE_VALIDATION_PROPS";
export const LOGIN_GOOGLE = "LOGIN.LOGIN_GOOGLE";
export const LOGIN_GOOGLE_SUCCESS = "LOGIN.LOGIN_GOOGLE_SUCCESS";
export const LOGIN_GOOGLE_FAILED = "LOGIN.LOGIN_GOOGLE_FAILED";
export const LOGIN_FACEBOOK = "LOGIN.LOGIN_FACEBOOK";
export const LOGIN_FACEBOOK_SUCCESS = "LOGIN.LOGIN_FACEBOOK_SUCCESS";
export const LOGIN_FACEBOOK_FAILED = "LOGIN.LOGIN_FACEBOOK_FAILED";
export const LOGIN_PUSH_ALERT = "LOGIN.CLIENTS-LIST.PUSH_ALERT";
export const LOGIN_CLOSE_ALERT = "LOGIN.CLIENTS-LIST.CLOSE_ALERT";
