import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  RESET_CHANGE_PROPS,
  CLOSE_ALERT, PUSH_ALERT
} from "./action-definitions";

export const changeResetValues = (propName, propValue) => ({
  type: RESET_CHANGE_PROPS,
  payload: { propName, propValue }
});

export const forgetPassword = userinfo => ({
  type: FORGET_PASSWORD,
  payload: { userinfo }
});

export const forgetPasswordSuccess = userinfo => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: userinfo
});

export const forgetPasswordFailed = error => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error
});


export const pushAlert = (id, type, message) => ({
  type: PUSH_ALERT,
  payload: { id, type, message }
});

export const closeAlert = id => ({
  type: CLOSE_ALERT,
  payload: id
});
