import React, { Component } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import "./style.css"

class RichEditor extends Component {
    constructor(props) {
        super(props);
        let contentState = stateFromHTML(this.props?.value);

        this.state = {
            editorState: EditorState.createWithContent(contentState)
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props?.value !== prevProps.value) {
            let contentState = stateFromHTML(this.props?.value);
            this.setState({
                editorState: EditorState.createWithContent(contentState)
            })
        }
    }

    onEditorStateChange = (editorState) => {
        this.props.onChange(stateToHTML(editorState.getCurrentContent()));
        this.setState({
            editorState
        });
    };

    render() {
        const { editorState } = this.state;
        return (
            <div className="editor">
                <Editor
                    editorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        options: ['blockType', 'inline', 'list', 'textAlign', 'link', 'remove', 'history'],
                        blockType: {
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                        },
                        inline: {
                            inDropdown: false,
                            options: ['bold', 'italic', 'underline', 'strikethrough']
                        },
                        list: { inDropdown: false },
                        textAlign: { inDropdown: false },
                        link: { inDropdown: false }
                    }}
                />
            </div>
        );
    }
}

export default RichEditor;
