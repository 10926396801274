import { all, call, delay, fork, put, takeLatest } from "redux-saga/effects";
import { uid } from "uid";
import {
  CONFIG_GET_COLORS
} from "./action-definitions";
import { getClientColors } from "../../services/admin.service";
import { getConfigColorsFailed, getConfigColorsSuccess } from "./action-creators";
// import {loginService} from "./services";

/* Define the actions watchers */
export function* watchGetConfig() {
  yield takeLatest(CONFIG_GET_COLORS, getConfigWorker);
}



/* Define the actions workers */
function* getConfigWorker({ payload: { client_id } }) {

  try {
    const call_result = yield getClientColors(client_id);
    const data = call_result.data !== null ? { ...call_result.data, client_id } : null;
    yield put(getConfigColorsSuccess(data));

  } catch (error) {
    const alert_id = uid();
    yield put(getConfigColorsFailed(alert_id, "danger", "Config colors failed"));

  }
}

// Export the combined sagas
export default function* allSagas() {
  yield all([
    fork(watchGetConfig),
  ]);
}
