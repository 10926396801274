import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DocumentLine from "../DocumentLine";
import "./index.css";




const EtudiantDocument = ({ clickedItem, discussions, onClickItem , callbackfn=()=>null }) => {


    const [documents, setDocuments] = useState(false);

  const { trainingSession } = useSelector(
    (state) => state.participant_course_item
  );
  useEffect(() => {
    setDocuments(
      trainingSession?.content?.find((c) => c.id === clickedItem?.id)
    );
  }, [clickedItem]);


  return (
    <div
      style={{
        flex: 3,
        display: "flex",
        paddingBottom: 100,
        marginLeft: 19,
        marginBottom:0
      }}
      className="NE_notionItem_container"
    >
      <div className="NE_notionItem_header">
        <h2 className="NE_notionItem_header_h2">Documents</h2>
          {callbackfn()}
      </div>

      <div
          style={
            {
              display: "flex",
              flex: 5,
              flexDirection: "column",
              marginTop: "5rem",
              width: "100%"
            }
          }
        >
          <p className={"desc_doc"} >
              {documents?.description}
          </p>
          {documents?.attached_documents?.map((d) => (
            <DocumentLine
              type={""}
              title={d?.title}
              // size="31.1kb"
              fileUrl={d?.file_path}
            />
          ))}
        </div>
    </div>
  );
};

export default EtudiantDocument ;
