import React,{Component} from "react";
import './index.css'

class Dropdown extends Component {
    state = {
        open: false,
        isSelected: false,
        item: 'Choose category'
    };
    handleButtonClick = () => {
        this.setState((state) => {
            return {
                items: this.props.category,
                open: !state.open,
            };
        });
    };

    handleAddClientClick = () => {
        this.setState({
            is_create_user_modal_open: true
        });
    }

    setSelectedItem = (index, id) => {
        this.setState({
            isSelected: true,
            open: false,
            item: this.state.items[index].title
        })

        this.sentValue(id, this.state.items[index].title)
    }

    sentValue = (id, value) => {
        this.props.onChange(id, value);
    }

    componentDidMount() {
        // console.log('child' + JSON.stringify(this.props.category));
    }

    render() {

        let class_name = "dropdown_option ";
        class_name += this.state.isSelected ? "dropdown_option_selected" : "";
        const category = this.props.category;
        const {item} = this.state
        const {onChange,value, placeholder ,...rest} = this.props;
        // console.log(category)
        return(
            <div className="NE_dropdown NE_dropdown_open">
                <label>{this.props.label}</label>

                <div className="dropdown" onClick={this.handleButtonClick}>
                    <p>{placeholder}</p>
                    <div className="down_button"></div>
                </div>
                {this.state.open && (<div className="dropdown_options">
                    {
                        category.map((anObjectMapped, index)=> {
                            return <div key={index} className={class_name} {...rest} onClick={e => {this.setSelectedItem(index, anObjectMapped.id)}}>{anObjectMapped.title}</div>
                     })
                    }
                </div>)
                }
                <p>This is a message</p>
            </div>
        );
    }
}
export default Dropdown;