import {
  LOAD_CLIENT_DETAILS,
  LOAD_CLIENT_DETAILS_ERROR,
  LOAD_CLIENT_DETAILS_SUCCESS,
  CHANGE_CURRENT_CLIENT_VALUE,
  LOAD_AVAILABLE_GAMES,
  LOAD_AVAILABLE_GAMES_SUCCESS,
  LOAD_AVAILABLE_GAMES_ERROR,
  LOAD_AVAILABLE_MODERATORS,
  LOAD_AVAILABLE_MODERATORS_SUCCESS,
  LOAD_AVAILABLE_MODERATORS_ERROR,
  AFFECT_GAMES_TO_CLIENT,
  AFFECT_GAMES_TO_CLIENT_ERROR,
  AFFECT_GAMES_TO_CLIENT_SUCCESS,
  AFFECT_MODERATORS_TO_CLIENT,
  AFFECT_MODERATORS_TO_CLIENT_ERROR,
  AFFECT_MODERATORS_TO_CLIENT_SUCCESS,
  REMOVE_MODERATOR_FROM_CLIENT,
  REMOVE_MODERATOR_FROM_CLIENT_ERROR,
  REMOVE_MODERATOR_FROM_CLIENT_SUCCESS,
  PUSH_ALERT,
  CLOSE_ALERT
} from "./action-definitions";

export const loadClientDetails = client_id => ({
  type: LOAD_CLIENT_DETAILS,
  payload: client_id
});

export const loadClientDetailsSuccess = (moderators, games) => ({
  type: LOAD_CLIENT_DETAILS_SUCCESS,
  payload: { moderators, games }
});

export const loadClientDetailsError = (id, type, message) => ({
  type: LOAD_CLIENT_DETAILS_ERROR,
  payload: { id, type, message }
});

export const changeCurrentClientValue = value => ({
  type: CHANGE_CURRENT_CLIENT_VALUE,
  payload: value
});

export const loadAvailableGames = () => ({
  type: LOAD_AVAILABLE_GAMES,
  payload: null
});

export const loadAvailableGamesSuccess = games => ({
  type: LOAD_AVAILABLE_GAMES_SUCCESS,
  payload: games
});

export const loadAvailableGamesError = (id, type, message) => ({
  type: LOAD_AVAILABLE_GAMES_ERROR,
  payload: { id, type, message }
});

export const loadAvailableModerators = () => ({
  type: LOAD_AVAILABLE_MODERATORS,
  payload: null
});

export const loadAvailableModeratorsSuccess = moderators => ({
  type: LOAD_AVAILABLE_MODERATORS_SUCCESS,
  payload: moderators
});

export const loadAvailableModeratorsError = (id, type, message) => ({
  type: LOAD_AVAILABLE_MODERATORS_ERROR,
  payload: { id, type, message }
});

export const affectGamesToClient = (client, games) => ({
  type: AFFECT_GAMES_TO_CLIENT,
  payload: { client, games }
});

export const affectGamesToClientSuccess = (client_id, games) => ({
  type: AFFECT_GAMES_TO_CLIENT_SUCCESS,
  payload: { client_id, games }
});

export const affectGamesToClientError = (id, type, message) => ({
  type: AFFECT_GAMES_TO_CLIENT_ERROR,
  payload: { id, type, message }
});

export const affectModeratorsToClient = (client, moderators) => ({
  type: AFFECT_MODERATORS_TO_CLIENT,
  payload: { client, moderators }
});

export const affectModeratorsToClientSuccess = (client_id, moderators) => ({
  type: AFFECT_MODERATORS_TO_CLIENT_SUCCESS,
  payload: { client_id, moderators }
});

export const affectModeratorsToClientError = (id, type, message) => ({
  type: AFFECT_MODERATORS_TO_CLIENT_ERROR,
  payload: { id, type, message }
});

export const removeModeratorFromClient = (client, moderator) => ({
  type: REMOVE_MODERATOR_FROM_CLIENT,
  payload: { client, moderator }
});

export const removeModeratorFromClientSuccess = (client_id, moderator) => ({
  type: REMOVE_MODERATOR_FROM_CLIENT_SUCCESS,
  payload: { client_id, moderator }
});

export const removeModeratorFromClientError = (id, type, message) => ({
  type: REMOVE_MODERATOR_FROM_CLIENT_ERROR,
  payload: { id, type, message }
});

export const pushAlert = (id, type, message) => ({
  type: PUSH_ALERT,
  payload: { id, type, message }
});

export const closeAlert = id => ({
  type: CLOSE_ALERT,
  payload: id
});
