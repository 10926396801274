import React,{Component} from "react";
import "./index.css"
import add from "./assets/add.svg"
import iconCloud from "./assets/icon-upload-cloud.svg"
import iconCheck from "./assets/Icon-Check.svg"
import avatar from "./assets/avatar.png"
import RadioAvatar from "../../../../components/RadioAvatar";
import ImagesUploader from "../../../../components/ImagesUploader";
import LargeButton from "../../../../components/LargeButton";
import {changeRegisterValues, completeProfileStep, registerUser} from "../../../../redux/register/action-creators";
import { connect } from "react-redux";

class ImageRegisterModal extends Component{


    handleImageChange = (raw) => {
        const {changeRegisterValues, registerUser} = this.props
        // changeRegisterValues(propName, PropValue);
        changeRegisterValues("image", raw)
        //

        // this.props.onChange(raw);
    }

    handleChangeAvatar = (avatar) => {

    }
    imageHandlerToParent = () => {
        // this.props.onChange();
    }

    render() {

        const {isOpen, onCancel} = this.props;
        let class_name = "modal_image_register_wrapper ";
        class_name += isOpen ? "modal_image_register_wrapper_open" : "";


        return(
            <div className={class_name} onClick={(e) => {
                if(e.target.className === class_name){
                    onCancel();
                }
            }}>
                <div className="NE_body_modal">
                    <div className="NE_text">
                        <h3 className="NE_text_body">Profile picture</h3>
                        <h3 className="NE_text_body" onClick={()=>{onCancel()}}>x</h3>
                    </div>
                    <div className="NE_body_center">
                        <ImagesUploader onImageChange={this.handleImageChange}/>
                        <div className="NE_text">
                            <h3 className="NE_middle_text">Or select image</h3>
                        </div>
                        <div className="NE_global_radio">
                            <RadioAvatar avatar={this.handleChangeAvatar}/>
                        </div>
                    </div>
                    <div className="form-actions">
                        <div className="form-action">
                            <LargeButton button='normal' value="Cancel" onClick={() => {onCancel()}}/>
                        </div>
                        <div className="form-action">
                            <LargeButton button='dark' value="Set image" onClick={() => {}} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { register } = state;
    return { register };
};

const mapActionsToProps = {
    registerUser,
    completeProfileStep,
    changeRegisterValues
};
export default connect(mapStateToProps, mapActionsToProps)(ImageRegisterModal)
