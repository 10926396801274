import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_CHANGE_PROPS,
  CHANGE_CURRENT_CLIENT_INFO_VALIDATION_PROP_VALUE,
  PUSH_ALERT,
  CLOSE_ALERT
} from "./actions-definitions";

// Initial state
const INIT_STATE = {
  // Userinfo,
  current_userinfo: {
    avatar_path: "",
    description: "",
    full_name: "",
    email: "",
    password: "",
    newpassword: "",
    repeat_newpassword: "",
    language: ""
  },
  current_userinfo_validation: {
    avatar_path: {
      is_valid: false,
      error: ""
    },
    description: {
      is_valid: false,
      error: ""
    },
    full_name: {
      is_valid: false,
      error: ""
    },
    email: {
      is_valid: false,
      error: ""
    },
    password: {
      is_valid: false,
      error: ""
    },
    newpassword: {
      is_valid: false,
      error: ""
    },
    repeat_newpassword: {
      is_valid: false,
      error: ""
    },
    language: {
      is_valid: false,
      error: ""
    }
  },
  loading: false,
  currentuser: [],
  alerts: []
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        current_userinfo: { ...state.current_userinfo, ...action.payload },
        loading: false
      };
    case UPDATE_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_USER_CHANGE_PROPS:
      return {
        ...state,
        current_userinfo: {
          ...state.current_userinfo,
          [action.payload.propName]: action.payload.propValue
        }
      };
    case CHANGE_CURRENT_CLIENT_INFO_VALIDATION_PROP_VALUE:
      return {
        ...state,
        current_userinfo_validation: {
          ...state.current_userinfo_validation,
          [action.payload.propName]: action.payload.propValue
        }
      };

    case PUSH_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ]
      };

    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
      };

    default:
      return { ...state };
  }
};

export default reducer;
