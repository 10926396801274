import React, { Component } from "react";
import './index.css'

class LargeButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { onClick = () => { }, value, ...rest } = this.props;

        if (this.props.button == 'dark') {
            return <>
                <button className="NE_button_large NE_button_large_dark" onClick={(e) => { e.preventDefault(); onClick() }} {...rest} >{value}</button>
            </>;
        }
        else if (this.props.button == 'normal') {
            return <>
                <button className="NE_button_large" onClick={(e) => { e.preventDefault(); onClick() }} {...rest} >{value}</button>
            </>;
        }
        else {
            return <>
                <button className="NE_button_large NE_button_large_outline" onClick={(e) => { e.preventDefault(); onClick() }}  {...rest}>{value}</button>
            </>;
        }

    }
}
export default LargeButton;
