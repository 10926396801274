import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import iconQuestion from "../ModuleCard/assets/icon-question.svg";
import { uid } from "uid";
import style from "./style.module.scss"
import { ModuleItemContext } from "../ModuleCard";
import { useDispatch, useSelector } from "react-redux";
import { deleteQuiz } from "../../../../../redux/moderator/configure-course/actions-creators";
import { editQuiz } from "../../../../../redux/moderator/edit-course/actions";
import DeleteElementsModal from "../../../configure-course/components/delete-elements-modal";
import Textarea from "../../../../../components/Textarea";
import { Translation } from "react-i18next";
// import DeleteElementsModal from "../delete-elements-modal";



const ItemContext = createContext({
  items: [],
  _id: null,
  question: "",
  justification: "",

  addItem: () => {
  },
  deleteItem: () => {
  },
  editItem: () => {
  },
  editQuestion: () => {
  },
  editJustification: () => {
  },
  changeQuestionOption: () => {
  }
});

const initialState = [
  // {
  //     text: "option",
  //     question_option:true,
  //     name_group:1,
  //     _id: uid()
  // }
];

const ItemProvider = (props) => {

  const { quiz_group } = props;

  const [items, setItems] = useState(initialState);
  const [question, setQuestion] = useState("");
  const _id = useRef(uid()).current;
  const { items: module_items, editItem: editItem_module, listDeleteQuestionOption } = useContext(ModuleItemContext);
  const [justification, setJustification] = useState("");




  const addItem = (item) => {


    setItems((items) => [...items, { ...item, ...(items.length <= 0 ? { question_option: true, is_correct: true } : {}), state_option: 0 }]);
  }
  const deleteItem = (id) => {

    listDeleteQuestionOption.push(items.find((item) => item._id === id));
    setItems((items) => items.filter((item) => item._id !== id));
  }

  const editItem = (id, text) => {
    const list = items.map((elem) => {
      if (elem._id === id) return { ...elem, content: text, state_option: elem.state_option === 0 ? elem.state_option : 1 };
      return elem;
    });
    setItems(list);
  };

  const editJustification = (text) => {
    const list = props.ListQuiz.map((elem, index) => {
      if (elem._id === props.quiz._id) return { ...elem, justification: text, stateJustification: 1 }
      return elem;
    });

    props.setList(list);

    setJustification(text);
  };

  const editQuestion = (text) => {


    const list = props.ListQuiz.map((elem, index) => {
      if (elem._id === props.quiz._id) return { ...elem, title: text, stateQuestion: 1 }
      return elem;
    });

    props.setList(list);

    setQuestion(text);
  };
  const changeQuestionOption = (id) => {
    //
    const list = items.map((elem) => {
      if (elem._id === id) return { ...elem, question_option: true, is_correct: true, state_option: 1 };
      return { ...elem, question_option: false, is_correct: false };
    });

    //

    setItems(list);
    // props.setList(list);

  };

  useEffect(() => {

    const list = props.ListQuiz.map((elem, index) => {
      if (elem._id === props.quiz._id) return { ...elem, items, question,justification }
      return elem;
    });

    props.setList(list);

  }, [items, question,justification]);

  useEffect(() => {
    if (items.length === 0 && props.quiz.options) {
      //

      setItems(props.quiz.options.map(elm => {
        return {
          ...elm,
          _id: uid()
        }
      }));
    }

  }, [props.quiz]);


  const value = {
    items,
    question,
    _id,
    justification,
    addItem,
    deleteItem,
    editItem,
    editQuestion,
    changeQuestionOption,
    editJustification
  };

  return (
    <ItemContext.Provider value={value}>{props.children}</ItemContext.Provider>
  );
};


function ItemQuestionComponent({ elem, is_save }) {

  const [isEdit, setIsEdit] = useState(false);
  const { deleteItem, editItem, changeQuestionOption, _id } = useContext(ItemContext);


  return <li
    className={`question_option  ${style.question_option} ${elem.is_correct ? 'question_option_selected' : ''} `}>
    <div className={`NE_radio_button ${style.NE_radio_button}`}>
      <input type="radio" name={_id} defaultChecked={elem.is_correct} id={elem._id}
        onClick={() => changeQuestionOption(elem._id)} />
      {isEdit ?
        <label htmlFor={elem._id}>
          <div className={style.block_}>
            <input className={style.input} onChange={(e) => editItem(elem._id, e.target.value)}
              value={elem.content} />
          </div>
        </label>
        :
        <label htmlFor={elem._id}>
          {elem.content}
        </label>
      }
    </div>
    {!is_save && <>
      <span className={`question_option_action ${isEdit ? style.btn_save : style.btn_edit}`}
        onClick={() => setIsEdit(!isEdit)} />
      <span className={`${style.question_option_action_trash}`} onClick={() => deleteItem(elem._id)} />
    </>
    }
    {elem.question_option &&
      <span className={`${style.question_option_action_valid}`} />
    }
    {/*<span className="question_option_action" onClick={onDelete(elem._id)} />*/}
  </li>;
}

function ListQuestionComponent({ name_group, is_save }) {
  const [list, setList] = useState([]);
  const { items, addItem } = useContext(ItemContext);

  useEffect(() => {
    if (items.length === 0){
    addElement();
    addElement();
    addElement();
    addElement();
    }
  }, []);

  const addElement = () => {
    addItem({
      content: "option",
      question_option: false,
      name_group: name_group,
      _id: uid()
    });
  }
  const onDelete = (id) => (e) => {
    setList(list.filter(elm => elm._id !== id))
  }
  return <ul className="question_options">
    {items.map((elem, index) => <ItemQuestionComponent is_save={is_save} elem={elem} key={elem._id}
      onDelete={onDelete} />)}

    {!is_save && <li className="add_question_option" onClick={addElement}>
      Add Option
        </li>}
  </ul>;
}

function QuizFormComponent({ element, deleteElement, is_save }) {
  const { editQuestion, question ,justification,editJustification} = useContext(ItemContext);

  useEffect(() => {
    editQuestion(element.title)
    editJustification(element.justification)
  }, []);

  return <div className={`form-field ${style.form_field}`}>
    <div className="question">
      <div className="NE_normal_input">
        <div className={`${style.block_elemnt}`}>
          <label htmlFor="question1">Question</label>
          {!is_save && <span className={`${style.question_option_action_trash}`}
            onClick={() => deleteElement(element._id)} />
          }                    </div>
        <Translation>
          
          {t =>
            <>
          <input type="text"
          id="question1"
          value={question}
          onChange={(e) => editQuestion(e.target.value)}
          placeholder={t("moderator.question_placeholder")} />
        <p>This is a message</p></>
            }
        </Translation>
        
     
      </div>
      <ListQuestionComponent is_save={is_save} name_group={element._id} />
      <div className="NE_normal_input">
        <Translation>
          {t =>
            <Textarea
           
              value={justification}
              onChange={(e) => editJustification(e.target.value)}
              placeholder={t("moderator.edit_course.justify_response")}
            />}
        </Translation>
        <p>This is a message</p>
      </div>
    </div>
  </div>
}

function QuizFormProvider({ element, quiz_id, setList, ListQuiz, deleteElement, is_save, quiz_group }) {

  return <ItemProvider quiz_group={quiz_group} quiz={element} ListQuiz={ListQuiz} quiz_id={quiz_id} setList={setList}>
    <QuizFormComponent is_save={is_save} deleteElement={deleteElement} element={element} />
  </ItemProvider>;
}

export default function QuizComponent({
  quiz_id,
  module,
  index,
  elem,

}) {
  const [isCollapse, setIsCollapse] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [List, setList] = useState([]);
  const { editItem, deleteItem, items, listDeleteQuestionOption } = useContext(ModuleItemContext);
  const dispatch = useDispatch();
  const [is_open_delete, setIsOpenDelete] = useState(false);
  const [is_save, setIsSave] = useState(false);
  // const {last_update} = useSelector((state)=>state.editCourse);
  const { current_course, isLoading, last_update } = useSelector((state) => state.editCourse);

  const addElement = () => {
    setList([...List, { _id: uid() }])
  };
  const [listDeleteQuestion, setListDeleteQuestion] = useState([]);

  const deleteElement = (id) => {
    const item = List.find((elm) => elm._id === id)
    listDeleteQuestion.push(item)
    setList((prev) => prev.filter((elm) => elm._id !== id))
  };

  useEffect(() => {


    if (elem?.questions?.length > 0) {
      const list = elem.questions.map((elem, index) => {
        return {
          ...elem,
          _id: uid()
        }
      })

      setList(list);

    }

  }, [elem]);



  const isValid = () => {

    return true;
    // if(List.length<=0) return false;
    //
    // return List.reduce((previousValue, currentValue) =>
    //     {
    //         let found = false;
    //         if (currentValue?.items?.length > 0){
    //             found  = !!currentValue.items.find(element => element.question_option === true);
    //         }
    //
    //
    //        return  currentValue.question !== "" && currentValue.items?.length > 0 && found && previousValue;
    //     }, true);

  }

  const deleteQuizButton = () => {

    dispatch(deleteQuiz(module.id, elem.id, () => {}))
    setIsOpenDelete(false)
  }

  return <div className={`body_element_notion ${isCollapse ? "isCollapse" : ""}`}>
    <DeleteElementsModal isOpen={is_open_delete} onCancel={() => setIsOpenDelete(false)} onDelete={deleteQuizButton} />
    {/*<pre>*/}
    {/*     {JSON.stringify(elem,undefined,"\t")}*/}
    {/*</pre>*/}
    <div
      className="NE_collapsable_card_with_icon NE_collapsable_card_with_icon_open quiz_creation_card">
      <div className="header">
        <div className="title">
          <img src={iconQuestion}
            alt="Container icon" />
          <h2>Quiz</h2>
        </div>

        {!current_course.publish && <div className="delete_element_content">
          <span className="btn_delete_element" onClick={() => {
            setIsOpenDelete(true)
            //
          }}>

          </span>
          <span className="btn_collapse" onClick={() => {
            setIsCollapse(!isCollapse)
          }} />
        </div>}
      </div>
      <div className={`body ${style.body}`}>
        <form>
          {
            List.map((element, index) => <QuizFormProvider
              is_save={is_save}
              deleteElement={deleteElement}
              ListQuiz={List}
              setList={setList}
              quiz_id={quiz_id}
              quiz_group={elem}
              element={element}
              key={element._id}
            />)
          }
          {showButtons && <div className="add_question" onClick={addElement} />}

          {showButtons &&
            <div className="actions" style={{ marginTop: "45px" }}>
              <div className="action">
                <button className="NE_button_large NE_button_outline"
                  type="reset">Cancel
                                </button>
              </div>

              <div className="action">
                <button disabled={!isValid()} className="NE_button_large NE_button_dark" type="button"
                  onClick={() => {

                    const list_delete_question = listDeleteQuestion;
                    const list_add_question = [];
                    const list_add_question_option = [];
                    const list_edit_question = [];
                    const list_edit_question_option = [];
                    const list_delete_question_option = listDeleteQuestionOption

                    List.map(function (elem, index) {

                      if (!elem.id) {
                        list_add_question.push({
                          ...elem,
                          title: elem.question,
                          justification: elem.justification,
                          options: elem.items?.map((el, i) => (
                            {
                              ...el,
                              element_order: i,
                              is_correct: el.is_correct ? 1 : 0
                            })),
                          element_order: index,
                          quiz_id: this.id,
                          module_id: this.module_id || module.id,
                        }
                        )
                        return elem;
                      } else if (elem.stateQuestion === 1 || elem.stateJustification === 1) {
                        list_edit_question.push({
                          title: elem.question,
                          justification: elem.justification,
                          id: elem.id,
                          quiz_id: elem.id,
                          module_id: elem.module_id,
                          element_order: index
                        });
                      }
                      elem.items?.map((item, index) => {
                        if (item.state_option === 0) list_add_question_option.push({
                          content: item.content,
                          is_correct: item.is_correct ? 1 : 0,
                          question_id: elem.id,
                          element_order: index,
                          quiz_id: elem.id,
                          module_id: elem.module_id,
                        })
                        else if (item.state_option === 1) list_edit_question_option.push({
                          content: item.content,
                          is_correct: item.is_correct ? 1 : 0,
                          id: item.id,
                          question_id: elem.id,
                          element_order: index,
                          quiz_id: elem.id,
                          module_id: elem.module_id,
                        })
                        return item;
                      })

                      return elem;
                    }.bind(elem))

                    dispatch(editQuiz(
                      list_add_question,
                      list_add_question_option,
                      list_edit_question,
                      list_edit_question_option,
                      list_delete_question_option,
                      list_delete_question,
                      module
                    ))
                  }
                  }>Save
                                </button>
              </div>
            </div>
          }
        </form>
      </div>
    </div>
  </div>;
}
