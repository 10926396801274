import React, {Component} from "react";
import './index.css'
import NormalInput from "../../../components/NormalInput";
import Dropdown from "../../../components/Dropdown";
import Textarea from "../../../components/Textarea";
import ImagesUploader from "../../../components/ImagesUploader";
import NormalButton from "../../../components/NormalButton";

class Terms extends Component {
    render() {
        const {isOpen, onCancel} = this.props;
        let class_name = "terms_modal_wrapper ";
        class_name += isOpen ? 'terms_modal_wrapper_open' : '';


        return (
            <div className={class_name} onClick={(e) => {
                if(e.target.className === class_name){
                    // this.clearLocalState();
                    onCancel();
                }
            }}>
                <div className="NE_terms_elements_wrapper">
                    <div className="terms_modal_body">
                        <div className="form_wrapper">
                            <p>The following Terms and Conditions apply to the use of this website. This Agreement (referred to as the "Agreement") refers to the contractual Agreement between the site owned by NowEdge SARL ("nowedge.io", "we", "on") and you ("User", "you "," Your "," your ") who accept the terms of this Agreement by clicking on the" I accept "box in the User account creation form in the" Terms and conditions "section. This Agreement becomes effective when you submit the User account creation form after clicking on the "I accept" box. This Agreement governs the rules of use of the website and its subdomains. Now in consideration of the terms and conditions of this Agreement contained in the remainder of this document, you agree to all of the following:</p>
                            <h3>1. Click on the button "Create an account"</h3>
                            <p>By using the website, by clicking on the "Create an account" button, you acknowledge that you have accepted the terms and conditions of this agreement. By accepting the terms and conditions of this Agreement, and after having validated the information you entered in the registration form, you become registered as a "Registered User" and agree to be designated as a "User" ( as defined in section 3) which ensures that you are represented in this Agreement. If you do not agree to the terms and conditions of this Agreement, you are not authorized to use this website. The term "Registered User" represents any natural or legal person who has registered on the website.</p>
                            <h3>2. Web Site</h3>
                            <p>The website provides a means for Users to create and participate in trainings. From the nowedge.io website, we provide information about our services, our users and other third-party information. We may change, delete or add some or all of this information at any time.</p>
                            <h3>3. Users</h3>
                            <p>By checking the box "Yes, I have read and accept the terms and conditions of use" in the registration form or the form for creating a project, you designate yourself as a "User".</p>
                            <h3>4. System integrity</h3>
                            <p>You are not authorized to use any device, software, or algorithm, including but not limited to viruses, trojans, worms, DDOS attack, with the intention of causing damage or opposing the proper functioning of the Website. , or any transaction taking place on the Website, to intercept and use the Website's system, data, or personal information. You are prohibited from taking any action that would aim to overload our system, including but not limited to "spam" or other mass email sending techniques.</p>
                            <p>The website keeps your data free of charge and will notify you in advance if for any reason the services should be interrupted and the data erased. If this were the case, the nowedge.io site would offer solutions to extract Users' data before their erasure.</p>
                            <p>The data on the nowedge.io website is properly protected and saved. However, NowEdge SARL does not guarantee Users of the nowedge.io site against any loss of data, in particular in the event of an event that is not the responsibility of NowEdge SARL such as, for example, fire, server incident, war. and acts of terrorism.</p>
                            <h3>5. Information published</h3>
                            <p>A. You are solely and solely responsible for the information you post to our Website, including but not limited to any form of posting, creating a message in a chat room, or through an email system. You grant us the irrevocable right to apply the copyrights as well as the rights of the publicity in respect of this information. The information you post must not: infringe the rights of others, including but not limited to intellectual property rights, publicity, or privacy; be defamatory, threaten, harass; be obscene, indecent, or contain pornography. We are not responsible for any information posted by Registered Users or exhibited for such type of information posted on our Website including but not limited to information about you. We reserve the right to take legal action with respect to information carried on our Website which will be deemed, in our sole discretion, to be in compliance with such type of information, including but not limited to the termination of this Agreement. Furthermore, we cannot control the information provided to you by Registered Users through the Website. It is very rare that information published by people is offensive, threatening or inaccurate. The information provided on the nowedge.io Website is delivered as such and may contain technical inconsistencies or typographical errors.</p>
                            <p>B. The Website may contain links to external websites which are not under the control of nowedge.io. We are not responsible for the content of these websites or any links that may be contained in these external sites.</p>
                            <h3>6. Confidentiality</h3>
                            <p>Nowedge.io expressly refrains from using data concerning users' customers. And this in particular for all marketing or commercial purposes. These data are confidential and remain for the exclusive use of the users who created them. Nowedge.io can only access this data for maintenance or technical support reasons.</p>
                        </div>
                    </div>
                </div>
            </div>
                )
        }
}
export default Terms;