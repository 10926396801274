import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import login from "./login/reducer";
import register from "./register/reducer";
import reset from "./reset/reducer";
import admin_clients_list from "./admin/clients-list/reducer";
import moderator_clients_list from "./moderator/courses-list/reducer";
import participant_courses_list from "./participant/participant-course-list/reducer";
import participant_course_item from "./participant/participant-course-item/reducer";
import participant_points from "./participant/participant-points/reducer";
import admin_client_details from "./admin/client-details/reducer";
import moderator_training_sessions from "./moderator/training-sessions/reducer";
import StudentManagement from "./studentManagement/reducers";

import moderator_configure_course from "./moderator/configure-course/reducer";
import user_information from "./updateuser/reducer";
import EditCourseReducer from "./moderator/edit-course";
import Config from "./config/reducer";

// Create root reducer
const rootReducer = combineReducers({
  login: persistReducer(
    {
      key: "login",
      storage: storage,
      stateReconciler: hardSet,
      blacklist: [
        "email",
        "password",
        "code",
        "is_loading",
        "login_validation",
        "alerts[]"
      ]
    },
    login
  ),
  register: register,
  reset: reset,
  admin_clients_list,
  moderator_courses_list: moderator_clients_list,
  moderator_configure_course: moderator_configure_course,
  user_information: user_information,
  participant_courses_list,
  participant_course_item,
  participant_points,
  admin_client_details,
  moderator_training_sessions,
  editCourse: EditCourseReducer,
  StudentManagement,
  Config:
    persistReducer(
      {
        key: "Config",
        storage: storage,
        stateReconciler: hardSet,
        
      },
      Config
    )
  // moderator_courses_list:moderator_clients_list
});

export default rootReducer;
