import { all, delay, fork, put, takeLatest } from "redux-saga/effects";
import moment from "moment";
import {
  CHANGE_STUDENT_ACTIVATION,
  CREATE_COMMENT,
  CREATE_SESSION,
  DELETE_SESSION,
  LOAD_ALL_SESSIONS,
  LOAD_SELECTED_SESSION_KPIS,
  UPDATE_SESSION
} from "./action-definitions";
import {
  changeStudentActivationError,
  changeStudentActivationSuccess,
  closeAlert,
  createCommentError,
  createCommentSuccess,
  createSessionError,
  createSessionSuccess,
  deleteSessionError,
  deleteSessionSuccess,
  loadAllSessionsError,
  loadAllSessionsSuccess,
  loadSelectedSessionKpisError,
  loadSelectedSessionKpisSuccess,
  pushAlert,
  updateSessionError,
  updateSessionSuccess
} from "./action-creators";
import { uid } from "uid";
import i18n from "../../../../i18n";
import {
  changeStudentActivation,
  createComment,
  createSession,
  deleteSession,
  loadAllSessions,
  loadSelectedSessionKpis,
  updateSession
} from "../../../services/moderator.service";

export function* showAlert(message = "", type = "success") {
  const alert_id = uid();
  yield put(pushAlert(alert_id, type, message));
  yield delay(3000);
  yield put(closeAlert(alert_id));
}

/* Define the actions watchers */
export function* watchLoadAllSessions() {
  yield takeLatest(LOAD_ALL_SESSIONS, loadAllSessionsWorker);
}

export function* watchCreateSession() {
  yield takeLatest(CREATE_SESSION, createSessionWorker);
}

export function* watchUpdateSession() {
  yield takeLatest(UPDATE_SESSION, updateSessionWorker);
}

export function* watchDeleteSession() {
  yield takeLatest(DELETE_SESSION, deleteSessionWorker);
}

export function* watchLoadSelectedSessionKpis() {
  yield takeLatest(LOAD_SELECTED_SESSION_KPIS, loadSelectedSessionKpisWorker);
}

export function* watchChangeStudentActivation() {
  yield takeLatest(CHANGE_STUDENT_ACTIVATION, changeStudentActivationWorker);
}

export function* watchCreateComment() {
  yield takeLatest(CREATE_COMMENT, createCommentWorker);
}

/* Define the actions workers */
function* loadAllSessionsWorker({ payload: course_id }) {
  // Attempt to call backend to load sessions list
  try {
    const call_result = yield loadAllSessions(course_id);

    const now = moment();

    for (let i = 0; i < call_result.data.length; i++) {
      call_result.data[i].start_date = moment(
        call_result.data[i].start_date
      ).format("YYYY-MM-DD");
      call_result.data[i].end_date = moment(
        call_result.data[i].end_date
      ).format("YYYY-MM-DD");

      if (
        now.isBetween(
          call_result.data[i].start_date,
          call_result.data[i].end_date
        )
      ) {
        call_result.data[i].status = "ONGOING";
      } else if (now.isBefore(call_result.data[i].start_date)) {
        call_result.data[i].status = "TO_COME_UP";
      } else {
        call_result.data[i].status = "COMPLETED";
      }
    }

    // Dispatch success
    yield put(loadAllSessionsSuccess(call_result.data));
  } catch (error) {
    const idAlert = uid();

    yield put(
      loadAllSessionsError(
          idAlert,
        "danger",
        i18n.t("moderator.training-sessions.messages.load_all_sessions_error")
      )
    );

    yield delay(3000);
    yield put(closeAlert(idAlert));
  }
}

function* createSessionWorker({ payload: session_data }) {
  // Attempt to call backend to create new session
  try {
    const call_result = yield createSession({
      ...session_data,
      start_date: moment(session_data.start_date).format("YYYY-MM-DD"),
      end_date: moment(session_data.end_date).format("YYYY-MM-DD")
    });

    const now = moment();

    call_result.data.start_date = moment(call_result.data.start_date).format(
      "YYYY-MM-DD"
    );
    call_result.data.end_date = moment(call_result.data.end_date).format(
      "YYYY-MM-DD"
    );

    if (now.isBetween(call_result.data.start_date, call_result.data.end_date)) {
      call_result.data.status = "ONGOING";
    } else if (now.isBefore(call_result.data.start_date)) {
      call_result.data.status = "TO_COME_UP";
    } else {
      call_result.data.status = "COMPLETED";
    }

    // Dispatch success
    yield put(createSessionSuccess(call_result.data));

    const idAlert = uid();
    // Dispatch alert
    yield put(pushAlert(idAlert, "success",
    i18n.t("moderator.training-sessions.messages.create_session_success")));

    yield delay(3000);
    yield put(closeAlert(idAlert));

  } catch (error) {
    const idAlert = uid();

    yield put(
      createSessionError(
          idAlert,
        "danger",
        i18n.t("moderator.training-sessions.messages.create_session_error")
      )
    );
    yield delay(3000);
    yield put(closeAlert(idAlert));
  }
}

function* updateSessionWorker({ payload: session_data }) {
  // Attempt to call backend to update session
  try {
    const call_result = yield updateSession(session_data);

    const now = moment();

    call_result.data.start_date = moment(call_result.data.start_date).format(
      "YYYY-MM-DD"
    );
    call_result.data.end_date = moment(call_result.data.end_date).format(
      "YYYY-MM-DD"
    );

    if (now.isBetween(call_result.data.start_date, call_result.data.end_date)) {
      call_result.data.status = "ONGOING";
    } else if (now.isBefore(call_result.data.start_date)) {
      call_result.data.status = "TO_COME_UP";
    } else {
      call_result.data.status = "COMPLETED";
    }

    // Dispatch success
    yield put(updateSessionSuccess(call_result.data));


    const idAlert = uid();

    // Dispatch alert
    yield put(pushAlert(idAlert, "success",
      i18n.t("moderator.training-sessions.messages.update_session_success"))
    );

    yield delay(3000);
    yield put(closeAlert(idAlert));

  } catch (error) {
    const idAlert = uid();
    yield put(
      updateSessionError(
          idAlert,
        "danger",
        i18n.t("moderator.training-sessions.messages.update_session_error")
      )
    );
    yield delay(3000);
    yield put(closeAlert(idAlert));
  }
}

function* deleteSessionWorker({ payload: session_id }) {
  // Attempt to call backend to delete session
  try {
    yield deleteSession(session_id);

    // Dispatch success
    yield put(deleteSessionSuccess(session_id));

    // Dispatch alert
    // yield put(pushAlert(uid(),"success","Training Session has been deleted successfully"));

    yield showAlert(
      i18n.t("moderator.training-sessions.messages.delete_session_success")
      );
  } catch (error) {

    yield put(
      deleteSessionError(
        uid(),
        "danger",
        i18n.t("moderator.training-sessions.messages.delete_session_error")
      )
    );


  }
}

function* loadSelectedSessionKpisWorker({ payload: session_id }) {
  // Attempt to call backend to get selected session kpis
  try {
    const call_result = yield loadSelectedSessionKpis(session_id);

    const session_kpis = call_result.data;

    // Extract and reformat selected session Kpi objects
    let selected_session_kpis = null;

    // Dashboard_Global Kpis
    let selected_session_dashboard_global_kpis = [];
    session_kpis.global_dashboard.business_game.map(global_dashboard_item => {
      selected_session_dashboard_global_kpis.push({
        id: global_dashboard_item.id,
        title: global_dashboard_item.title,
        type: "BUSINESS_GAME",
        global_order: global_dashboard_item.global_element_order,
        completed_participants: global_dashboard_item.completed_participants
      });
    });

    session_kpis.global_dashboard.documents.map(global_dashboard_item => {
      selected_session_dashboard_global_kpis.push({
        id: global_dashboard_item.id,
        title: global_dashboard_item.title,
        type: "DOCUMENT",
        global_order: global_dashboard_item.global_element_order,
        completed_participants: global_dashboard_item.completed_participants
      });
    });

    session_kpis.global_dashboard.notion.map(global_dashboard_item => {
      selected_session_dashboard_global_kpis.push({
        id: global_dashboard_item.id,
        title: global_dashboard_item.title,
        type: "NOTION",
        global_order: global_dashboard_item.global_element_order,
        completed_participants: global_dashboard_item.completed_participants
      });
    });

    session_kpis.global_dashboard.evaluation.map(global_dashboard_item => {
      selected_session_dashboard_global_kpis.push({
        id: global_dashboard_item.id,
        title: global_dashboard_item.title,
        type: "EVALUATION",
        global_order: global_dashboard_item.global_element_order,
        correct_answers: global_dashboard_item.correct_answers,
        questions: global_dashboard_item.questions.map(question => {
          return {
            id: question.id,
            title: question.title,
            order: question.element_order,
            correct_answers: question.correct_answers
          };
        })
      });
    });

    session_kpis.global_dashboard.quiz.map(global_dashboard_item => {
      selected_session_dashboard_global_kpis.push({
        id: global_dashboard_item.id,
        title: global_dashboard_item.title,
        type: "QUIZ",
        global_order: global_dashboard_item.global_element_order,
        correct_answers: global_dashboard_item.correct_answers,
        questions: global_dashboard_item.questions.map(question => {
          return {
            id: question.id,
            title: question.title,
            order: question.element_order,
            correct_answers: question.correct_answers
          };
        })
      });
    });

    // Dashboard Kpis
    let selected_session_dashboard_kpis = [];
    
    session_kpis.students_dashboard.map(dashboard_item => {
      console.log("dashboard_item", dashboard_item);
      selected_session_dashboard_kpis.push({
        id: dashboard_item.user.id,
        email: dashboard_item.user.email,
        full_name: dashboard_item.user.full_name,
        image_path: dashboard_item.user.avatar_path,
        is_active: dashboard_item.is_active === 1,
        progresses: dashboard_item.progresses && dashboard_item.progresses.length > 0
  ? dashboard_item.progresses.map(progress => {
          return {
            id: progress.id,
            title: progress.element_title,
            order: progress.element_order,
            type: progress.reference_object,
            numerator: progress.numerator,
            denominator: progress.denominator,
            element_status: progress.element_status
          };
        }): []
      });
    });

    // Dashboard_discussion Kpis
    let selected_session_dashboard_discussion_kpis = [];
    session_kpis.discussions_dashboard.map(dashboard_discussion_item => {
      let total_comments_count = 0;
      let current_item = {
        id: dashboard_discussion_item.id,
        title: dashboard_discussion_item.element_title,
        type: dashboard_discussion_item.element_type,
        messages: dashboard_discussion_item.messages
          .filter(message => message.parent_message_id === null)
          .sort((a, b) => {
            if (
              moment(a.posting_time).unix() === moment(b.posting_time).unix()
            ) {
              return 0;
            } else {
              return moment(a.posting_time).unix() <
                moment(b.posting_time).unix()
                ? -1
                : 1;
            }
          })
          .map(message => {
            total_comments_count++;
            return {
              id: message.id,
              parent_id: message.parent_message_id,
              time: message.posting_time,
              content: message.content,
              user: {
                id: message.user.id,
                full_name: message.user.full_name,
                avatar_path: message.user.avatar_path
              },
              replies: dashboard_discussion_item.messages
                .filter(reply => {
                  return reply.parent_message_id === message.id;
                })
                .sort((a, b) => {
                  if (
                    moment(a.posting_time).unix() ===
                    moment(b.posting_time).unix()
                  ) {
                    return 0;
                  } else {
                    return moment(a.posting_time).unix() <
                      moment(b.posting_time).unix()
                      ? -1
                      : 1;
                  }
                })
                .map(reply => {
                  total_comments_count++;
                  return {
                    id: reply.id,
                    parent_id: reply.parent_message_id,
                    time: reply.posting_time,
                    content: reply.content,
                    user: {
                      id: reply.user.id,
                      full_name: reply.user.full_name,
                      avatar_path: reply.user.avatar_path
                    }
                  };
                })
            };
          })
      };

      current_item = { ...current_item, comments_count: total_comments_count };
      selected_session_dashboard_discussion_kpis.push(current_item);
    });

    selected_session_kpis = {
      global_dashboard: selected_session_dashboard_global_kpis,
      dashboard: selected_session_dashboard_kpis,
      dashboard_discussion: selected_session_dashboard_discussion_kpis
    };

    // Dispatch success
    yield put(loadSelectedSessionKpisSuccess(selected_session_kpis));
  } catch (error) {
    console.log("erooooria",error)
    yield put(
      loadSelectedSessionKpisError(
        uid(),
        "danger",
        i18n.t(
          "moderator.training-sessions.messages.load_selected_session_kpis_error"
        )
      )
    );
  }
}

function* changeStudentActivationWorker({ payload }) {
  // Attempt to call backend to change student activation session
  try {
    const call_result = yield changeStudentActivation(
      payload.session_id,
      payload.student_id,
      payload.is_active
    );

    // Dispatch success
    yield put(changeStudentActivationSuccess(call_result.data.is_active === 1));
  } catch (error) {
    yield put(
      changeStudentActivationError(
        uid(),
        "danger",
        i18n.t(
          "moderator.training-sessions.messages.change_student_activation_error"
        )
      )
    );
  }
}

function* createCommentWorker({ payload }) {
  // Attempt to call backend to create comment
  try {
    const call_result = yield createComment(
      payload.discussion_id,
      payload.parent_id,
      payload.content
    );

    const newComment = {
      id: call_result.data.id,
      parent_id: call_result.data.parent_message_id,
      time: call_result.data.posting_time,
      content: call_result.data.content,
      discussion_id: call_result.data.discussion_id,
      user: {
        id: call_result.data.user.id,
        full_name: call_result.data.user.full_name,
        avatar_path: call_result.data.user.avatar_path
      }
    };

    // Dispatch success
    yield put(createCommentSuccess(newComment));
  } catch (error) {

    yield put(
      createCommentError(
        uid(),
        "danger",
        i18n.t(
          "moderator.training-sessions.messages.change_student_activation_error"
        )
      )
    );
  }
}

// Export the combined sagas
export default function* allSagas() {
  yield all([
    fork(watchLoadAllSessions),
    fork(watchCreateSession),
    fork(watchUpdateSession),
    fork(watchDeleteSession),
    fork(watchLoadSelectedSessionKpis),
    fork(watchChangeStudentActivation),
    fork(watchCreateComment)
  ]);
}
