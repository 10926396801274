import {
  LOAD_ALL_SESSIONS,
  LOAD_ALL_SESSIONS_SUCCESS,
  LOAD_ALL_SESSIONS_ERROR,
  PUSH_ALERT,
  CLOSE_ALERT,
  CHANGE_CURRENT_SESSION_PROP_VALUE,
  CHANGE_CURRENT_SESSION_VALIDATION_PROP_VALUE,
  CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_ERROR,
  SET_CURRENT_SESSION,
  UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_ERROR,
  SET_SELECTED_SESSION,
  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_ERROR,
  CLOSE_SESSION_CODE_MODAL,
  LOAD_SELECTED_SESSION_KPIS,
  LOAD_SELECTED_SESSION_KPIS_SUCCESS,
  LOAD_SELECTED_SESSION_KPIS_ERROR,
  SET_SELECTED_DASHBOARD,
  SET_CURRENT_DASHBOARD_SELECTED_ELEMENT,
  CHANGE_STUDENT_ACTIVATION,
  CHANGE_STUDENT_ACTIVATION_ERROR,
  CHANGE_STUDENT_ACTIVATION_SUCCESS,
  CREATE_COMMENT,
  CREATE_COMMENT_ERROR,
  CREATE_COMMENT_SUCCESS, RESET_SELECTED_SESSION_KPIS
} from "./action-definitions";
export const loadAllSessions = course_id => ({
  type: LOAD_ALL_SESSIONS,
  payload: course_id
});

export const loadAllSessionsSuccess = sessions => ({
  type: LOAD_ALL_SESSIONS_SUCCESS,
  payload: sessions
});

export const loadAllSessionsError = (id, type, message) => ({
  type: LOAD_ALL_SESSIONS_ERROR,
  payload: { id, type, message }
});

export const changeCurrentSessionPropValue = (propName, propValue) => ({
  type: CHANGE_CURRENT_SESSION_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeCurrentSessionValidationPropValue = (
  propName,
  propValue
) => ({
  type: CHANGE_CURRENT_SESSION_VALIDATION_PROP_VALUE,
  payload: { propName, propValue }
});

export const createSession = session_data => ({
  type: CREATE_SESSION,
  payload: session_data
});

export const createSessionSuccess = session => ({
  type: CREATE_SESSION_SUCCESS,
  payload: session
});

export const createSessionError = (id, type, message) => ({
  type: CREATE_SESSION_ERROR,
  payload: { id, type, message }
});

export const setCurrentSession = session => ({
  type: SET_CURRENT_SESSION,
  payload: session
});

export const updateSession = session_data => ({
  type: UPDATE_SESSION,
  payload: session_data
});

export const updateSessionSuccess = session => ({
  type: UPDATE_SESSION_SUCCESS,
  payload: session
});

export const updateSessionError = (id, type, message) => ({
  type: UPDATE_SESSION_ERROR,
  payload: { id, type, message }
});

export const deleteSession = session_id => ({
  type: DELETE_SESSION,
  payload: session_id
});

export const deleteSessionSuccess = session_id => ({
  type: DELETE_SESSION_SUCCESS,
  payload: session_id
});

export const deleteSessionError = (id, type, message) => ({
  type: DELETE_SESSION_ERROR,
  payload: { id, type, message }
});

export const loadSelectedSessionKpis = session_id => ({
  type: LOAD_SELECTED_SESSION_KPIS,
  payload: session_id
});

export const loadSelectedSessionKpisSuccess = session_kpis => ({
  type: LOAD_SELECTED_SESSION_KPIS_SUCCESS,
  payload: session_kpis
});

export const loadSelectedSessionKpisError = (id, type, message) => ({
  type: LOAD_SELECTED_SESSION_KPIS_ERROR,
  payload: { id, type, message }
});

export const changeStudentActivation = (session_id, student_id, is_active) => ({
  type: CHANGE_STUDENT_ACTIVATION,
  payload: { session_id, student_id, is_active }
});

export const changeStudentActivationSuccess = is_active => ({
  type: CHANGE_STUDENT_ACTIVATION_SUCCESS,
  payload: is_active
});

export const changeStudentActivationError = (id, type, message) => ({
  type: CHANGE_STUDENT_ACTIVATION_ERROR,
  payload: { id, type, message }
});

export const createComment = (discussion_id, parent_id, content) => ({
  type: CREATE_COMMENT,
  payload: { discussion_id, parent_id, content }
});

export const createCommentSuccess = comment => ({
  type: CREATE_COMMENT_SUCCESS,
  payload: comment
});

export const createCommentError = (id, type, message) => ({
  type: CREATE_COMMENT_ERROR,
  payload: { id, type, message }
});

export const setSelectedSession = session => ({
  type: SET_SELECTED_SESSION,
  payload: session
});

export const setSelectedDashboard = dashboard => ({
  type: SET_SELECTED_DASHBOARD,
  payload: dashboard
});

export const setCurrentDashboardSelectedElement = selected_element => ({
  type: SET_CURRENT_DASHBOARD_SELECTED_ELEMENT,
  payload: selected_element
});

export const closeSessionCodeModal = () => ({
  type: CLOSE_SESSION_CODE_MODAL,
  payload: null
});

export const pushAlert = (id, type, message) => ({
  type: PUSH_ALERT,
  payload: { id, type, message }
});

export const closeAlert = id => ({
  type: CLOSE_ALERT,
  payload: id
});

export const resetSelectedSessionKpis = _ => ({
  type: RESET_SELECTED_SESSION_KPIS,
  payload: null
});
