import {
  GET_PROFILE_POINT,
  GET_PROFILE_POINT_ERROR,
  GET_PROFILE_POINT_SUCCESS
} from "./action-definitions";

// Initial state
const INIT_STATE = {
  errors: false,
  is_loading: false,
  profilePoints: {}
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE_POINT:
      return { ...state, errors: [], is_loading: true };

    case GET_PROFILE_POINT_SUCCESS:
      return {
        ...state,
        profilePoints: action.payload,
        is_loading: false
      };
    case GET_PROFILE_POINT_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    default:
      return { ...state };
  }
};

export default reducer;
