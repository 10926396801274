import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  REGISTER_GOOGLE,
  REGISTER_GOOGLE_SUCCESS,
  REGISTER_GOOGLE_FAILED,
  REGISTER_FACEBOOK,
  REGISTER_FACEBOOK_SUCCESS,
  REGISTER_FACEBOOK_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  REGISTER_CHANGE_PROPS,
  REGISTER_CHANGE_VALIDATION_PROPS,
  COMPLETE_PROFIL_REGISTER,
  COMPLETE_PROFIL_REGISTER_SUCCESS,
  COMPLETE_PROFIL_REGISTER_FAILED,
  CLOSE_ALERT
} from "./action-definitions";

// Initial state
const INIT_STATE = {
  // Userinfo,
  full_name: "",
  email: "",
  password: "",
  repeat_password: "",
  is_loading: false,
  user: [],
  alerts: [],
  register_validation: {
    full_name: {
      is_valid: false,
      error: ""
    },
    email: {
      is_valid: false,
      error: ""
    },
    password: {
      is_valid: false,
      error: ""
    },
    repeat_password: {
      is_valid: false,
      error: ""
    },
    terms: {
      is_valid: false,
      error: ""
    }
  },
  image: "",
  description: "",
  loading: false
};

// Reducer
const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return { ...state, is_loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, is_loading: false, error: null };
    case REGISTER_USER_FAILED:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };
    case REGISTER_GOOGLE:
      return { ...state, is_loading: true };
    case REGISTER_GOOGLE_SUCCESS:
      return { ...state, user: action.payload, is_loading: false, error: null };
    case REGISTER_GOOGLE_FAILED:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };
    case REGISTER_FACEBOOK:
      return { ...state, is_loading: true };
    case REGISTER_FACEBOOK_SUCCESS:
      return { ...state, user: action.payload, is_loading: false, error: null };
    case REGISTER_FACEBOOK_FAILED:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };
    case COMPLETE_PROFIL_REGISTER:
      return { ...state, is_loading: true };
    case COMPLETE_PROFIL_REGISTER_SUCCESS:
      return { ...state, user: action.payload, is_loading: false, error: null };
    case COMPLETE_PROFIL_REGISTER_FAILED:
      return { ...state, error: action.payload, is_loading: false };
    case FORGET_PASSWORD:
      return { ...state, is_loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: true,
        is_loading: false,
        error: null
      };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, is_loading: false };
    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
      };
    case REGISTER_CHANGE_PROPS:
      return { ...state, [action.payload.propName]: action.payload.propValue };
    case REGISTER_CHANGE_VALIDATION_PROPS:
      return {
        ...state,
        register_validation: {
          ...state.register_validation,
          [action.payload.propName]: action.payload.propValue
        }
      };

    default:
      return { ...state };
  }
};

export default Auth;
