import React, { Component, useEffect, useRef, useState } from "react";
import './index.css'
import list from './assets/list.svg';
import award from './assets/awards.svg';
import OptionsPopup from "../OptionsPopup";
import { Translation } from "react-i18next";


function useOutsideAlerter(ref, setOpen) {
  useEffect(() => {

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const CardModulesEvaluations = ({ name, image, description, creation_date, number_of_evaluations, number_of_modules, onClick, category, color, options, showOption = true }) => {

  const [is_options_popup_open, setIs_options_popup_open] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIs_options_popup_open);

  const toggleOptionsPopup = () => {
    setIs_options_popup_open(prv => !prv);
  };

  const handleCardModulesEvaluationsModalCancel = () => {
    setIs_options_popup_open(false);

  }




  return (
    <Translation>
      { t =>

        <>
          <div className="NE_module_evaluation_card" onClick={() => { onClick(); }}>

            <div className="card_header">
              <span className="actions_btn" onClick={(e) => {
                e.stopPropagation();
                toggleOptionsPopup();
              }} />


              <div className="card_image">

                <img src={image} alt="" />
                <div className="card_category" >{category}</div>
              </div>

              <div className="card_secondary_text">{t("moderator.creation_date")} {creation_date}</div>

              <div className={`actions_wrapper ${is_options_popup_open ? "actions_wrapper_open" : ""}`} ref={wrapperRef} >
                {showOption && <OptionsPopup onClick={toggleOptionsPopup} options={options} />}
              </div>
            </div>

            <div className="card_body_moderator">
              <h1>{name}</h1>
              {/*<p>{description}</p>*/}
            </div>

            <div className="card_footer">
              <div className="metric">
                <img src={list} alt="Metric icon" />
                <Translation>
                  {t =>
                    <p><span className="metric_count">{number_of_modules}</span> {t("participant.course_contenu")}</p>}
                </Translation>
              </div>

              <div className="metric">
                <img src={award} alt="Metric icon" />
                <p><span className="metric_count">{number_of_evaluations}</span> {number_of_evaluations <= 1 ? 'Evaluation' : 'Evaluations'}</p>
              </div>
            </div>
          </div>
        </>
      }
    </Translation>
  )

}
export default CardModulesEvaluations;
