import React, { Component } from "react";
import './index.css';
import Addition_icon from './assets/add.svg'
import { Translation } from "react-i18next";

class CardAdditionAction extends Component {
    render() {
        const { onClick = () => { } } = this.props;
        const { current_user } = this.props;
        let username = current_user?.email.substring(0, current_user?.email.lastIndexOf("@")) || "";

        username =username.charAt(0).toUpperCase() + username.slice(1) || "";


        return (
            <Translation>
                { t =>

                    <>
                        <div className="NE_addition_action_card" onClick={current_user.expired ? null : onClick} >
                            <div className="card_header">
                                <div className="card_action">
                                    <img src={Addition_icon} alt="Addition icon" />
                                </div>
                            </div>

                            <div className="card_body">
                                <h1>{t("participant.hello_msg")} {username} !</h1>
                                <p>{current_user?.role.name === 'Participant' ?  t("participant.keep_learning_msg") : t("moderator.create_msg") }</p>
                            </div>

                            <div className="card_footer">
                                <button onClick={current_user.expired ? null : onClick}>{current_user?.role.name === 'Participant' ? `${t("participant.join_text")} ${t("participant.course_text")}` : t("moderator.create_text")} </button>
                            </div>
                        </div>
                    </>
                }
            </Translation>
        )
    }
}
export default CardAdditionAction;
