import {
  ADD_BUSINESS_GAME,
  ADD_BUSINESS_GAME_FAILED,
  ADD_BUSINESS_GAME_SUCCESS,
  ADD_COURSE,
  ADD_COURSE_DOCUMENTS_ELEMENT,
  ADD_COURSE_DOCUMENTS_ELEMENT_ERROR,
  ADD_COURSE_DOCUMENTS_ELEMENT_SUCCESS,
  ADD_COURSE_FAILED,
  ADD_COURSE_SUCCESS,
  ADD_DOCUMENT,
  ADD_DOCUMENT_FAILED,
  ADD_DOCUMENT_SUCCESS,
  ADD_ELEMENT,
  ADD_ELEMENT_FAILED,
  ADD_ELEMENT_SUCCESS,
  ADD_ELEMENT_TO_COURSE,
  ADD_EVALUATION,
  ADD_EVALUATION_FAILED,
  ADD_EVALUATION_SUCCESS,
  ADD_MODULE,
  ADD_MODULE_FAILED,
  ADD_MODULE_SUCCESS,
  ADD_NOTION,
  ADD_NOTION_FAILED,
  ADD_NOTION_SUCCESS,
  ADD_QUIZ,
  ADD_QUIZ_FAILED,
  ADD_QUIZ_SUCCESS,
  CHANGE_COURSE_PROP_VALUE,
  CHANGE_COURSE_PROP_VALUE_BY_ID,
  CHANGE_CURRENT_COURSE_ELEMENT_PROP_VALUE,
  CHANGE_CURRENT_COURSE_PROP_VALUE,
  CHANGE_CURRENT_COURSE_VALIDATION_PROP_VALUE,
  CHANGE_CURRENT_ELEMENT_PROPS,
  CHANGE_DOCUMENT_PROP_VALUE,
  CHANGE_ELEMENT_ORDER,
  CHANGE_ELEMENT_PROP_VALUE,
  CHANGE_MODULE_PROP_VALUE,
  CHANGE_NOTION_PROP_VALUE,
  CHANGE_QUIZ_PROP_VALUE,
  CLOSE_ALERT,
  DELETE_CURRENT_COURSE_DOCUMENTS_ELEMENT_ITEM,
  DELETE_MODULE,
  DELETE_MODULE_FAILED,
  DELETE_MODULE_SUCCESS,
  DELETE_NOTION,
  DELETE_NOTION_FAILED,
  DELETE_NOTION_SUCCESS,
  EDIT_TYPE_ELEMENT,
  ELEMENT_ADDED,
  LOAD_ALL_CATEGORIES,
  LOAD_ALL_CATEGORIES_ERROR,
  LOAD_ALL_CATEGORIES_SUCCESS,
  LOAD_ALL_GAMES,
  LOAD_ALL_GAMES_ERROR,
  LOAD_ALL_GAMES_SUCCESS,
  LOAD_COURSE_CONTENT,
  LOAD_COURSE_CONTENT_ERROR,
  LOAD_COURSE_CONTENT_SUCCESS,
  PUSH_ALERT,
  PUSH_CURRENT_ELEMENT,
  PUSH_ELEMENT,
  RESET_ELEMENT,
  SET_CURRENT_STEP,
  DELETE_GAME,
  DELETE_GAME_SUCCESS,
  DELETE_GAME_FAILED,
  DELETE_DOCUMENTS,
  DELETE_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENTS_FAILED,
  DELETE_QUIZ,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAILED,
  DELETE_EVALUATION,
  DELETE_EVALUATION_SUCCESS,
  DELETE_EVALUATION_FAILED,
  PUBLISH,
  CLEAR_ELEMENT_COURSE,
  EDIT_DOCUMENTS_ELEMENT,
  EDIT_DOCUMENTS_ELEMENT_SUCCESS,
  EDIT_DOCUMENTS_ELEMENT_ERROR,
  EDIT_MODULE,
  EDIT_MODULE_SUCCESS,
  EDIT_MODULE_FAILED,
  EDIT_ITEM_COURSE,
  EDIT_ITEM_COURSE_FAILED,
  EDIT_ITEM_COURSE_SUCCESS, EDIT_COURSE, EDIT_COURSE_SUCCESS, EDIT_COURSE_FAILED
} from "./actions-definitions";

export const addElement = Data => ({
  type: ADD_ELEMENT,
  payload: Data
});

export const addElementSuccess = element => ({
  type: ADD_ELEMENT_SUCCESS,
  payload: element
});

export const addElementFailed = ERROR => ({
  type: ADD_ELEMENT_FAILED,
  payload: ERROR
});

export const addDocuments = Data => ({
  type: ADD_DOCUMENT,
  payload: Data
});

export const addDocumentSuccess = document => ({
  type: ADD_DOCUMENT_SUCCESS,
  payload: document
});

export const addDocumentFailed = errors => ({
  type: ADD_DOCUMENT_FAILED,
  payload: errors
});

export const addModule = (Data, callback) => ({
  type: ADD_MODULE,
  payload: { ...Data, callback }
});

export const addModuleSuccess = (_id, Module) => ({
  type: ADD_MODULE_SUCCESS,
  payload: { _id, Module }
});

export const addModuleFailed = errors => ({
  type: ADD_MODULE_FAILED,
  payload: errors
});


export const editModule = (id,
  title,
  description,
  element_order) => ({
    type: EDIT_MODULE,
    payload: {
      id,
      title,
      description,
      element_order
    }
  });

export const editModuleSuccess = (data) => ({
  type: EDIT_MODULE_SUCCESS,
  payload: data
});

export const editModuleFailed = errors => ({
  type: EDIT_MODULE_FAILED,
  payload: errors
});

export const addNotion = (Notion, callback) => ({
  type: ADD_NOTION,
  payload: { Notion, callback }
});

export const addNotionSuccess = (_id, Notion) => ({
  type: ADD_NOTION_SUCCESS,
  payload: { _id, Notion }
});

export const addNotionFailed = errors => ({
  type: ADD_NOTION_FAILED,
  payload: errors
});

export const deleteNotion = (module_id, id, callback) => ({
  type: DELETE_NOTION,
  payload: { module_id, id, callback }
});

export const deleteNotionSuccess = (module_id, id) => ({
  type: DELETE_NOTION_SUCCESS,
  payload: { module_id, id }
});

export const deleteNotionFailed = errors => ({
  type: DELETE_NOTION_FAILED,
  payload: errors
});

export const deleteQuiz = (module_id, id, callback) => ({
  type: DELETE_QUIZ,
  payload: { module_id, id, callback }
});

export const deleteQuizSuccess = (module_id, id) => ({
  type: DELETE_QUIZ_SUCCESS,
  payload: { module_id, id }
});

export const deleteQuizFailed = errors => ({
  type: DELETE_QUIZ_FAILED,
  payload: errors
});

export const deleteModule = id => ({
  type: DELETE_MODULE,
  payload: { id }
});

export const deleteModuleSuccess = id => ({
  type: DELETE_MODULE_SUCCESS,
  payload: { id }
});

export const deleteModuleFailed = errors => ({
  type: DELETE_MODULE_FAILED,
  payload: errors
});

export const deleteEvaluation = (_id, id) => ({
  type: DELETE_EVALUATION,
  payload: { _id, id }
});

export const deleteEvaluationSuccess = _id => ({
  type: DELETE_EVALUATION_SUCCESS,
  payload: { _id }
});

export const deleteEvaluationFailed = errors => ({
  type: DELETE_EVALUATION_FAILED,
  payload: errors
});

export const deleteDocuments = (id, _id) => ({
  type: DELETE_DOCUMENTS,
  payload: { id, _id }
});

export const deleteDocumentsSuccess = _id => ({
  type: DELETE_DOCUMENTS_SUCCESS,
  payload: { _id }
});

export const deleteDocumentsFailed = errors => ({
  type: DELETE_DOCUMENTS_FAILED,
  payload: errors
});

export const deleteGame = (id, _id) => ({
  type: DELETE_GAME,
  payload: { id, _id }
});

export const deleteGameSuccess = id => ({
  type: DELETE_GAME_SUCCESS,
  payload: { id }
});

export const deleteGameFailed = errors => ({
  type: DELETE_GAME_FAILED,
  payload: errors
});

export const addQuiz = (List, module, index, callback) => ({
  type: ADD_QUIZ,
  payload: { List, module, index, callback }
});

export const addQuizSuccess = (_id, Quiz) => ({
  type: ADD_QUIZ_SUCCESS,
  payload: { _id, Quiz }
});

export const addQuizFailed = errors => ({
  type: ADD_QUIZ_FAILED,
  payload: errors
});

export const addEvaluation = (element, course_id, List, index, callback) => ({
  type: ADD_EVALUATION,
  payload: { List, course_id, element, index, callback }
});

export const addEvaluationSuccess = (oldEvaluation, Evaluation) => ({
  type: ADD_EVALUATION_SUCCESS,
  payload: { oldEvaluation, Evaluation }
});

export const addEvaluationFailed = errors => ({
  type: ADD_EVALUATION_FAILED,
  payload: errors
});

export const addBusinessGame = (
  course_id,
  element_order,
  game_id,
  client_affectation_id,
  _id,
  stratedge_configuration_name,
  callback
) => ({
  type: ADD_BUSINESS_GAME,
  payload: {
    course_id,
    element_order,
    game_id,
    client_affectation_id,
    callback,
    _id,
    stratedge_configuration_name
  }
});

export const addBusinessGameSuccess = (_id, businessGame) => ({
  type: ADD_BUSINESS_GAME_SUCCESS,
  payload: { _id, businessGame }
});

export const addBusinessGameFailed = errors => ({
  type: ADD_BUSINESS_GAME_FAILED,
  payload: errors
});

export const changeElementPropValue = (propName, propValue) => ({
  type: CHANGE_ELEMENT_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeModulePropValue = (propName, propValue) => ({
  type: CHANGE_MODULE_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeNotionPropValue = (propName, propValue) => ({
  type: CHANGE_NOTION_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeDocumentPropValue = (propName, propValue) => ({
  type: CHANGE_DOCUMENT_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeQuizPropValue = (propName, propValue) => ({
  type: CHANGE_QUIZ_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeOrderPropValue = (propName, propValue) => ({
  type: CHANGE_ELEMENT_ORDER,
  payload: { propName, propValue }
});

export const addElementToCourse = (propName, propValue) => ({
  type: ADD_ELEMENT_TO_COURSE,
  payload: { propName, propValue }
});

export const changeCoursePropValue = (propName, propValue) => ({
  type: CHANGE_COURSE_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeCoursePropValueById = element => ({
  type: CHANGE_COURSE_PROP_VALUE_BY_ID,
  payload: element
});

export const changeCurrentElementPropValue = (propName, propValue) => ({
  type: CHANGE_CURRENT_ELEMENT_PROPS,
  payload: { propName, propValue }
});

export const loadAllCategories = () => ({
  type: LOAD_ALL_CATEGORIES,
  payload: null
});

export const loadAllCategoriesSuccess = categories => ({
  type: LOAD_ALL_CATEGORIES_SUCCESS,
  payload: categories
});

export const loadAllCategoriesError = (id, type, message) => ({
  type: LOAD_ALL_CATEGORIES_ERROR,
  payload: { id, type, message }
});

export const addCourse = courseData => ({
  type: ADD_COURSE,
  payload: courseData
});

export const addCourseSuccess = course => ({
  type: ADD_COURSE_SUCCESS,
  payload: course
});

export const addCourseFailed = (id, type, message) => ({
  type: ADD_COURSE_FAILED,
  payload: { id, type, message }
});

export const editCourse = (courseData, callback) => ({
  type: EDIT_COURSE,
  payload: { courseData, callback }
});

export const editCourseSuccess = course => ({
  type: EDIT_COURSE_SUCCESS,
  payload: course
});

export const editCourseFailed = (id, type, message) => ({
  type: EDIT_COURSE_FAILED,
  payload: { id, type, message }
});

export const setCurrentStep = current_step => ({
  type: SET_CURRENT_STEP,
  payload: current_step
});

export const changeCurrentCoursePropValue = (propName, propValue) => ({
  type: CHANGE_CURRENT_COURSE_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeCurrentCourseElementPropValue = (
  element,
  propName,
  propValue
) => ({
  type: CHANGE_CURRENT_COURSE_ELEMENT_PROP_VALUE,
  payload: { element, propName, propValue }
});

export const deleteCurrentCourseDocumentsElementItem = (element, document) => ({
  type: DELETE_CURRENT_COURSE_DOCUMENTS_ELEMENT_ITEM,
  payload: { element, document }
});

export const addCourseDocumentsElement = (
  course_id,
  element,
  element_order,
  callback
) => ({
  type: ADD_COURSE_DOCUMENTS_ELEMENT,
  payload: { course_id, element, element_order, callback }
});

export const addCourseDocumentsElementSuccess = (_id, documents_element) => ({
  type: ADD_COURSE_DOCUMENTS_ELEMENT_SUCCESS,
  payload: { _id, documents_element }
});

export const addCourseDocumentsElementError = (id, type, message) => ({
  type: ADD_COURSE_DOCUMENTS_ELEMENT_ERROR,
  payload: { id, type, message }
});

export const editDocumentsElement = (
  id,
  title,
  media_path,
  element_order,
  description,
  published
) => ({
  type: EDIT_DOCUMENTS_ELEMENT,
  payload: {
    id,
    title,
    media_path,
    element_order,
    description, published
  }
});

export const editDocumentsElementSuccess = (_id, documents_element) => ({
  type: EDIT_DOCUMENTS_ELEMENT_SUCCESS,
  payload: { _id, documents_element }
});

export const editDocumentsElementError = (id, type, message) => ({
  type: EDIT_DOCUMENTS_ELEMENT_ERROR,
  payload: { id, type, message }
});


export const loadCourseContent = course_id => ({
  type: LOAD_COURSE_CONTENT,
  payload: course_id
});

export const loadCourseContentSuccess = course_content => ({
  type: LOAD_COURSE_CONTENT_SUCCESS,
  payload: course_content
});

export const loadCourseContentError = (id, type, message) => ({
  type: LOAD_COURSE_CONTENT_ERROR,
  payload: { id, type, message }
});

export const pushCurrentElement = () => ({
  type: PUSH_CURRENT_ELEMENT,
  payload: null
});

export const pushAlert = (id, type, message) => ({
  type: PUSH_ALERT,
  payload: { id, type, message }
});

export const closeAlert = id => ({
  type: CLOSE_ALERT,
  payload: id
});

export const changeCurrentCourseValidationPropValue = (
  propName,
  propValue
) => ({
  type: CHANGE_CURRENT_COURSE_VALIDATION_PROP_VALUE,
  payload: { propName, propValue }
});
export const loadAllGames = () => ({
  type: LOAD_ALL_GAMES,
  payload: null
});

export const loadAllGamesSuccess = games => ({
  type: LOAD_ALL_GAMES_SUCCESS,
  payload: games
});

export const loadAllGamesError = (id, type, message) => ({
  type: LOAD_ALL_GAMES_ERROR,
  payload: { id, type, message }
});

export const pushElement = element => ({
  type: PUSH_ELEMENT,
  payload: element
});

export const editTypElement = element => ({
  type: EDIT_TYPE_ELEMENT,
  payload: element
});

export const elementAdded = value => ({
  type: ELEMENT_ADDED,
  payload: value
});

export const restElement = element => ({
  type: RESET_ELEMENT,
  payload: element
});

export const publishCourse = () => ({
  type: PUBLISH,
  payload: {}
});

export const clearElementCourse = () => ({
  type: CLEAR_ELEMENT_COURSE,
  payload: {}
});


export const editItemCourse = (item, type) => ({
  type: EDIT_ITEM_COURSE,
  payload: {
    item,
    type
  }
});

export const editItemCourseSuccess = (data) => ({
  type: EDIT_ITEM_COURSE_SUCCESS,
  payload: data
});

export const editItemCourseFailed = errors => ({
  type: EDIT_ITEM_COURSE_FAILED,
  payload: errors
});
