import React, { Component, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./index.css";
import { subscribeTrainingSession } from "../../../../../redux/participant/participant-course-list/action-creators";
import { Translation } from "react-i18next";

const JoinCourseModal = ({
  show,
  openWonModal = () => null,
  close = () => null,
}) => {
  let class_name = "NE_join_course_modal_modal_wrapper ";
  class_name += show ? "modal_open" : "";

  const [code, setCode] = useState("");

  const { errors } = useSelector((state) => state.participant_courses_list);
  const dispatch = useDispatch();

  return (
    <Translation>
      { t =>
        <>


          <div
            className={class_name}
            style={{
              zIndex: 3,
            }}
            onClick={(e) => {
              if (e.target.className === class_name) {
                close();
              }
            }}
          >
            <div className="NE_join_course_modal">
              <div className="modal_header">
                <p className="modal_title">{t("participant.join-course.join_course_text")}</p>
                <p className="modal_close" onClick={() => {
                  close()
                }}>X</p>
              </div>

              <div className="modal_body">
                <form action="#" method="post" encType="multipart/form-data">
                  <div className="form-field">
                    <div className="NE_normal_input">
                      <label htmlFor="session_code">{t("participant.join-course.enter_code_text")}</label>
                      <input
                        type="text"
                        id="session_code"
                        placeholder={t("participant.join-course.enter_session_code_placeholder")}
                        value={code}
                        onChange={(evt) => {
                          setCode(evt.target.value);
                        }}
                      />
                      <p>This is a message</p>

                    </div>

                  </div>
                  <p
                    style={{
                      color: "red",
                      marginLeft: 35,
                    }}
                  >
                    {errors}
                  </p>
                </form>
              </div>

              <div className="modal_footer">
                <div className="actions">
                  <div className="action">
                    <button
                      className="NE_button NE_button_outline"
                      onClick={() => {
                        close();
                      }}
                    >
                      {t("participant.join-course.cancel_button")}
                    </button>
                  </div>

                  <div className="action">
                    <button
                      className="NE_button NE_button_dark"
                      onClick={() => {
                        console.log("-----", code);

                        dispatch(
                          subscribeTrainingSession(code, () => {
                            openWonModal();
                          })
                        );
                      }}
                    >
                      {t("participant.join-course.join_button")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </Translation>
  );

};

export default JoinCourseModal;
