import {
  AFFECT_GAMES_TO_CLIENT_ERROR,
  AFFECT_GAMES_TO_CLIENT_SUCCESS,
  AFFECT_MODERATORS_TO_CLIENT_ERROR,
  AFFECT_MODERATORS_TO_CLIENT_SUCCESS,
  CHANGE_CURRENT_CLIENT_VALUE,
  LOAD_AVAILABLE_GAMES_ERROR,
  LOAD_AVAILABLE_GAMES_SUCCESS,
  LOAD_AVAILABLE_MODERATORS_ERROR,
  LOAD_AVAILABLE_MODERATORS_SUCCESS,
  LOAD_CLIENT_DETAILS_ERROR,
  LOAD_CLIENT_DETAILS_SUCCESS,
  REMOVE_MODERATOR_FROM_CLIENT_ERROR,
  REMOVE_MODERATOR_FROM_CLIENT_SUCCESS,
  CLOSE_ALERT,
  PUSH_ALERT,
  LOAD_CLIENT_DETAILS,
  LOAD_AVAILABLE_GAMES,
  LOAD_AVAILABLE_MODERATORS,
  AFFECT_GAMES_TO_CLIENT,
  AFFECT_MODERATORS_TO_CLIENT,
  REMOVE_MODERATOR_FROM_CLIENT
} from "./action-definitions";

import { findIndex } from "lodash";

// Initial state
const INIT_STATE = {
  current_client: {
    id: null,
    image: null,
    full_name: "",
    type: "",
    phone: "",
    email: "",
    address: "",
    expiration_date: ""
  },
  current_client_moderators: [],
  current_client_games: [],
  available_games: [],
  current_client_selected_games: [],
  available_moderators: [],
  alerts: [],
  is_loading: false
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_CURRENT_CLIENT_VALUE:
      return { ...state, current_client: { ...action.payload } };

    case LOAD_CLIENT_DETAILS:
      return { ...state, is_loading: true };

    case LOAD_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        current_client_moderators: [...action.payload.moderators],
        current_client_games: [...action.payload.games],
        is_loading: false
      };

    case LOAD_CLIENT_DETAILS_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case LOAD_AVAILABLE_GAMES:
      return { ...state, is_loading: true };

    case LOAD_AVAILABLE_GAMES_SUCCESS:
      return {
        ...state,
        available_games: [...action.payload],
        is_loading: false
      };

    case LOAD_AVAILABLE_GAMES_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case LOAD_AVAILABLE_MODERATORS:
      return { ...state, is_loading: true };

    case LOAD_AVAILABLE_MODERATORS_SUCCESS:
      return {
        ...state,
        available_moderators: [...action.payload],
        is_loading: false
      };

    case LOAD_AVAILABLE_MODERATORS_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case AFFECT_GAMES_TO_CLIENT:
      return { ...state, is_loading: true };

    case AFFECT_GAMES_TO_CLIENT_SUCCESS:
      return {
        ...state,
        current_client_games: [
          ...state.current_client_games,
          ...action.payload.games
        ],
        is_loading: false
      };

    case AFFECT_GAMES_TO_CLIENT_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case AFFECT_MODERATORS_TO_CLIENT:
      return { ...state, is_loading: true };

    case AFFECT_MODERATORS_TO_CLIENT_SUCCESS:
      return {
        ...state,
        current_client_moderators: [
          ...state.current_client_moderators,
          ...action.payload.moderators
        ],
        is_loading: false
      };

    case AFFECT_MODERATORS_TO_CLIENT_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case REMOVE_MODERATOR_FROM_CLIENT:
      return { ...state, is_loading: true };

    case REMOVE_MODERATOR_FROM_CLIENT_SUCCESS:
      return {
        ...state,
        current_client_moderators: [
          ...state.current_client_moderators.filter(
            moderator => moderator.id !== action.payload.moderator.id
          )
        ],
        is_loading: false
      };

    case REMOVE_MODERATOR_FROM_CLIENT_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case PUSH_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ]
      };

    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
      };

    default:
      return { ...state };
  }
};

export default reducer;
