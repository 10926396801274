export const INIT_SUCCESS_STUDENT = "INIT_SUCCESS_STUDENT";
export const GET_PARTICIPANTS_MODERATOR_FAILED = "GET_PARTICIPANTS_MODERATOR_FAILED";
export const GET_PARTICIPANTS_MODERATOR_SUCCESS = "GET_PARTICIPANTS_MODERATOR_SUCCESS";
export const GET_PARTICIPANTS_MODERATOR = "GET_PARTICIPANTS_MODERATOR";
export const SELECT_PARTICIPANTS_MODERATOR = "SELECT_PARTICIPANTS_MODERATOR";
export const CHANGE_STATUS_PARTICIPANT_MODERATOR = "CHANGE_STATUS_PARTICIPANT_MODERATOR";
export const CHANGE_STATUS_PARTICIPANT_MODERATOR_SUCCESS = "CHANGE_STATUS_PARTICIPANT_MODERATOR_SUCCESS";
export const CHANGE_STATUS_PARTICIPANT_MODERATOR_FAILED = "CHANGE_STATUS_PARTICIPANT_MODERATOR_FAILED";
export const GET_QUIZ_STATISTICS_MODERATOR = "GET_QUIZ_STATISTICS_MODERATOR";
export const GET_QUIZ_STATISTICS_MODERATOR_SUCCESS = "GET_QUIZ_STATISTICS_MODERATOR_SUCCESS";
export const GET_QUIZ_STATISTICS_MODERATOR_FAILED = "GET_QUIZ_STATISTICS_MODERATOR_FAILED";
export const SELECT_QUIZ_STATISTICS_MODERATOR = "SELECT_QUIZ_STATISTICS_MODERATOR";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAILED = "GET_TEAMS_FAILED";
export const GET_TEAMS = "GET_TEAMS";
export const ASSIGNMENT_TEAMS = "ASSIGNMENT_TEAMS";
export const ASSIGNMENT_TEAMS_SUCCESS = "ASSIGNMENT_TEAMS_SUCCESS";
export const ASSIGNMENT_TEAMS_FAILE = "ASSIGNMENT_TEAMS_FAILE";
export const ASSIGNMENT_TEAMS_FAILED = "ASSIGNMENT_TEAMS_FAILED";