import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearQuizQuestionResult,
  pushQuizQuestionResult,
  saveQuiz,
  getQuiz,
} from "../../redux/participant/participant-course-item/action-creators";
import Question from "../Question";
import "./index.css";

const EtudiantQuiz = ({ clickedItem, discussions, onClickItem ,callbackfn =()=>null }) => {
  const [quiz, setQuiz] = useState(false);
  const [submitIndex, setSubmitIndex] = useState(0);

  const dispatch = useDispatch();
  const { trainingSession, quiz_question_results } = useSelector(
    (state) => state.participant_course_item
  );

  useEffect(() => {
    setSubmitIndex(0);
    setQuiz(
      trainingSession?.content
        ?.find((c) => c.id === clickedItem?.parentId)
        ?.quizzes?.find((e) => e.id === clickedItem?.id)
    );
  }, [clickedItem]);

  useEffect(() => {
    dispatch(clearQuizQuestionResult());
    if (quiz?.id)
      dispatch(getQuiz(trainingSession?.training_session?.id, quiz?.id));
  }, [quiz]);

  // NE_CourseSideBar_course

  useEffect(() => {
    const sideBa = document.getElementById('NE_CourseSideBar_course');
    const quizz = document.getElementById('NE_QUIZ_item');
   
    quizz.style.height = `${sideBa.clientHeight}px`;

  }, []);

  return (
    <div className="NE_etudiant_quiz_course">
      <div className="NE_QUIZ " id="NE_QUIZ_item">
        {quiz?.questions?.map((question, index) => {
          const responseQuestion = quiz_question_results?.find(
            (qr) => qr.question_id === question.id
          );
          return (
            <Question
              key={question.id}
              question={question}
              index={index}
              responseQuestion={responseQuestion}
              isSubmit={responseQuestion ? false : index === submitIndex}
              onSubmit={(value) => {
                dispatch(
                  pushQuizQuestionResult({
                    question_id: question.id,
                    question_option_id: value,
                  })
                );

                if (submitIndex === quiz?.questions?.length - 1) {
                  dispatch(
                    saveQuiz(trainingSession?.training_session?.id, quiz?.id, [
                      ...quiz_question_results,
                      {
                        question_id: question.id,
                        question_option_id: value,
                      },
                    ])
                  );
                }
                setSubmitIndex(submitIndex + 1);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default EtudiantQuiz;
