import {
  LOAD_TRAINING_SESSION,
  LOAD_TRAINING_SESSION_SUCCESS,
  LOAD_TRAINING_SESSION_ERROR,
  LOAD_COURSE_SIDEBAR_DATA,
  DISPATCH_CLICKED_ITEM,
  GET_DISCUSSION_TRAINING_SESSION,
  GET_DISCUSSION_TRAINING_SESSION_SUCCESS,
  GET_DISCUSSION_TRAINING_SESSION_ERROR,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_ERROR,
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_ERROR,
  GET_PROGRESS,
  GET_PROGRESS_SUCCESS,
  GET_PROGRESS_ERROR,
  UPDATE_PROGRESS,
  UPDATE_PROGRESS_SUCCESS,
  UPDATE_PROGRESS_ERROR,
  PUSH_QUIZ_QUESTION_RESULT,
  CLEAR_QUIZ_QUESTION_RESULT,
  SAVE_QUIZ,
  SAVE_QUIZ_SUCCESS,
  SAVE_QUIZ_ERROR,
  GET_QUIZ,
  GET_QUIZ_SUCCESS,
  GET_QUIZ_ERROR,
  LOAD_COURSE,
  LOAD_COURSE_SUCCESS,
  LOAD_COURSE_ERROR
} from "./action-definitions";

export const loadTrainingSession = training_session_id => ({
  type: LOAD_TRAINING_SESSION,
  payload: { training_session_id }
});

export const loadTrainingSessionSuccess = trainingSession => ({
  type: LOAD_TRAINING_SESSION_SUCCESS,
  payload: trainingSession
});

export const loadCourseSideBarData = data => ({
  type: LOAD_COURSE_SIDEBAR_DATA,
  payload: data
});

export const loadTrainingSessionError = errors => ({
  type: LOAD_TRAINING_SESSION_ERROR,
  payload: errors
});

export const dispatchClickedItem = item => ({
  type: DISPATCH_CLICKED_ITEM,
  payload: item
});

export const getDiscussionTrainingSession = training_session_id => ({
  type: GET_DISCUSSION_TRAINING_SESSION,
  payload: { training_session_id }
});

export const getDiscussionTrainingSessionSuccess = discussions => ({
  type: GET_DISCUSSION_TRAINING_SESSION_SUCCESS,
  payload: discussions
});

export const getDiscussionTrainingSessionError = errors => ({
  type: GET_DISCUSSION_TRAINING_SESSION_ERROR,
  payload: errors
});

export const getMessages = discussion_id => ({
  type: GET_MESSAGES,
  payload: { discussion_id }
});

export const getMessagesSuccess = messages => ({
  type: GET_MESSAGES_SUCCESS,
  payload: messages
});

export const getMessagesError = errors => ({
  type: GET_MESSAGES_ERROR,
  payload: errors
});

export const addMessage = (
  discussion_id,
  parent_message_id,
  content,
  onFinish
) => ({
  type: ADD_MESSAGE,
  payload: { discussion_id, parent_message_id, content, onFinish }
});

export const addMessageSuccess = message => ({
  type: ADD_MESSAGE_SUCCESS,
  payload: message
});

export const addMessageError = errors => ({
  type: ADD_MESSAGE_ERROR,
  payload: errors
});

export const getProgress = training_session_id => ({
  type: GET_PROGRESS,
  payload: { training_session_id }
});

export const getProgressSuccess = progress => ({
  type: GET_PROGRESS_SUCCESS,
  payload: progress
});

export const getProgressError = errors => ({
  type: GET_PROGRESS_ERROR,
  payload: errors
});

export const updateProgress = (training_session_id, progress_id) => ({
  type: UPDATE_PROGRESS,
  payload: { training_session_id, progress_id }
});

export const updateProgressSuccess = progress => ({
  type: UPDATE_PROGRESS_SUCCESS,
  payload: progress
});

export const updateProgressError = errors => ({
  type: UPDATE_PROGRESS_ERROR,
  payload: errors
});

export const saveQuiz = (training_session_id, quiz_id, question_results) => ({
  type: SAVE_QUIZ,
  payload: { training_session_id, quiz_id, question_results }
});

export const saveQuizSuccess = progress => ({
  type: SAVE_QUIZ_SUCCESS,
  payload: progress
});

export const saveQuizError = errors => ({
  type: SAVE_QUIZ_ERROR,
  payload: errors
});
export const getQuiz = (training_session_id, quiz_id) => ({
  type: GET_QUIZ,
  payload: { training_session_id, quiz_id }
});

export const getQuizSuccess = quiz => ({
  type: GET_QUIZ_SUCCESS,
  payload: quiz
});

export const getQuizError = errors => ({
  type: GET_QUIZ_ERROR,
  payload: errors
});
export const pushQuizQuestionResult = result => ({
  type: PUSH_QUIZ_QUESTION_RESULT,
  payload: result
});

export const clearQuizQuestionResult = () => ({
  type: CLEAR_QUIZ_QUESTION_RESULT,
  payload: null
});

export const loadCourse = course_id => ({
  type: LOAD_COURSE,
  payload: { course_id }
});

export const loadCourseSuccess = course => ({
  type: LOAD_COURSE_SUCCESS,
  payload: course
});

export const loadCourseError = errors => ({
  type: LOAD_COURSE_ERROR,
  payload: errors
});
