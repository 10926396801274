import React, {Component} from "react";
import './index.css'
import icon from './assets/icon-documents.svg';
import DocumentsUploader from "../../../../../components/DocumentsUploader";
import LargeButton from "../../../../../components/LargeButton";
import {
    addCourseDocumentsElement,
    deleteDocuments, deleteDocumentsSuccess,
    restElement
} from "../../../../../redux/moderator/configure-course/actions-creators";
import {connect} from "react-redux";
import DeleteElementsModal from "../delete-elements-modal";
import Switch from "../../../../../components/Switch";
import PublishedElement from "../../../edit-course/components/PublishedElement/PublishedElement";

class DocumentsCard extends Component {

    state = {
        is_open: true,
        showButtons: true,
        is_open_delete:false,
        description:""
    };

    toggleCollapse = () => {
        this.setState({
            ...this.state,
            is_open: !this.state.is_open
        });
    };


    onSave = (element) => {
        const {addCourseDocumentsElement} = this.props;
        const {current_course} = this.props.moderator_configure_course;

        // Dispatch action to save documents element
        addCourseDocumentsElement(current_course.id, {...element,description:this.state.description}, current_course.elements.findIndex((e) => e._id === element._id), () => {
            this.setState({
                showButtons: false
            })
        });
    }

    deleteEvaluationButton= ()=>{
        const {element} = this.props;

        if(element.id)  this.props.deleteDocuments(element.id, element._id)
        else  this.props.deleteDocumentsSuccess(element._id)
    }

    render() {
        const {element, onUpdateAttachedDocuments, onDeleteAttachedDocument,} = this.props;

        const {is_open} = this.state;

        let class_name = "NE_collapsable_card_with_icon notion_creation_card ";
        class_name += is_open ? "NE_collapsable_card_with_icon_open" : "";


        return <>
            <DeleteElementsModal  isOpen={this.state.is_open_delete} onCancel={()=> this.setState({is_open_delete:false})} onDelete={this.deleteEvaluationButton} />

            <div className={class_name}>
                <div className="header">
                    <div className="title">
                        <img src={icon} alt="Container icon"/>
                        <h2>{element?.title}</h2>
                    </div>

                    {!this.props.moderator_configure_course.current_course.publish && <div className="delete_element_content">
                        {element.id && <PublishedElement element={element} type={"document"}/>}

                        <span className="btn_delete_element" onClick={() => {
                               this.setState({
                                   is_open_delete: true
                               })
                           }}>

                           </span>
                        <span className="btn_collapse" onClick={() => {
                            this.toggleCollapse();
                        }}/>
                    </div>}

                </div>

                <div className="body">
                    <form action="#" method="post" encType="multipart/form-data">
                        <div className="NE_normal_textarea NE_normal_textarea_success NE_normal_textarea_error ">
                                <textarea
                                    className={"textarea_doc"}
                                    maxLength={1000}
                                    value={this.state.description}
                                    onChange={(e) => {
                                        this.setState({description:e.target.value})
                                    }}
                                    placeholder="Description"
                                />
                        </div>
                        <br/>
                        <div className="form-field">
                            <DocumentsUploader
                                showUploader={this.state.showButtons}
                                activeDelete={this.state.showButtons}
                                items={element?.attached_documents ? element.attached_documents : []}
                                onDocumentsChange={onUpdateAttachedDocuments}
                                onDocumentDelete={(document) => {
                                    onDeleteAttachedDocument(element, document);
                                }}
                            />
                        </div>

                        {this.state.showButtons && <div className="actions">
                            <div className="action">
                                <LargeButton value="Cancel" onClick={() => {
                                    this.props.restElement(element);
                                }}/>
                            </div>

                            <div className="action">
                                <LargeButton button="dark" value="Save" onClick={() => {
                                    this.onSave(element);
                                }}/>
                            </div>
                        </div>}
                    </form>
                </div>
            </div>
        </>
    }
}


const mapStateToProps = state => {
    const {moderator_courses_list, moderator_configure_course} = state;
    return {moderator_courses_list, moderator_configure_course};
};

const mapActionsToProps = {
    addCourseDocumentsElement,
    restElement,
    deleteDocuments,
    deleteDocumentsSuccess
};


export default connect(mapStateToProps, mapActionsToProps)(DocumentsCard);
