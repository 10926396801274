import React, {Component, useEffect, useState} from 'react';
import './index.css';

import history from "../../../utils/history";


const Switch  = ({init = false , onChange = b=>null}) => {

    const [checked, setChecked] = useState(init);

    const onCheck = (e)=>{
        onChange(!checked)
        setChecked(()=>!checked);
    }

    useEffect(() => {
        setChecked(init);
    }, [init]);


    return (
        <input className="switch" type="checkbox" onChange={onCheck} checked={checked}/>
    );
}

export default Switch;
