import React, {useEffect} from 'react';
import "./index.css";
import NormalInput from "../../../../../components/NormalInput";
import NormalButton from "../../../../../components/NormalButton";
import DatePickerInput from "../../../../../components/DateInput";
import {Translation} from 'react-i18next';

const SessionModal = (props) => {

    let {isOpen, currentSession, currentSessionValidation, action, isFormValid, onSubmit, onClose, onChange} = props;
    currentSession.start_date = currentSession?.start_date ? new Date(currentSession?.start_date) : new Date()
    currentSession.end_date = currentSession?.end_date ? new Date(currentSession?.end_date) : new Date();



    useEffect(() => {

        onChange('start_date', currentSession.start_date);
        onChange('end_date', currentSession.end_date);


    }, [isOpen]);



    let class_name = "session_modal_wrapper ";

    class_name += isOpen ? "session_modal_wrapper_open" : "";

    return (
        <Translation>
            {t =>
                <>
                    <div
                        className={class_name}
                        onClick={(e) => {
                             if (e.target.className === class_name) {
                               onClose();
                             }
                        }}
                    >
                        <div className="NE_modal">
                            <div className="modal_header">
                                <p className="modal_title">{action === "CREATE" ?
                                    t("moderator.training-sessions.statics.sessions-modal.create_session_text")
                                    : t("moderator.training-sessions.statics.sessions-modal.update_session_text")}
                                </p>
                                <p className="modal_close" onClick={() => {
                                    onClose();
                                }}>X</p>
                            </div>
                            <div className="modal_body">
                                <form action="#" method="post">
                                    <div className="form-field">
                                        <NormalInput
                                            id="session_title"
                                            label={t("moderator.training-sessions.statics.sessions-modal.session_title_text")}
                                            placeholder={t("moderator.training-sessions.statics.sessions-modal.session_title_placeholder")}
                                            value={currentSession?.title}
                                            isValid={currentSessionValidation?.title.is_valid}
                                            message={currentSessionValidation?.title.error}
                                            onChange={(e) => {
                                                onChange('title', e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="double-form-fields">
                                        <div className="form-field">
                                            <DatePickerInput
                                                placeholder="YYYY-MM-DD"
                                                label={t("moderator.training-sessions.statics.sessions-modal.start_date_text")}
                                                value={currentSession?.start_date}
                                                isValid={currentSessionValidation?.start_date.is_valid}
                                                message={currentSessionValidation?.start_date.error}
                                                onChange={(vale) => {
                                                    console.log('start_date', vale);

                                                    onChange('start_date', vale);
                                                }}
                                                selectsStart
                                                selected={currentSession?.start_date}
                                                startDate={currentSession?.start_date}
                                                endDate={currentSession?.end_date}
                                                minDate={new Date()}

                                            />
                                        </div>
                                        <div className="form-field">
                                            <DatePickerInput
                                                placeholder="YYYY-MM-DD"
                                                label={t("moderator.training-sessions.statics.sessions-modal.end_date_text")}
                                                value={currentSession?.end_date}
                                                onChange={(vale) => onChange('end_date', vale)}
                                                selectsEnd
                                                selected={currentSession?.end_date}
                                                startDate={currentSession?.start_date}
                                                endDate={currentSession?.end_date}
                                                minDate={currentSession?.start_date}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="modal_footer">
                                <div className="actions">
                                    <div className="action">
                                        <NormalButton
                                            value={t("moderator.training-sessions.statics.sessions-modal.cancel_button")}
                                            onClick={onClose}/>
                                    </div>
                                    <div className="action">
                                        <NormalButton value={action === "CREATE" ?
                                            t("moderator.training-sessions.statics.sessions-modal.create_button") :
                                            t("moderator.training-sessions.statics.sessions-modal.update_button")}
                                                      button="dark" disabled={!isFormValid} onClick={() => {
                                            if (isFormValid) {
                                                onSubmit();
                                            }
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </Translation>
    );
}

export default SessionModal;
