export const ADD_ELEMENT = "MODERATOR.ADD_ELEMENT";
export const ADD_ELEMENT_SUCCESS = "MODERATOR.ADD_ELEMENT_SUCCESS";
export const ADD_ELEMENT_FAILED = "MODERATOR.ADD_ELEMENT_FAILED";

export const ADD_MODULE = "MODERATOR.ADD_MODULE";
export const ADD_MODULE_SUCCESS = "MODERATOR.ADD_MODULE_SUCCESS";
export const ADD_MODULE_FAILED = "MODERATOR.ADD_MODULE_FAILED";

export const EDIT_MODULE = "MODERATOR.EDIT_MODULE";
export const EDIT_MODULE_SUCCESS = "MODERATOR.EDIT_MODULE_SUCCESS";
export const EDIT_MODULE_FAILED = "MODERATOR.EDIT_MODULE_FAILED";

export const ADD_NOTION = "MODERATOR.ADD_NOTION";
export const ADD_NOTION_SUCCESS = "MODERATOR.ADD_NOTION_SUCCESS";
export const ADD_NOTION_FAILED = "MODERATOR.ADD_NOTION_FAILED";

export const DELETE_NOTION = "MODERATOR.DELETE_NOTION";
export const DELETE_NOTION_SUCCESS = "MODERATOR.DELETE_NOTION_SUCCESS";
export const DELETE_NOTION_FAILED = "MODERATOR.DELETE_NOTION_FAILED";

export const DELETE_MODULE = "MODERATOR.DELETE_MODULE";
export const DELETE_MODULE_SUCCESS = "MODERATOR.DELETE_MODULE_SUCCESS";
export const DELETE_MODULE_FAILED = "MODERATOR.DELETE_MODULE_FAILED";

export const DELETE_GAME = "MODERATOR.GAME_MODULE";
export const DELETE_GAME_SUCCESS = "MODERATOR.GAME_MODULE_SUCCESS";
export const DELETE_GAME_FAILED = "MODERATOR.GAME_MODULE_FAILED";

export const DELETE_DOCUMENTS = "MODERATOR.DELETE_DOCUMENTS";
export const DELETE_DOCUMENTS_SUCCESS = "MODERATOR.DELETE_DOCUMENTS_SUCCESS";
export const DELETE_DOCUMENTS_FAILED = "MODERATOR.DELETE_DOCUMENTS_FAILED";

export const DELETE_QUIZ = "MODERATOR.DELETE_QUIZ";
export const DELETE_QUIZ_SUCCESS = "MODERATOR.DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAILED = "MODERATOR.DELETE_QUIZ_FAILED";

export const DELETE_EVALUATION = "MODERATOR.DELETE_EVALUATION";
export const DELETE_EVALUATION_SUCCESS = "MODERATOR.DELETE_EVALUATION_SUCCESS";
export const DELETE_EVALUATION_FAILED = "MODERATOR.DELETE_EVALUATION_FAILED";

export const ADD_DOCUMENT = "MODERATOR.ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "MODERATOR.ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAILED = "MODERATOR.ADD_DOCUMENT_FAILED";

export const ADD_QUIZ = "MODERATOR.ADD_QUIZ";
export const ADD_QUIZ_SUCCESS = "MODERATOR.ADD_QUIZ_SUCCESS";
export const ADD_QUIZ_FAILED = "MODERATOR.ADD_QUIZ_FAILED";

export const ADD_EVALUATION = "MODERATOR.ADD_EVALUATION";
export const ADD_EVALUATION_SUCCESS = "MODERATOR.ADD_EVALUATION_SUCCESS";
export const ADD_EVALUATION_FAILED = "MODERATOR.ADD_EVALUATION_FAILED";

export const ADD_BUSINESS_GAME = "MODERATOR.ADD_BUSINESS_GAME";
export const ADD_BUSINESS_GAME_SUCCESS = "MODERATOR.ADD_BUSINESS_GAME_SUCCESS";
export const ADD_BUSINESS_GAME_FAILED = "MODERATOR.ADD_BUSINESS_GAME_FAILED";

export const CHANGE_ELEMENT_PROP_VALUE = "MODERATOR.CHANGE_ELEMENT_PROP_VALUE";
export const CHANGE_NOTION_PROP_VALUE = "MODERATOR.CHANGE_NOTION_PROP_VALUE";
export const CHANGE_DOCUMENT_PROP_VALUE =
  "MODERATOR.CHANGE_DOCUMENT_PROP_VALUE";
export const CHANGE_MODULE_PROP_VALUE = "MODERATOR.CHANGE_MODULE_PROP_VALUE";
export const CHANGE_QUIZ_PROP_VALUE = "MODERATOR.CHANGE_QUIZ_PROP_VALUE";
export const CHANGE_ELEMENT_ORDER = "MODERATOR.CHANGE_ELEMENT_ORDER";

export const ADD_COURSE = "MODERATOR.CONFIGURE-COURSE.ADD_COURSE";
export const ADD_COURSE_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_SUCCESS";
export const ADD_COURSE_FAILED = "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_FAILED";

export const EDIT_COURSE = "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE";
export const EDIT_COURSE_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE_SUCCESS";
export const EDIT_COURSE_FAILED = "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE_FAILED";
export const CHANGE_COURSE_PROP_VALUE = "MODERATOR.CHANGE_COURSE_PROP_VALUE";

export const PUBLISH = "MODERATOR.CONFIGURE-COURSE.PUBLISH";

export const ADD_ELEMENT_TO_COURSE = "ADD_ELEMENT_TO_COURSE";

export const CHANGE_COURSE_PROP_VALUE_BY_ID = "CHANGE_COURSE_PROP_VALUE_BY_ID";

export const LOAD_ALL_CATEGORIES =
  "MODERATOR.CONFIGURE-COURSE.LOAD_ALL_CATEGORIES";
export const LOAD_ALL_CATEGORIES_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.LOAD_ALL_CATEGORIES_SUCCESS";
export const LOAD_ALL_CATEGORIES_ERROR =
  "MODERATOR.CONFIGURE-COURSE.LOAD_ALL_CATEGORIES_ERROR";
export const SET_CURRENT_STEP = "MODERATOR.CONFIGURE-COURSE.SET_CURRENT_STEP";
export const CHANGE_CURRENT_COURSE_PROP_VALUE =
  "MODERATOR.CONFIGURE-COURSE.CHANGE_CURRENT_COURSE_PROP_VALUE";
export const CHANGE_CURRENT_COURSE_ELEMENT_PROP_VALUE =
  "MODERATOR.CONFIGURE-COURSE.CHANGE_CURRENT_COURSE_ELEMENT_PROP_VALUE";
export const DELETE_CURRENT_COURSE_DOCUMENTS_ELEMENT_ITEM =
  "MODERATOR.CONFIGURE-COURSE.DELETE_CURRENT_COURSE_DOCUMENTS_ELEMENT_ITEM";
export const ADD_COURSE_DOCUMENTS_ELEMENT =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_DOCUMENTS_ELEMENT";
export const ADD_COURSE_DOCUMENTS_ELEMENT_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_DOCUMENTS_ELEMENT_SUCCESS";
export const ADD_COURSE_DOCUMENTS_ELEMENT_ERROR =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_DOCUMENTS_ELEMENT_ERROR";

export const EDIT_DOCUMENTS_ELEMENT =
    "MODERATOR.CONFIGURE-COURSE.EDIT_DOCUMENTS_ELEMENT";
export const EDIT_DOCUMENTS_ELEMENT_SUCCESS =
    "MODERATOR.CONFIGURE-COURSE.EDIT_DOCUMENTS_ELEMENT_SUCCESS";
export const EDIT_DOCUMENTS_ELEMENT_ERROR =
    "MODERATOR.CONFIGURE-COURSE.EDIT_DOCUMENTS_ELEMENT_ERROR";

export const LOAD_COURSE_CONTENT =
  "MODERATOR.CONFIGURE-COURSE.LOAD_COURSE_CONTENT";
export const LOAD_COURSE_CONTENT_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.LOAD_COURSE_CONTENT_SUCCESS";
export const LOAD_COURSE_CONTENT_ERROR =
  "MODERATOR.CONFIGURE-COURSE.LOAD_COURSE_CONTENT_ERROR";
export const ADD_COURSE_EVALUATION_ELEMENT_QUESTION =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_EVALUATION_ELEMENT_QUESTION";
export const ADD_QUESTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT =
  "MODERATOR.CONFIGURE-COURSE.ADD_QUESTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT";
export const ADD_QUESTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.ADD_QUESTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_SUCCESS";
export const ADD_QUESTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_ERROR =
  "MODERATOR.CONFIGURE-COURSE.ADD_QUESTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_ERROR";
export const CHANGE_COURSE_EVALUATION_ELEMENT_QUESTION_PROP_VALUE =
  "MODERATOR.CONFIGURE-COURSE.CHANGE_COURSE_EVALUATION_ELEMENT_QUESTION_PROP_VALUE";
export const ADD_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION";
export const CHANGE_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION_PROP_VALUE =
  "MODERATOR.CONFIGURE-COURSE.CHANGE_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION_PROP_VALUE";
export const DELETE_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION =
  "MODERATOR.CONFIGURE-COURSE.DELETE_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION";
export const ADD_COURSE_EVALUATION_ELEMENT =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_EVALUATION_ELEMENT";
export const ADD_COURSE_EVALUATION_ELEMENT_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_EVALUATION_ELEMENT_SUCCESS";
export const ADD_COURSE_EVALUATION_ELEMENT_ERROR =
  "MODERATOR.CONFIGURE-COURSE.ADD_COURSE_DEVALUATION_ELEMENT_ERROR";
export const EDIT_COURSE_EVALUATION_ELEMENT_QUESTION =
  "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE_EVALUATION_ELEMENT_QUESTION";
export const EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_SUCCESS";
export const EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_ERROR =
  "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_ERROR";
export const ADD_OPTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_QUESTION =
  "MODERATOR.CONFIGURE-COURSE.ADD_OPTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_QUESTION";
export const ADD_OPTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_QUESTION_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.ADD_OPTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_QUESTION_SUCCESS";
export const ADD_OPTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_QUESTION_ERROR =
  "MODERATOR.CONFIGURE-COURSE.ADD_OPTION_TO_EXISTING_COURSE_EVALUATION_ELEMENT_QUESTION_ERROR";
export const EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION =
  "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION";
export const EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION_SUCCESS";
export const EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION_ERROR =
  "MODERATOR.CONFIGURE-COURSE.EDIT_COURSE_EVALUATION_ELEMENT_QUESTION_OPTION_ERROR";

export const CLOSE_ALERT = "MODERATOR.CONFIGURE-COURSE.CLOSE_ALERT";

//

export const LOAD_ALL_GAMES = "MODERATOR.CONFIGURE-COURSE.LOAD_ALL_GAMES";
export const LOAD_ALL_GAMES_SUCCESS =
  "MODERATOR.CONFIGURE-COURSE.LOAD_ALL_GAMES_SUCCESS";
export const LOAD_ALL_GAMES_ERROR =
  "MODERATOR.CONFIGURE-COURSE.LOAD_ALL_GAMES_ERROR";
export const CHANGE_CURRENT_ELEMENT_PROPS =
  "MODERATOR.CHANGE_CURRENT_ELEMENT_PROPS";
export const PUSH_CURRENT_ELEMENT = "MODERATOR.PUSH_CURRENT_ELEMENT";
export const PUSH_ALERT = "MODERATOR.PUSH_ALERT";

export const CHANGE_CURRENT_COURSE_VALIDATION_PROP_VALUE =
  "CHANGE_CURRENT_COURSE_VALIDATION_PROP_VALUE";

export const PUSH_ELEMENT = "MODERATOR.PUSH_ELEMENT";
export const EDIT_TYPE_ELEMENT = "MODERATOR.EDIT_TYPE_ELEMENT";

export const RESET_ELEMENT = "MODERATOR.RESET_ELEMENT";

export const ELEMENT_ADDED = "MODERATOR.ELEMENT_ADDED";

export const CLEAR_ELEMENT_COURSE = "MODERATOR.CLEAR_ELEMENT_COURSE";



export const EDIT_ITEM_COURSE = "MODERATOR.EDIT_ITEM_COURSE";
export const EDIT_ITEM_COURSE_SUCCESS = "MODERATOR.EDIT_ITEM_COURSE_SUCCESS";
export const EDIT_ITEM_COURSE_FAILED = "MODERATOR.EDIT_ITEM_COURSE_FAILED";
