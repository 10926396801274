import React, {Component} from 'react';
import "./index.css";

class Counter extends Component {
    state = {
        value:0,
        mode:"INCREMENTS"
    }

    componentDidMount() {
        const {initialValue} = this.props;

        this.setState({
            ...this.state,
            value: initialValue
        });
    }

    incrementValue = () => {
        const {value} = this.state;
        const {step,max,onChange} = this.props;

        if(max !== undefined){
            if((value + step) <= max){
                this.setState({
                    ...this.state,
                    value: value + step
                });

                // Call onChange callback
                onChange(value + step);
            }
        }else{
            this.setState({
                ...this.state,
                value: value + step
            });

            // Call onChange callback
            onChange(value + step);
        }
    }

    decrementValue = () => {
        const {value} = this.state;
        const {step,min,onChange} = this.props;

        if(min !== undefined){
            if((value - step) >= min){
                this.setState({
                    ...this.state,
                    value: value - step
                });

                // Call onChange callback
                onChange(value - step);
            }
        }else{
            this.setState({
                ...this.state,
                value: value - step
            });

            // Call onChange callback
            onChange(value - step);
        }

    }

    handleCounterValueChange = (value) => {
        const {min,max,onChange} = this.props;

        if(value >= min && (max ? value <= max : true)){
            this.setState({
                ...this.state,
                value: value
            });

            // Call onChange callback
            onChange(value);
        }
    }

    changeCounterMode = (mode) => {
        this.setState({
            ...this.state,
            mode:mode
        });
    }

    render() {
        const {value,mode} = this.state;
        const {min,max,step} = this.props;

        let class_name = "NE_counter ";
        class_name += mode === "EDIT" ? "NE_counter_edit" : "";

        return (
            <div className={class_name} onClick={(e) => {e.stopPropagation()}}>
                <div className="counter_btn decrement_btn" onClick={this.decrementValue} />
                <p onDoubleClick={() => {this.changeCounterMode("EDIT")}}>{value}</p>
                <input type="number" min={min} max={max} step={step}  value={value}
                       onChange={(e) => {this.handleCounterValueChange(Number(e.target.value));}}
                       onKeyPress={(e) => {
                           if(e.code.toUpperCase() === "ENTER"){
                               this.changeCounterMode("INCREMENTS");
                           }
                       }} />
                <div className="counter_btn increment_btn" onClick={this.incrementValue} />
            </div>
        );
    }
}

export default Counter;