

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentLine from "../DocumentLine";
import "./index.css";
import imgSVG from "./assets/Group_10279.svg";
import { getMessages } from "../../redux/participant/participant-course-item/action-creators";
import { redirectToApp } from "../../../utils/redirectToApp";
import useGetGame from "../../../utils/useGetGame";
import _ from "lodash"


const CourseBusinessGame = ({ clickedItem, discussions, onClickItem, callbackfn = () => null }) => {

  const [documents, setDocuments] = useState(false);
  const dispatch = useDispatch();

  const { trainingSession } = useSelector(
    (state) => state.participant_course_item
  );
  const { game, url } = useGetGame(clickedItem);
  const gameInfo = trainingSession?.content?.find(item => item.id === clickedItem.id) || {};
   console.log("game",game)
      console.log("gameInfo",gameInfo)

 
  return (
    <div
      style={{
        flex: 3,
        display: "flex",
        // paddingBottom: 100,
        marginLeft: 19
      }}
      className="NE_notionItem_container"
    >


       <div class="NE_business_game">
        {!_.isEmpty(gameInfo) && clickedItem?.id &&
          <>
            <img className="logo_game" src={gameInfo?.game_logo_path ?? imgSVG} alt=""/>
            <h1>{gameInfo?.game_name}</h1>
            <div class="textsize">
              <p>{gameInfo?.game_description}</p>
            </div>

          </>
        }
      </div>


    </div>
  );
};

export default CourseBusinessGame;
