import React,{Component} from "react";
import './index.css'

class Alert extends Component{
    constructor(props) {
        super(props);
    }

    render() {

        const {type,message,onClose, destination} = this.props;

        console.log(destination)

        let class_name = "NE_alert ";

        switch (type){
            case "success":
                class_name += "NE_success_alert";
                break;

            case "danger":
                class_name += "NE_danger_alert";
                break;

            case "warning":
                class_name += "NE_warning_alert";
                break;

            case "flash":
                class_name += "NE_flash_alert";
                break;

            case "dark":
                class_name += "NE_dark_alert";
                break;

            default:
                class_name += "NE_bright_alert";
                break
        }

        return (
            <div className={class_name} >
                <div className="container">
                    <p>{message}</p>
                </div>

                <div className="close_button" onClick={onClose} />
            </div>
            );
    }
}
export default Alert;
