import { all, fork, put, takeLatest, delay, call } from "redux-saga/effects";
import {
  AFFECT_GAMES_TO_CLIENT,
  AFFECT_MODERATORS_TO_CLIENT,
  LOAD_AVAILABLE_GAMES,
  LOAD_AVAILABLE_MODERATORS,
  LOAD_CLIENT_DETAILS,
  REMOVE_MODERATOR_FROM_CLIENT
} from "./action-definitions";
import {
  loadClientDetailsSuccess,
  loadClientDetailsError,
  loadAvailableGamesSuccess,
  loadAvailableGamesError,
  loadAvailableModeratorsSuccess,
  loadAvailableModeratorsError,
  affectGamesToClientSuccess,
  affectGamesToClientError,
  affectModeratorsToClientSuccess,
  affectModeratorsToClientError,
  removeModeratorFromClientSuccess,
  removeModeratorFromClientError,
  pushAlert
} from "./action-creators";
import {
  affectGameToClient,
  affectModeratorsToClient,
  loadAvailableGames,
  loadAvailableModerators,
  loadClientDetails,
  removeModeratorFromClient
} from "../../../services/admin.service";
import { uid } from "uid";
import i18n from "../../../../i18n";
import { closeAlert } from "../clients-list/action-creators";

/* Define the actions watchers */
export function* watchLoadClientDetails() {
  yield takeLatest(LOAD_CLIENT_DETAILS, loadClientDetailsWorker);
}

export function* watchLoadAvailableGames() {
  yield takeLatest(LOAD_AVAILABLE_GAMES, loadAvailableGamesWorker);
}

export function* watchLoadAvailableModerators() {
  yield takeLatest(LOAD_AVAILABLE_MODERATORS, loadAvailableModeratorsWorker);
}

export function* watchAffectGamesToClient() {
  yield takeLatest(AFFECT_GAMES_TO_CLIENT, affectGamesToClientWorker);
}

export function* watchAffectModeratorsToClient() {
  yield takeLatest(AFFECT_MODERATORS_TO_CLIENT, affectModeratorsToClientWorker);
}

export function* watchRemoveModeratorFromClient() {
  yield takeLatest(
    REMOVE_MODERATOR_FROM_CLIENT,
    removeModeratorFromClientWorker
  );
}

/* Define the actions workers */
function* loadClientDetailsWorker({ payload: client_id }) {
  // Attempt to call backend to load client details
  try {
    const call_result = yield loadClientDetails(client_id);

    // Extract moderators and games from call result
    const moderators = [];
    const games = [];

    call_result.data["moderators"].map(moderator => {
      moderators.push({
        id: moderator.id,
        full_name: moderator.full_name,
        phone: "NOT PROVIDED",
        expiration_date: "NOT PROVIDED",
        email: moderator.email
      });
    });

    call_result.data["games_affectations"].map(affectation => {
      games.push({
        id: affectation.game.id,
        name: affectation.game.name,
        image: affectation.game.logo_path,
        description: affectation.game.description,
        affectation_id: affectation.id,
        remaining_sessions_count: affectation.remaining_sessions
      });
    });

    // Dispatch success
    yield put(loadClientDetailsSuccess(moderators, games));
  } catch (error) {
    const alert_id = uid();
    yield put(
      loadClientDetailsError(
        alert_id,
        "danger",
        i18n.t("admin.client-details.messages.load_client_details_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function* loadAvailableGamesWorker() {
  // Attempt to call backend to load available games
  try {
    const call_result = yield loadAvailableGames();

    // Extract games from call result
    const games = [];

    call_result.data.map(game => {
      games.push({
        id: game.id,
        name: game.name,
        image: game.logo_path,
        description: game.description
      });
    });

    // Dispatch success
    yield put(loadAvailableGamesSuccess(games));
  } catch (error) {
    const alert_id = uid();
    yield put(
      loadAvailableGamesError(
        alert_id,
        "danger",
        i18n.t("admin.client-details.messages.load_available_games_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function* loadAvailableModeratorsWorker() {
  // Attempt to call backend to load available moderators
  try {
    const call_result = yield loadAvailableModerators();

    // Extract games from call result
    const moderators = [];

    call_result.data.map(moderator => {
      moderators.push({
        id: moderator.id,
        image: moderator.avatar_path,
        full_name: moderator.full_name,
        email: moderator.email,
        type: moderator.description
      });
    });

    // Dispatch success
    yield put(loadAvailableModeratorsSuccess(moderators));
  } catch (error) {
    const alert_id = uid();
    yield put(
      loadAvailableModeratorsError(
        alert_id,
        "danger",
        i18n.t("admin.client-details.messages.load_available_moderators_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function* affectGamesToClientWorker({ payload }) {
  const { client, games } = payload;

  // Attempt to call backend to affect games to client
  try {
    let affectations_data = {
      client_id: client.id,
      affectations: []
    };

    games.map(game => {
      affectations_data.affectations.push({
        game_id: game.id,
        sessions: game.remaining_sessions_count
      });
    });

    const call_result = yield affectGameToClient(affectations_data);

    // Dispatch success
    yield put(affectGamesToClientSuccess(client.id, games));

    // Dispatch alert
    const alert_id = uid();
    yield put(
      pushAlert(
        alert_id,
        "success",
        i18n.t("admin.client-details.messages.affect_games_to_client_success")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  } catch (error) {
    const alert_id = uid();
    yield put(
      affectGamesToClientError(
        alert_id,
        "danger",
        i18n.t("admin.client-details.messages.affect_games_to_client_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function* affectModeratorsToClientWorker({ payload }) {
  const { client, moderators } = payload;

  // Attempt to call backend to affect moderators to client
  try {
    let affectations_data = {
      client_id: client.id,
      moderators: []
    };

    moderators.map(moderator => {
      affectations_data.moderators.push(moderator.id);
    });

    const call_result = yield affectModeratorsToClient(affectations_data);

    // Dispatch success
    yield put(affectModeratorsToClientSuccess(client.id, moderators));

    // Dispatch alert
    const alert_id = uid();
    yield put(
      pushAlert(
        alert_id,
        "success",
        i18n.t(
          "admin.client-details.messages.affect_moderators_to_client_success"
        )
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  } catch (error) {
    const alert_id = uid();
    yield put(
      affectModeratorsToClientError(
        alert_id,
        "danger",
        i18n.t(
          "admin.client-details.messages.affect_moderators_to_client_error"
        )
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function* removeModeratorFromClientWorker({ payload }) {
  const { client, moderator } = payload;

  // Attempt to call backend to remove moderator from client
  try {
    const call_result = yield removeModeratorFromClient(
      client.id,
      moderator.id
    );

    // Dispatch success
    yield put(removeModeratorFromClientSuccess(client.id, moderator));

    // Dispatch alert
    const alert_id = uid();
    yield put(
      pushAlert(
        alert_id,
        "success",
        i18n.t(
          "admin.client-details.messages.remove_moderator_from_client_success"
        )
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  } catch (error) {
    const alert_id = uid();
    yield put(
      removeModeratorFromClientError(
        alert_id,
        "danger",
        i18n.t(
          "admin.client-details.messages.remove_moderator_from_client_error"
        )
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

// Export the combined sagas
export default function* allSagas() {
  yield all([
    fork(watchLoadClientDetails),
    fork(watchLoadAvailableGames),
    fork(watchLoadAvailableModerators),
    fork(watchAffectGamesToClient),
    fork(watchAffectModeratorsToClient),
    fork(watchRemoveModeratorFromClient)
  ]);
}
