import React, {Component, useEffect} from "react";
import {connect} from "react-redux";
import "./index.css";
import Header from "../../../components/Header";
import CardAdditionAction from "../../../components/CardAdditionAction";
import {loadAllCourses,} from "../../../redux/participant/participant-course-list/action-creators";
import CardActionProgress from "../../../components/CardActionProgress";
import history from "../../../../utils/history";
import CardLatestComments from "../../../components/CardLatestComments";
import Loader from "../../../components/Loader";
import JoinCourseModal from "./components/JoinCourseModal";
import WonModal from "./components/WonModal";
import {getProfilePoints} from "../../../redux/participant/participant-points/action-creators.js";
import empty from "./assets/empty.png"
import { Translation } from "react-i18next";
import mainImage from './assets/header_bg2.png';
import { useSelector } from "react-redux";

class ParticipantList extends Component {
    INITIAL_STATE = {
        show: false,
        showWonModal: false,
        showJoinedCourseSuccessfully: false,
        search_string: "",
        currentPage: 1,
        todosPerPage: 15
    };

    state = {...this.INITIAL_STATE};

    componentDidMount() {
        // Dispatch load clients action
        // this.props.loadAllClients();
        this.props.loadAllCourses();
        this.props.getProfilePoints();

    }


    //Implement Search
    handleHeaderSearchChange = (search_string) => {
        this.setState({
            ...this.state,
            search_string: search_string
        });
    }


    handleClick = (number) => {
        this.setState({
            currentPage: Number(number)
        });
    }
    
    render() {
      
                // const {clients_list} = this.props.admin_clients_list;
                const { courses_list, last_comments, is_loading } = this.props.participant_courses_list;
                const { search_string } = this.state;
                const { current_user } = this.props.login;
        
               console.log("ConfigParticipList", this.props.Config);

    
         
                // Filter client list based on search string
                const filtered_courses_list = courses_list.filter(obj => obj.course?.title?.toLowerCase().includes(search_string.toLowerCase()));

            
                const { currentPage, todosPerPage } = this.state;

                const indexOfLastTodo = currentPage * todosPerPage;
                const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
                const currentTodos = filtered_courses_list.slice(indexOfFirstTodo, indexOfLastTodo);
                let renderTodos;
      
                if (currentTodos.length > 0) {
                    renderTodos = currentTodos.map((cl, key) => {

                        return (
                            
                            <CardActionProgress key={key}
                                trainingSession={cl}
                                onClick={() => {
                                    history.push(
                                        "/participant/course/" + cl?.training_session?.id
                                    );
                                }}
                            />

                        );
                    });
                }
                else {
                    renderTodos = (
                        <Translation>
                            {t =>
                                <>
                                    <div className="no-courses-container">
                                        <img className="no-courses-image" src={empty} alt="No courses available" />
                                        <p className="no-courses-text"> {t("participant.empty_text")}</p>
                                    </div>
                                </>
                            }
                        </Translation>

                
                    );
            
                }
        
       
                // Logic for displaying page numbers
                const pageNumbers = [];
                for (let i = 1; i <= Math.ceil(filtered_courses_list.length / todosPerPage); i++) {
                    pageNumbers.push(i);
                }

                const renderPageNumbers = pageNumbers.map(number => {
                    return (
                        <div
                            key={number}
                            id={number}
                            onClick={() => {
                                this.handleClick(number)
                            }}
                            style={number !== this.state.currentPage ? {
                                border: "1px solid var(--secondary-color)",
                                borderRadius: 5,
                                cursor: "pointer",
                                height: 30,
                                width: 30,
                                margin: 4,
                                display: "flex"

                            } : {
                                border: "1px solid var(--primary-color)",
                                borderRadius: 5,
                                cursor: "pointer",
                                height: 30,
                                width: 30,
                                margin: 4,
                                display: "flex",
                                backgroundColor: "var(--primary-color)",
                                color: "#fff"
                            }}>

                            <span style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                flex: 1,
                            }}>{number}</span>
                        </div>
                    );
                });
  
                return (
            
                    <>
                        <Loader isOpen={is_loading} />
                        {this.state.showWonModal &&
                            <WonModal
                                show={this.state.showWonModal}
                                onClickOK={() => {
                                    this.setState({
                                        showWonModal: false,
                                        showJoinedCourseSuccessfully: true,
                                    });
                                }}
                                close={() => {
                                    this.setState({
                                        showWonModal: false,
                                    });
                                }}
                            />
                        }
                        {this.state.show && (
                            <JoinCourseModal
                                show={this.state.show}
                                openWonModal={() => {
                                    this.setState({
                                        showWonModal: true,
                                        show: false,
                                    });
                                }}
                                close={() => {
                                    this.setState({
                                        show: false,
                                    });
                                }}
                            />
                        )}

                        {this.state.showJoinedCourseSuccessfully && (
                            <JoinedCourseSuccessfully
                                show={this.state.showJoinedCourseSuccessfully}
                                close={() => {
                                    this.setState({
                                        showJoinedCourseSuccessfully: false,
                                    });
                                }}
                            />
                        )}
   
                        <div className="participant_list_page">
                        <Translation>
                           {t => 
                            <div className="page_header" style={{ backgroundImage: `url(${this.props.Config.main_image})` }}>
                                <Header user="participant" searchData={{ 
                                  placeholder: t("header.search"),
                                  search_string: search_string,
                                  onChange: this.handleHeaderSearchChange
                              }} />
                            </div>
                      }
                     </Translation>
                            <div className="page_body">
                                <div className="content">
                                    <div
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        <CardAdditionAction current_user={current_user}
                                            onClick={() => {
                                                this.setState({
                                                    show: true,
                                                });
                                            }}
                                        />
                                    </div>
                            
                                    {renderTodos}
                                </div>
                                <div className="last_comments">
                                    <CardLatestComments comments_list={last_comments} />
                                </div>
                            </div>
                            <div>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flex: 1,
                                    marginTop: "10px",
                                    height: 60,

                                }}>
                                    {renderPageNumbers}
                                </div>
                            </div>
                        </div>
                    </>
                    
                );
         
    }

}

const mapStateToProps = (state) => {
    const { participant_courses_list, login, Config } = state;

    return { participant_courses_list, login, Config };

};



const mapActionsToProps = {
    loadAllCourses,
    getProfilePoints
};


const JoinedCourseSuccessfully = ({show, close = () => null}) => {
    useEffect(() => {
        if (show) {
            setTimeout(() => {
                close();
            }, 3000);
        }
    }, [show]);

    return (
        <div
            style={{
                width: "100%",
                backgroundColor: "#14A38B",
                height: 60,
                position: "absolute",
                top: 60,
                display: "flex",
                flex: 1,
                alignItems: "center",
            }}
        >
            <h3
                style={{
                    color: "#FFFFFF",
                    fontSize: 18,
                    marginLeft: "10rem",
                }}
            >
                You joined the course successfully
            </h3>

            <span
                style={{
                    justifySelf: "flex-end",
                    color: "#fff",
                    right: 20,
                    position: "absolute",
                    cursor: "pointer",
                }}
                onClick={close}
            >
        X
      </span>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(ParticipantList);
