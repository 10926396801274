import React, {Component} from "react";
import './index.css'

class RadioButton2 extends Component{
    render() {
        const {id,name,value,is_disabled = true  ,...rest} = this.props;
        return (
                <div className="NE_radio_button">
                    <input id={id} type="radio" disabled={is_disabled ?? false} {...rest} />
                    <label htmlFor={id}>
                        {value}
                    </label>
                </div>
        );
    }
}
export default RadioButton2;
