import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  REGISTER_GOOGLE,
  REGISTER_GOOGLE_SUCCESS,
  REGISTER_GOOGLE_FAILED,
  REGISTER_FACEBOOK,
  REGISTER_FACEBOOK_SUCCESS,
  REGISTER_FACEBOOK_FAILED,
  LOGOUT_USER,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  REGISTER_CHANGE_PROPS,
  REGISTER_CHANGE_VALIDATION_PROPS,
  COMPLETE_PROFIL_REGISTER,
  COMPLETE_PROFIL_REGISTER_SUCCESS,
  COMPLETE_PROFIL_REGISTER_FAILED,
  CLOSE_ALERT
} from "./action-definitions";

export const changeRegisterValues = (propName, propValue) => ({
  type: REGISTER_CHANGE_PROPS,
  payload: { propName, propValue }
});

export const registerUser = userinfo => ({
  type: REGISTER_USER,
  payload: { userinfo }
});

export const registerUserSuccess = user => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
});

export const registerUserFailed = (id, type, message) => ({
  type: REGISTER_USER_FAILED,
  payload: { id, type, message }
});

export const registerFacebook = code => ({
  type: REGISTER_FACEBOOK,
  payload: code
});

export const registerFacebookSuccess = user => ({
  type: REGISTER_FACEBOOK_SUCCESS,
  payload: user
});

export const registerFacebookFailed = (id, type, message) => ({
  type: REGISTER_FACEBOOK_FAILED,
  payload: { id, type, message }
});

export const registerGoogle = code => ({
  type: REGISTER_GOOGLE,
  payload: code
});

export const registerGoogleSuccess = user => ({
  type: REGISTER_GOOGLE_SUCCESS,
  payload: user
});

export const registerGoogleFailed = (id, type, message) => ({
  type: REGISTER_GOOGLE_FAILED,
  payload: { id, type, message }
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const forgetPassword = username => ({
  type: FORGET_PASSWORD,
  payload: { username }
});

export const forgetPasswordSuccess = passwordResetStatus => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus
});

export const forgetPasswordFailed = error => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error
});

export const changeRegisterValidationValues = (propName, propValue) => ({
  type: REGISTER_CHANGE_VALIDATION_PROPS,
  payload: { propName, propValue }
});

export const completeProfileStep = data => ({
  type: COMPLETE_PROFIL_REGISTER,
  payload: data
});

export const completeProfileStepSuccess = data => ({
  type: COMPLETE_PROFIL_REGISTER_SUCCESS,
  payload: data
});

export const completeProfileStepFailed = error => ({
  type: COMPLETE_PROFIL_REGISTER_FAILED,
  payload: error
});

export const registerCloseAlert = id => ({
  type: CLOSE_ALERT,
  payload: id
});
