import {
    CLONE_COURSE_ELEMENTS,
    CLONE_COURSE_ELEMENTS_FAILED,
    CLONE_COURSE_ELEMENTS_SUCCESS, COURSE_CANNOT_DELETED,
    DELETE_COURSE,
    DELETE_COURSE_FAILED,
    DELETE_COURSE_SUCCESS,
    LOAD_ALL_CATEGORIES,
    LOAD_ALL_CATEGORIES_FAILED,
    LOAD_ALL_CATEGORIES_SUCCESS,
    LOAD_ALL_COMMENTS,
    LOAD_ALL_COMMENTS_FAILED,
    LOAD_ALL_COMMENTS_SUCCESS,
    LOAD_ALL_COURSES,
    LOAD_ALL_COURSES_ERROR,
    LOAD_ALL_COURSES_SUCCESS,
    LOAD_COURSE_BY_ID,
    LOAD_COURSE_BY_ID_FAILED,
    LOAD_COURSE_BY_ID_SUCCESS,
    LOAD_COURSE_ELEMENTS_FAILED,
    LOAD_COURSE_ELEMENTS_SUCCESS
} from "./action-definitions";

// Initial state
const INIT_STATE = {
    loading: false,
    category_list: [],
    courses_list: [],
    comments_list: [],
    course_elements: [],
    courses_cannot_deleted: []
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ALL_COURSES:
            return {...state, errors: []};
        case LOAD_ALL_COURSES_SUCCESS:
            return {...state, courses_list: [...action.payload]};
        case LOAD_ALL_COURSES_ERROR:
            return {...state, errors: action.payload};
        case DELETE_COURSE:
            return {...state, errors: [], loading: true};
        case DELETE_COURSE_SUCCESS:

            return {
                ...state,
                courses_list:
                    state.courses_list?.filter(
                        e => e?.course?.id !== action.payload.id
                    )
                , loading: false

            };
        case DELETE_COURSE_FAILED:
            return {...state, errors: action.payload, loading: false};
        case LOAD_COURSE_BY_ID:
            return {...state, errors: action.payload};
        case LOAD_COURSE_BY_ID_SUCCESS:
            return {...state, errors: action.payload};
        case LOAD_COURSE_BY_ID_FAILED:
            return {...state, errors: action.payload};
        case LOAD_ALL_CATEGORIES:
            return {...state, errors: []};
        case LOAD_ALL_CATEGORIES_SUCCESS:
            return {...state, category_list: [...action.payload]};
        case LOAD_ALL_CATEGORIES_FAILED:
            return {...state, errors: action.payload};
        case LOAD_ALL_COMMENTS:
            return {...state, errors: []};
        case LOAD_ALL_COMMENTS_SUCCESS:
            return {...state, comments_list: [...action.payload]};
        case LOAD_ALL_COMMENTS_FAILED:
            return {...state, errors: action.payload};

        // case LOAD_COURSE_ELEMENTS:
        //     return { ...state, errors: action.payload };
        case LOAD_COURSE_ELEMENTS_SUCCESS:
            return {...state, course_elements: action.payload};
        case LOAD_COURSE_ELEMENTS_FAILED:
            return {...state, errors: action.payload};

        case CLONE_COURSE_ELEMENTS:
            return {...state, errors: []};
        case CLONE_COURSE_ELEMENTS_SUCCESS:
            // courses_list: [...state.courses_list, action.payload]
            return {...state, errors: []};
        case CLONE_COURSE_ELEMENTS_FAILED:
            return {...state, errors: action.payload};
        case COURSE_CANNOT_DELETED:
            return {...state, courses_cannot_deleted: [...state.courses_cannot_deleted, action.payload]};

        default:
            return {...state};
    }
};

export default reducer;
