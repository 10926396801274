const SessionStates = [
  {
    id: 0,
    i18n_key: "statics.session-states.state_all_label",
    code: "ALL"
  },
  {
    id: 1,
    i18n_key: "statics.session-states.state_ongoing_label",
    code: "ONGOING"
  },
  {
    id: 2,
    i18n_key: "statics.session-states.state_to_come_up_label",
    code: "TO_COME_UP"
  },
  {
    id: 3,
    i18n_key: "statics.session-states.state_completed_label",
    code: "COMPLETED"
  }
];

export default SessionStates;
