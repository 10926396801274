import React, {Component} from "react";
import "./index.css"

class NormalButton extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const {onClick, value,...rest} = this.props;

        // return <>
        //     <button className="NE_button NE_button_dark" onClick={onClick} {...rest} >{value}</button>
        // </>
        // return ;

        if (this.props.button == 'dark'){
            return <>
                <button className="NE_button NE_button_dark" onClick={() => {onClick()}} {...rest} >{value}</button>
            </>;
        }
        else if (this.props.button == 'normal'){
            return <>
                <button className="NE_button" onClick={() => {onClick()}} {...rest} >{value}</button>
            </>;
        }
        else {
            return <>
                <button className="NE_button NE_button_outline" onClick={() => {onClick()}}>{value}</button>
            </>;
        }

        // return <>
        //     <button className="NE_button NE_button_dark" onClick={onClick} {...rest} >{value}</button>
        // </>
        // return ;
        //
        // if (this.props.button == 'dark'){
        //     return <>
        //         <button className="NE_button NE_button_dark" onClick={onClick} >{value}</button>
        //     </>;
        // }
        // else if (this.props.button == 'normal'){
        //     return <>
        //         <button className="NE_button" onClick={onClick}>{value}</button>
        //     </>;
        // }
        // else {
        //     return <>
        //         <button className="NE_button NE_button_outline" onClick={() => {onClick()}} {...rest}>{value}</button>
        //     </>;
        // }

    }
}
// Exporting the components
export default NormalButton;