export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_CHANGE_PROPS = "UPDATE_USER_CHANGE_PROPS";
export const CHANGE_CURRENT_CLIENT_INFO_VALIDATION_PROP_VALUE =
  "CHANGE_CURRENT_CLIENT_INFO_VALIDATION_PROP_VALUE";
export const PUSH_ALERT = "UPDATE_USER.PUSH_ALERT";
export const CLOSE_ALERT = "UPDATE_USER.CLOSE_ALERT";
export const CHANGE_CURRENT_USER_INFO_VALUE =
  "UPDATE_USER.CHANGE_CURRENT_USER_INFO_VALUE";
export const REMOVE_CURRENT_CLIENT_INFO_VALIDATION =
  "UPDATE_USER.REMOVE_CURRENT_CLIENT_INFO_VALIDATION";
