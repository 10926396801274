export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const REGISTER_GOOGLE = "REGISTER_GOOGLE";
export const REGISTER_GOOGLE_SUCCESS = "REGISTER_GOOGLE_SUCCESS";
export const REGISTER_GOOGLE_FAILED = "REGISTER_GOOGLE_FAILED";
export const REGISTER_FACEBOOK = "REGISTER_FACEBOOK";
export const REGISTER_FACEBOOK_SUCCESS = "REGISTER_FACEBOOK_SUCCESS";
export const REGISTER_FACEBOOK_FAILED = "REGISTER_FACEBOOK_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";
export const REGISTER_CHANGE_PROPS = "LOGIN.CHANGE_PROPS";
export const REGISTER_CHANGE_VALIDATION_PROPS = "LOGIN.CHANGE_VALIDATION_PROPS";
export const COMPLETE_PROFIL_REGISTER = "REGISTER_COMPLETE_PROFIL_REGISTER";
export const COMPLETE_PROFIL_REGISTER_SUCCESS = "REGISTER_COMPLETE_PROFIL_REGISTER_SUCCESS";
export const COMPLETE_PROFIL_REGISTER_FAILED = "REGISTER_COMPLETE_PROFIL_REGISTER_FAILED";
export const CLOSE_ALERT = "REGISTER.CLOSE_ALERT";
