import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

import './index.css';
import Header from "../../../components/Header";

import {
    address_schema, date_schema,
    email_schema,
    full_name_schema,
    image_size_schema,
    phone_schema,
    type_schema
} from "../../../validation/validations";
import ClientInfoCardWithActions from "../../../components/ClientInfoCardWithActions";
import { changeCurrentClientValue, loadClientDetails, loadAvailableGames, loadAvailableModerators, affectGamesToClient, affectModeratorsToClient, removeModeratorFromClient, closeAlert } from "../../../redux/admin/client-details/action-creators";
import history from "../../../../utils/history";
import ClientModeratorCardWithActions from "../../../components/ClientModeratorCardWithActions";
import BusinessGameCard from "../../../components/BusinessGameCard";
import ChooseBusinessGameModal from "../../../components/ChooseBusinessGameModal";
import ChooseModeratorModal from "./components/ChooseModeratorModal";
import icon_delete from "../clients-list/assets/icon-trash.svg";
import CryptoJS from "crypto-js";

const secret_key = '6@3gmqe^bph=5#47f#nefvr!@txod=(_q=nspcr_ak#b=lqt#m';


function encryptIdClinet(id) {
    const key = '55a51621a6648525';
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    const enc = CryptoJS.AES.encrypt(id, keyutf, { iv: iv });
    const encStr = enc.toString();
    return encStr
}




class ClientDetails extends Component {

    INITIAL_STATE = {
        is_moderator_modal_open: false,
        is_games_modal_open: false,
        current_search_string: "",
        header_search_string: "",
    }

    state = { ...this.INITIAL_STATE }

    componentDidMount() {
        // Get action creators from props
        const { loadClientDetails, changeCurrentClientValue, loadAvailableGames, loadAvailableModerators } = this.props;
        // Get client from location state
        const client = this.props.location.state;

        // Check if we have a client
        if (client && client.id) {
            // Dispatch action to set current client
            changeCurrentClientValue(client);

            // Dispatch action to load client details
            loadClientDetails(client.id);

            // Dispatch actions to load available games and moderators
            loadAvailableGames(); loadAvailableModerators();
        } else {
            // Redirect back with error message
            history.goBack();
        }
    }

    handleGamesModalAffect = (games) => {
        const { current_client } = this.props.admin_client_details;

        // Dispatch action to affect games to client
        this.props.affectGamesToClient(current_client, games);
    }

    handleModalSearch = (search_string) => {
        this.setState({
            ...this.state,
            current_search_string: search_string,
        });
    }

    handleAffectGames = () => {
        this.setState({
            ...this.state,
            is_games_modal_open: true
        });
    }

    handleAffectModerators = () => {
        this.setState({
            ...this.state,
            is_moderator_modal_open: true
        });
    }

    handleModalClose = () => {
        this.setState({
            ...this.INITIAL_STATE
        });
    }

    handleModeratorModalAffect = (moderators) => {
        const { current_client } = this.props.admin_client_details;

        // Dispatch action to affect moderators to client
        this.props.affectModeratorsToClient(current_client, moderators);
    }

    handleDeleteModerator = (moderator) => {
        const { current_client } = this.props.admin_client_details;

        // Dispatch action to remove moderator from client
        this.props.removeModeratorFromClient(current_client, moderator);
    }

    handleHeaderSearchChange = (search_string) => {
        this.setState({
            ...this.state,
            header_search_string: search_string
        });
    }

    handleCloseAlert = (alert_id) => {
        // Dispatch action to close the targeted alert
        this.props.closeAlert(alert_id);
    }

    render() {
        // Getting state variables
        const { current_client, current_client_moderators, current_client_games, available_games, available_moderators, alerts, is_loading } = this.props.admin_client_details;
        const { current_search_string, header_search_string } = this.state;
        const client = this.props.location.state;
        // Filter available games to get only those that are not already affected to the client
        const currentClientGamesIds = current_client_games.map(g => g.id);

        const filtered_available_games = available_games.filter(game => {
            return !currentClientGamesIds.includes(game.id) && game.name.toLowerCase().includes(current_search_string.toLowerCase());
        });

        // Filter available moderators to get only those that are not affected to the client
        const currentClientModeratorsIds = current_client_moderators.map(m => m.id);



        const filtered_available_moderators = available_moderators.filter(moderator => {
            return !currentClientModeratorsIds.includes(moderator.id) && moderator.full_name ? moderator.full_name.toLowerCase().includes(current_search_string.toLowerCase()) : true;
        });

        // Filter current client moderators
        const filtered_current_client_moderators = current_client_moderators.filter(moderator => moderator.full_name ? moderator.full_name.toLowerCase().includes(header_search_string.toLowerCase()) : true);

        // Filter current client business games
        const filtered_current_client_business_games = current_client_games.filter(game => game.name.toLowerCase().includes(header_search_string.toLowerCase()));

        const key_clinet = encryptIdClinet(`${client.id}`);



        return <Translation>
            {t => {
                // Prepare header global search data
                const header_search_data = {
                    placeholder: t("admin.client-details.statics.header.searchbar_placeholder"),
                    search_string: header_search_string,
                    onChange: this.handleHeaderSearchChange
                }

                return (
                    <div className="client_details_page">
                        <div className="page_header">
                            <Header searchData={header_search_data} isLoading={is_loading} alerts={alerts} onCloseAlert={this.handleCloseAlert} />
                        </div>

                        <div className="page_body">
                            <ClientInfoCardWithActions client={current_client} onAffectGames={this.handleAffectGames} onAffectModerator={this.handleAffectModerators} />

                            <div className="page_content">
                                <h1 className="section_title"> Client url :</h1>
                                <p className="url_clinet" >
                                    {`${window.location.origin}/client/${key_clinet}`}
                                </p>

                                <h1 className="section_title">{t("admin.client-details.statics.moderators-section.title")}</h1>

                                <div className="moderators_list">
                                    {filtered_current_client_moderators.map(moderator => {
                                        const moderator_card_options = [
                                            {
                                                name: t("admin.client-details.statics.moderator-card.delete_option"),
                                                icon: icon_delete,
                                                data: moderator,
                                                click_handler: this.handleDeleteModerator,
                                            }
                                        ];

                                        return (
                                            <div className="moderator_item" key={moderator.id}>
                                                <ClientModeratorCardWithActions moderator={moderator} options={moderator_card_options} />
                                            </div>
                                        );
                                    })}

                                </div>

                                <h1 className="section_title">{t("admin.client-details.statics.business-games-section.title")}</h1>
                                {/* <p className="section_description">{t("admin.client-details.statics.business-games-section.description")}</p> */}

                                <div className="business_games_list">
                                    {filtered_current_client_business_games.map(game => {
                                        return (
                                            <div className="business_game_item" key={game.id}>
                                                <BusinessGameCard game={game} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <ChooseBusinessGameModal
                            gamesList={filtered_available_games}
                            onClose={this.handleModalClose}
                            onAffect={this.handleGamesModalAffect}
                            onSearch={this.handleModalSearch}
                            isOpen={this.state.is_games_modal_open}
                            searchString={current_search_string}
                        />

                        <ChooseModeratorModal
                            moderatorsList={filtered_available_moderators}
                            onClose={this.handleModalClose}
                            onAffect={this.handleModeratorModalAffect}
                            onSearch={this.handleModalSearch}
                            isOpen={this.state.is_moderator_modal_open}
                            searchString={current_search_string}
                        />
                    </div>
                );
            }}
        </Translation>
    }
}

const mapStateToProps = state => {
    const { admin_client_details } = state;

    return { admin_client_details };
};

const mapActionsToProps = {
    loadClientDetails,
    changeCurrentClientValue,
    loadAvailableGames,
    loadAvailableModerators,
    affectGamesToClient,
    affectModeratorsToClient,
    removeModeratorFromClient,
    closeAlert
};

export default connect(mapStateToProps, mapActionsToProps)(ClientDetails);
