import React,{Component} from "react";
import './index.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DatePickerInput extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const {label,message,onChange,isValid,id,value,placeholder, defaultValue, ...rest} = this.props;
        let class_name = "NE_normal_input ";
        class_name+= isValid === false ? "NE_normal_input_error" : "";
        return (
                <div className={class_name}>
                    <label htmlFor={id}>{label}</label>
                    <DatePicker
                        // className="red-border"
                        placeholderText={placeholder}
                        selected={value}
                        onChange={(date) => onChange(date)}
                        dateFormat="yyyy-MM-dd"
                        {...rest}

                    />
                    {(isValid !== undefined) && <p>{message}</p>}
                </div>
        );
    }
}
export default DatePickerInput;
