import React, {Component} from "react";
import './index.css';
import icon_trash from './assets/icon-trash.svg';
import icon_edit from './assets/icon-pencil.svg';

class OptionsPopup extends Component{
    render() {
        const {options, onClick, onCancel} = this.props;
        let class_name = "actions_wrapper actions_wrapper_open"

        return (
                <div className="NE_options_popup">
                    <ul>
                        {options && options.map(option => {
                            return (
                                <li key={option.name} onClick={(e) => {
                                    e.stopPropagation();
                                    option.click_handler(option.data);
                                    onClick && onClick();
                                }}>
                                    <img src={option.icon} alt={option.name} />
                                    <p>{option.name}</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
        );
    }
}
export default OptionsPopup;
