import React from "react";
import "./index.css";
import file_ppt from "./assets/file_ppt.png";
import { useTranslation } from 'react-i18next';
// import { useTranslation } from "react-i18next";

const DocumentLine = ({
  type,
  title = "Document Title",
  size = "",
  fileUrl
}) => {
  const {t} = useTranslation();
  return (
    <div class="doc_container">
      <div class="NE_inline_items">
        <img src={file_ppt} alt="" />
        <p class="NE_inline_item_text">{title}</p>
        <p class="NE_inline_item_size">{size}</p>
        <div
          class="border_button"
          onClick={() => {
            window.open(fileUrl);
          }}
        >
          <p>{t("participant.join-course.visualise")}</p>
        </div>
        {/*<div*/}
        {/*  class="normal_button"*/}
        {/*  onClick={() => {*/}
        {/*    window.open(fileUrl);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <p>Download</p>*/}
        {/*</div>*/}
      </div>
      <div class="NE_hr_doc"></div>
    </div>
  );
};

export default DocumentLine;

// import React from "react";

// import "./index.css";

// import file_ppt from "./assets/file_ppt.png";
// const DocumentLine = ({
//   type,
//   title = "Document Title",
//   size = "",
//   fileUrl,
// }) => {
//   return (
//     <div className="NE_notionItem_file_line">
//       <img
//         src={file_ppt}
//         style={{
//           width: 47,
//           height: 56,
//           marginRight: 10,
//           marginBottom: 20,
//         }}
//       />
//       <h5 className="NE_notionItem_file_title">{title}</h5>

//       <h6
//         style={{
//           flex: 1,
//           color: "#989898",
//         }}
//       >
//         {size}
//       </h6>

//       <div
//         style={{
//           display: "flex",
//           flex: 1,
//           flexDirection: "row",
//           justifyContent: "center",
//         }}
//       >
//         <div
//           className="disabled_button_file"
//           style={{ marginRight: 20 }}
//           onClick={() => {
//             window.open(fileUrl);
//           }}
//         >
//           <span>Visualise</span>
//         </div>

//         <div
//           className="active_button_file"
//           onClick={() => {
//             window.open(fileUrl);
//           }}
//         >
//           <span>Download</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DocumentLine;
