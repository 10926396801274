import React, { Component } from "react";
import './index.css'
import history from "../../../utils/history";
import { Translation } from "react-i18next";
import comments from './assets/comments.png'
import defaultAvatar from '../../assets/defaultAvatar.png'
class CardLatestComments extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { comments_list } = this.props
        return (
            <Translation>
                { t =>
                    <>
                        <div className="NE_latest_comments_card">

                            <div className="card_body">
                                <h1>{t("comments.latest_comments")}</h1>
                                <div className="comments_wrapper">
                                   {comments_list && comments_list.length > 0 ? ( 
                                    comments_list?.map((obj, key) => {
                                        return (
                                            <div className="comment" key={key}>
                                                <div className="comment_body">
                                                    <img src={obj?.user?.avatar_path || defaultAvatar} alt="Profile Avatar" width="34" height="34" />
                                                    <div className="comment_infos">
                                                        <div className="comment_user">
                                                            <h2>
                                                                
                                                                {obj.user.full_name ? obj.user.full_name.toLowerCase().split(" ")
                                                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                .join(" "): ""}
                                                            </h2>

                                                            <p>{t("comments.add_comment")} <span
                                                                className="course_name"
                                                                onClick={() => {
                                                                    history.push("/participant/course/" + obj.training_session_id + "?disccussion=" + obj.discussion_id)
                                                                }}
                                                            >{obj.course_title}</span>
                                                            </p>
                                                        </div>

                                                        <div className="comment_text">
                                                            {obj.content}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                      ) : (
                                          <div className="no_comments">
                                                <img className="no_comments_img"src={comments} alt="No Comments Image" />
                                                <p>{t("card.no_comments") }</p>
                                          </div>
                                         )}

                                </div>
                            </div>
                        </div>
                    </>
                }
            </Translation>
        )
    }

}
export default CardLatestComments;
