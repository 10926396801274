import React,{Component} from "react";
import './index.css';
import add from './assets/add.svg';
import cloud from './assets/icon-upload-cloud.svg';

class ImagesUploader extends Component{
    constructor(props) {
        super(props);
        this.state = {
            image: '',
            raw: ''
        }
    }

    dragOver = (e) => {
        e.preventDefault();
    }

    dragEnter = (e) => {
        e.preventDefault();
    }

    dragLeave = (e) => {
        e.preventDefault();
    }

    fileDrop = (e) => {
        e.preventDefault();
        this.setState({
            image: URL.createObjectURL(e.dataTransfer.files[0])
        })
        this.props.onImageChange(e.dataTransfer.files[0])
    }


    handleChange = (e) => {
        this.setState({
            image: URL.createObjectURL(e.target.files[0])
        })
        this.props.onImageChange(e.target.files[0])
    }

    render() {

        // Try to get preview image
        // const preview = fields.image ? URL.createObjectURL(fields.image) : null;
        // console.log('child state ' + JSON.stringify(this.state))
        const {image} = this.state

        return (
            <div className="NE_images_uploader"
                 onDragOver={this.dragOver}
                 onDragEnter={this.dragEnter}
                 onDragLeave={this.dragLeave}
                 onDrop={this.fileDrop}
            >
                <div className="drop_zone">
                    <label className="drop_zone_label" htmlFor="upload-button-complete">
                        {/*<img src={!image.preview ? add : image.preview} alt="Add image icon"/>*/}
                        <img src={image ? image : add} alt="Add image icon"/>
                        <h1>Upload Image</h1>
                        <p>Drag &amp; drop or browse your file here</p>
                        <input id="upload-button-complete" type="file"
                               onChange={(e) => this.handleChange(e)}
                               accept=".png,.jpeg,.jpg" />
                    </label>
                </div>
                {this.props.client !== 'moderator' ?
                <div className="upload_vault">
                    <div className="upload_item">
                        <div className="upload_item_icon">
                            <img src={cloud} alt="Upload icon"/>
                        </div>
                        <div className="upload_item_content">
                            <div className="upload_item_header">
                                <h1 className="upload_item_name">Myface.jpeg</h1>
                                <button className="upload_item_cancel_button">Cancel</button>
                            </div>
                            <div className="upload_item_progress">
                                <div className="progress_bar">
                                    <div className="progress_level" style={{width: '80%'}}/>
                                </div>
                                <div className="progress_infos">
                                    <p className="progress_title">Uploading…</p>
                                    <p className="progress_percentage">80%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''}
            </div>
        );
    }
}
export default ImagesUploader;
