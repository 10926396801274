import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";

// Create the Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Holds the list of used middlewares
const middlewares = [sagaMiddleware];

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    // other store enhancers if any
);

// Create and export the redux store
export const store = createStore(
    rootReducer,
    enhancer
);

// Configure the SagaMiddleware to run the sagas
sagaMiddleware.run(rootSaga);

// Create and export the store persistor
export const persistor = persistStore(store);
