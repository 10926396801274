// import { httpClient_post, httpClient_get } from "../../services/api";
import { getSecureAxiosInstance } from "../../services/axios-instance";

function getParticipantsService(trainingSessionId) {
  return getSecureAxiosInstance().get(
    `/moderator/getparticipants?training_session_id=${trainingSessionId}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getQuizStatisticsService(trainingSessionId) {
  // return quizStatistics;
  return getSecureAxiosInstance().get(
    `/moderator/getquizstatistics?training_session_id=${trainingSessionId}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function changeStatusParticipantService({ trainingSessionId, userId, status }) {
  return getSecureAxiosInstance().post(
    `/moderator/activateparticipant?training_session_id=${trainingSessionId}&userId=${userId}&status=${status}`,
    null,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getTeamsService(trainingSessionID) {
  return getSecureAxiosInstance().get(
    `moderator/stratedge/getteams?training_session_id=${trainingSessionID}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function assignmentTeamsService(list) {
  return getSecureAxiosInstance().post(`moderator/stratedge/updateteamaffectations`, list)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export {
  getParticipantsService,
  changeStatusParticipantService,
  getQuizStatisticsService,
  getTeamsService,
  assignmentTeamsService,
};
