import {
  LOAD_ALL_CLIENTS,
  LOAD_ALL_CLIENTS_SUCCESS,
  LOAD_ALL_CLIENTS_ERROR,
  ADD_CLIENT,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_ERROR,
  CHANGE_CURRENT_CLIENT_PROP_VALUE,
  CHANGE_CURRENT_CLIENT_VALUE,
  CHANGE_CURRENT_CLIENT_VALIDATION_PROP_VALUE,
  CLEAR_CURRENT_CLIENT,
  EDIT_CLIENT,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_ERROR,
  DISPLAY_CLIENT_DETAILS,
  PUSH_ALERT,
  CLOSE_ALERT
} from "./action-definitions";

export const loadAllClients = adminData => ({
  type: LOAD_ALL_CLIENTS,
  payload: { adminData }
});

export const loadAllClientsSuccess = clients => ({
  type: LOAD_ALL_CLIENTS_SUCCESS,
  payload: clients
});

export const loadAllClientsError = (id, type, message) => ({
  type: LOAD_ALL_CLIENTS_ERROR,
  payload: { id, type, message }
});

export const addClient = clientData => ({
  type: ADD_CLIENT,
  payload: clientData
});

export const addClientSuccess = client => ({
  type: ADD_CLIENT_SUCCESS,
  payload: client
});

export const addClientError = (id, type, message) => ({
  type: ADD_CLIENT_ERROR,
  payload: { id, type, message }
});

export const changeCurrentClientPropValue = (propName, propValue) => ({
  type: CHANGE_CURRENT_CLIENT_PROP_VALUE,
  payload: { propName, propValue }
});

export const changeCurrentClientValue = value => ({
  type: CHANGE_CURRENT_CLIENT_VALUE,
  payload: value
});

export const changeCurrentClientValidationPropValue = (
  propName,
  propValue
) => ({
  type: CHANGE_CURRENT_CLIENT_VALIDATION_PROP_VALUE,
  payload: { propName, propValue }
});

export const clearCurrentClient = () => ({
  type: CLEAR_CURRENT_CLIENT,
  payload: null
});

export const editClient = clientData => ({
  type: EDIT_CLIENT,
  payload: clientData
});

export const editClientSuccess = client => ({
  type: EDIT_CLIENT_SUCCESS,
  payload: client
});

export const editClientError = (id, type, message) => ({
  type: EDIT_CLIENT_ERROR,
  payload: { id, type, message }
});

export const pushAlert = (id, type, message) => ({
  type: PUSH_ALERT,
  payload: { id, type, message }
});

export const closeAlert = id => ({
  type: CLOSE_ALERT,
  payload: id
});
