export const LOAD_TRAINING_SESSION = "PARTICIPANT.LOAD_TRAINING_SESSION";
export const LOAD_TRAINING_SESSION_SUCCESS =
  "PARTICIPANT.LOAD_TRAINING_SESSION_SUCCESS";
export const LOAD_TRAINING_SESSION_ERROR =
  "PARTICIPANT.LOAD_TRAINING_SESSION_ERROR";
export const LOAD_COURSE_SIDEBAR_DATA = "PARTICIPANT.LOAD_COURSE_SIDEBAR_DATA";
export const DISPATCH_CLICKED_ITEM = "PARTICIPANT.DISPATCH_CLICKED_ITEM";
export const GET_DISCUSSION_TRAINING_SESSION =
  "PARTICIPANT.GET_DISCUSSION_TRAINING_SESSION";
export const GET_DISCUSSION_TRAINING_SESSION_SUCCESS =
  "PARTICIPANT.GET_DISCUSSION_TRAINING_SESSION_SUCCESS";
export const GET_DISCUSSION_TRAINING_SESSION_ERROR =
  "PARTICIPANT.GET_DISCUSSION_TRAINING_SESSION_ERROR";
export const GET_MESSAGES = "PARTICIPANT.GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "PARTICIPANT.GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_ERROR = "PARTICIPANT.GET_MESSAGES_ERROR";
export const ADD_MESSAGE = "PARTICIPANT.ADD_MESSAGE";
export const ADD_MESSAGE_SUCCESS = "PARTICIPANT.ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_ERROR = "PARTICIPANT.ADD_MESSAGE_ERROR";
export const GET_PROGRESS = "PARTICIPANT.GET_PROGRESS";
export const GET_PROGRESS_SUCCESS = "PARTICIPANT.GET_PROGRESS_SUCCESS";
export const GET_PROGRESS_ERROR = "PARTICIPANT.GET_PROGRESS_ERROR";
export const UPDATE_PROGRESS = "PARTICIPANT.UPDATE_PROGRESS";
export const UPDATE_PROGRESS_SUCCESS = "PARTICIPANT.UPDATE_PROGRESS_SUCCESS";
export const UPDATE_PROGRESS_ERROR = "PARTICIPANT.UPDATE_PROGRESS_ERROR";
export const PUSH_QUIZ_QUESTION_RESULT =
  "PARTICIPANT.PUSH_QUIZ_QUESTION_RESULT";
export const CLEAR_QUIZ_QUESTION_RESULT =
  "PARTICIPANT.CLEAR_QUIZ_QUESTION_RESULT";
export const SAVE_QUIZ = "PARTICIPANT.SAVE_QUIZ";
export const SAVE_QUIZ_SUCCESS = "PARTICIPANT.SAVE_QUIZ_SUCCESS";
export const SAVE_QUIZ_ERROR = "PARTICIPANT.SAVE_QUIZ_ERROR";
export const GET_QUIZ = "PARTICIPANT.GET_QUIZ";
export const GET_QUIZ_SUCCESS = "PARTICIPANT.GET_QUIZ_SUCCESS";
export const GET_QUIZ_ERROR = "PARTICIPANT.GET_QUIZ_ERROR";

export const LOAD_COURSE = "PARTICIPANT.LOAD_COURSE";
export const LOAD_COURSE_SUCCESS = "PARTICIPANT.LOAD_COURSE_SUCCESS";
export const LOAD_COURSE_ERROR = "PARTICIPANT.LOAD_COURSE_ERROR";
