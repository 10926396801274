import {
  CONFIG_GET_COLORS,
  CONFIG_GET_COLORS_FAILED,
  CONFIG_GET_COLORS_SUCCESS
} from "./action-definitions";

export const getConfigColors = (client_id) => ({
  type: CONFIG_GET_COLORS,
  payload: { client_id }
});

export const getConfigColorsSuccess = config => ({
  type: CONFIG_GET_COLORS_SUCCESS,
  payload: config
});

export const getConfigColorsFailed = (message) => ({
  type: CONFIG_GET_COLORS_FAILED,
  payload: { message }
});