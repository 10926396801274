import React,{Component} from "react";
import './index.css';

import icon_user from './assets/icon-user-tie.svg';
import icon_mail from './assets/icon-mail.svg';
import OptionsPopup from "../OptionsPopup";

class ClientModeratorCardWithActions extends Component{

    constructor(props) {
        super(props);
        this.options = React.createRef();
    }

    state = {
        is_options_popup_open: false
    };

    toggleOptionsPopup = () => {
        this.setState({
            is_options_popup_open: !this.state.is_options_popup_open
        });
    };

    componentDidMount() {
        window.addEventListener('click', this.backDropHandler);
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.backDropHandler);

    }

    backDropHandler =(e)=>{
        if (!this.options.current?.contains(e.target)) {
            this.setState({
                is_options_popup_open:false
            })
        }
    }

    render() {
        const {moderator, options} = this.props;

        const {is_options_popup_open} = this.state;

        return <>
            <div className="NE_clientModeratorCardWithAction">
                <div className="item">
                    <img src={icon_user} alt="Moderator name" />
                    <p> {moderator.full_name? moderator.full_name.toLowerCase().split(" ")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
                    : ""}
                    </p>

                </div>

                <div className="item">
                    <img src={icon_mail} alt="Moderator mail" />
                    <p>{moderator.email}</p>
                </div>

                <span className="item_menu" onClick={(e) => {e.stopPropagation();this.toggleOptionsPopup();}} />

                <div ref={this.options} className={`actions_wrapper ${is_options_popup_open ? "actions_wrapper_open" : ""}`}>
                    <OptionsPopup options={options} onClick={this.toggleOptionsPopup} />
                </div>
            </div>
        </>
    }
}
export default ClientModeratorCardWithActions;
