import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import "./index.css";
import Header from "../../../components/Header";
import Loader from "../../../components/Loader";
import ProfilePoints from "./components/ProfilePoints";
import { useDispatch } from "react-redux";
import { getProfilePoints } from "../../../redux/participant/participant-points/action-creators";


const ParticipantPoints = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getProfilePoints());
    }, [])
    const {is_loading} = useSelector((state) => state.participant_points)
    return (
        <div className="participant_points">
            <Loader isOpen={is_loading}/>

            <div className="page_header">
                <Header user="participant"/>
            </div>

            <div className="page_body" style={{flexDirection: "column"}}>
                <ProfilePoints />
            </div>
        </div>
    );

}
export default ParticipantPoints;
