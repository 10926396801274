import React, { Component, useEffect, useState } from "react";
import { Translation } from "react-i18next";
import './index.css';
import file_document from "./assets/file-document.svg";
import file_document_active from "./assets/file-document_blue.svg";


const ElementsList = (props) => {
    const [active_element, setActiveElement] = useState(props.active_element);

    useEffect(() => {
        setActiveElement(props.active_element);
    }, [props.active_element]);

    const handleActiveElementChange = (element) => {
        setActiveElement(element)
        props.onActiveElementChange(element);
    }

    const { elements } = props;

    return <Translation>
        {t => {
            return (
                <div className="NE_elements_list">
                    <div className="list_header">
                        <p>{elements?.length} {t("moderator.training-sessions.statics.session-dashboards.dashboard-global.elements_list_title")}</p>
                    </div>


                    <div className="list_items">
                        {   console.log("elements",elements)}
                            {elements?.map(element => {
                            const is_active = active_element?.id === element.id;
                            let class_name = "list_item ";
                            class_name += is_active ? "list_item_active" : "";
                            return (
                                <div key={element.id} className={class_name} onClick={() => { handleActiveElementChange(element) }}>
                                    <img src={is_active ? file_document_active : file_document} alt="Element" />
                                    <p >{element.title}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }}
    </Translation>

}
export default ElementsList;
