export const LOAD_ALL_COURSES = "PARTICIPANT.LOAD_ALL_COURSES";
export const LOAD_ALL_COURSES_SUCCESS = "PARTICIPANT.LOAD_ALL_COURSES_SUCCESS";
export const LOAD_LAST_COMMENT_SUCCESS =
  "PARTICIPANT.LOAD_LAST_COMMENT_SUCCESS";
export const LOAD_ALL_COURSES_ERROR = "PARTICIPANT.LOAD_ALL_COURSES_ERROR";

export const SUBSCRIBE_TRAINING_SESSION =
  "PARTICIPANT.SUBSCRIBE_TRAINING_SESSION";
export const SUBSCRIBE_TRAINING_SESSION_SUCCESS =
  "PARTICIPANT.SUBSCRIBE_TRAINING_SESSION_SUCCESS";
export const SUBSCRIBE_TRAINING_SESSION_ERROR =
  "PARTICIPANT.SUBSCRIBE_TRAINING_SESSION_ERROR";
