import React, { Component } from "react";
import "./index.css";
import avatar from "./assets/avatar.png";
import { useSelector } from "react-redux";
import { Translation } from "react-i18next";
import defaultAvatar from '../../assets/defaultAvatar.png'


const ProfilePopupCard = ({ user, options }) => {

  const { profilePoints } = useSelector((state) => state.participant_points);

  const score = profilePoints?.user_scores?.total_score;

  return (
    <Translation>
      { t =>
        <>

          <div className="NE_profile_popup_card">
            <div className="profile_infos">
              <div className="pic_and_name">
                <img
                  src={user?.avatar_path || defaultAvatar}
                  alt={user?.full_name}
                />
                
                <h1>
  {user?.full_name
    ? user.full_name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : ""}
</h1>

              </div>
              {user?.role.name.toUpperCase() === "PARTICIPANT" && (
                <div className="profile_progress">
                  <div className="progress_bar">
                    <div className="progress_level" style={{ width: `${score * 0.33}%` }}>
                      <div className="tooltip_container">
                        {
                          score < 100 &&
                          <div className="tooltip">{t("participant.user-metrics.level1")}</div>
                        }
                        {
                          score >= 100 &&
                          <div className="tooltip">{t("participant.user-metrics.level2")}</div>
                        }
                        {
                          score >= 200 &&
                          <div className="tooltip">{t("participant.user-metrics.level3")}</div>
                        }
                        {
                          score >= 300 &&
                          <div className="tooltip">{t("participant.user-metrics.level4")}</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="profile_actions">
              <ul>
                {options?.map((option, index) => {
                  return (
                    <li key={index} onClick={option.click_handler} >
                      {option.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </>
      }
    </Translation>
  );
};
export default ProfilePopupCard;
