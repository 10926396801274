import { all, fork, put, takeLatest } from "redux-saga/effects";
import { GET_PROFILE_POINT } from "./action-definitions";
import {
  getProfilePointsError,
  getProfilePointsSuccess
} from "./action-creators";
import { loadProfilePoints } from "../../../services/participant.service";

export function* watchLoadProfilePointsWorker() {
  yield takeLatest(GET_PROFILE_POINT, getProfilePointsWorker);
}

/* Define the actions workers */
function* getProfilePointsWorker({ payload }) {
  // Get admin data from payload

  // Attempt to call backend to load clients list
  try {
    const call_result = yield loadProfilePoints();

    yield put(getProfilePointsSuccess(call_result.data));
  } catch (error) {
    yield put(getProfilePointsError(["Cannot load progress."]));
  }
}

// Export the combined sagas
export default function* allSagas() {
  yield all([fork(watchLoadProfilePointsWorker)]);
}
