import { all, fork, put, takeLatest, delay } from "redux-saga/effects";
import {
  ADD_CLIENT,
  EDIT_CLIENT,
  LOAD_ALL_CLIENTS, PUSH_ALERT
} from "./action-definitions";
import {
  loadAllClientsSuccess,
  loadAllClientsError,
  addClientSuccess,
  editClientSuccess,
  pushAlert,
  addClientError,
  editClientError,
  closeAlert
} from "./action-creators";
import {
  loadAllClients,
  addClient,
  editClient
} from "../../../services/admin.service";
import { uploadClientImage } from "../../../services/uploads.service";
import { uid } from "uid";
import i18n from "../../../../i18n";

/* Define the actions watchers */
export function* watchLoadAllClients() {
  yield takeLatest(LOAD_ALL_CLIENTS, loadAllClientsWorker);
}

export function* watchAddClient() {
  yield takeLatest(ADD_CLIENT, addClientWorker);
}

export function* watchEditClient() {
  yield takeLatest(EDIT_CLIENT, editClientWorker);
}

export function* watchPushAlert() {
  yield takeLatest(PUSH_ALERT, pushAlertWorker);
}

/* Define the actions workers */
function* pushAlertWorker({ payload }) {
  // Attempt to call backend to load clients list
  try {

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(payload.id));

  } catch (error) {



  }
}
function* loadAllClientsWorker({ payload }) {
  // Attempt to call backend to load clients list
  try {
    const call_result = yield loadAllClients();

    const all_clients = [];

    call_result.data.map(cl => {
      all_clients.push({
        id: cl.id,
        image: cl.logo_path,
        full_name: cl.name,
        type: cl.description,
        phone: cl.phone,
        email: cl.email,
        address: cl.address,
        expiry_date: cl.expiry_date
      });
    });

    // Dispatch success
    yield put(loadAllClientsSuccess(all_clients));
  } catch (error) {
    const alert_id = uid();
    yield put(
      loadAllClientsError(
        alert_id,
        "danger",
        i18n.t("admin.clients-list.messages.load_all_clients_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function* addClientWorker({ payload: clientData }) {
  // Attempt to call backend to create new client
  try {
    // Upload client image
    const image_upload_result = yield uploadClientImage(
      clientData.full_name,
      clientData.image
    );

    const call_result = yield addClient(
      image_upload_result.data.message,
      clientData.full_name,
      clientData.type,
      clientData.phone,
      clientData.email,
      clientData.address,
      clientData.expiry_date
    );

    const new_client = {
      id: call_result.data.id,
      image: call_result.data.logo_path,
      full_name: call_result.data.name,
      type: call_result.data.description,
      phone: call_result.data.phone,
      email: call_result.data.email,
      address: call_result.data.address,
      expiry_date: call_result.data.expiry_date
    };

    // Dispatch success
    yield put(addClientSuccess(new_client));

    // Dispatch alert
    const alert_id = uid();
    yield put(
      pushAlert(
        alert_id,
        "success",
        i18n.t("admin.clients-list.messages.add_client_success")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  } catch (error) {
    const alert_id = uid();
    yield put(
      addClientError(
        alert_id,
        "danger",
        i18n.t("admin.clients-list.messages.add_client_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function* editClientWorker({ payload: clientData }) {
  // Attempt to call backend to edit the client
  try {
    let image_upload_result = null;

    // Upload client image
    if (clientData.image instanceof File) {
      image_upload_result = yield uploadClientImage(
        clientData.full_name,
        clientData.image
      );
    }

    const call_result = yield editClient(
      clientData.id,
      image_upload_result ? image_upload_result.data.message : clientData.image,
      clientData.full_name,
      clientData.type,
      clientData.phone,
      clientData.email,
      clientData.address,
      clientData.expiry_date
    );



    const modified_client = {
      id: call_result.data.id,
      image: call_result.data.logo_path,
      full_name: call_result.data.name,
      type: call_result.data.description,
      phone: call_result.data.phone,
      email: call_result.data.email,
      address: call_result.data.address,
      expiry_date: call_result.data.expiry_date
    };

    // Dispatch success
    yield put(editClientSuccess(modified_client));

    // Dispatch alert
    const alert_id = uid();
    yield put(
      pushAlert(
        alert_id,
        "success",
        i18n.t("admin.clients-list.messages.edit_client_success")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  } catch (error) {
    const alert_id = uid();
    yield put(
      editClientError(
        alert_id,
        "danger",
        i18n.t("admin.clients-list.messages.edit_client_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

// Export the combined sagas
export default function* allSagas() {
  yield all([
    fork(watchLoadAllClients),
    fork(watchAddClient),
    fork(watchEditClient),
    fork(watchPushAlert)
  ]);
}
