import React, { Component } from "react";
import './index.css'
import * as moment from "moment";
import lens from '../../assets/Icon-Lens.svg';
import list from '../../assets/list.svg';
import awards from '../../assets/awards.svg';
import CollapsableCardWithCheckboxes from "../../../../../components/CollapsableCardWithCheckboxes";

import { connect } from "react-redux";
import history from "../../../../../../utils/history";
import {
    changeCurrentElementPropValue,
    loadAllGames,
    pushCurrentElement,
    editTypElement,
    changeCurrentCourseElementPropValue
} from "../../../../../redux/moderator/configure-course/actions-creators"
import { getStratedgeConfigurations } from "../../../../../services/moderator.service";
import Dropdown2 from "../../../../../components/Dropdown2";
// import styles from "./style.module.css"
import no_games from "../../assets/no_games.png"
import { Translation } from "react-i18next";

class BusinessGameModal extends Component {

    state = {
        search_string: "",
        selectElement: false,
        course_id: "",
        activ_id: "",
        game_id: "",
        name_game: "",
        cloneElements: {
            course_id: null,
            modules: [],
            documents_ids: [],
            games_ids: [],
            evaluations_ids: [],
        },
        stratedge_configurations: [],
        stratedge_configuration_name: ""
    };

    componentDidMount() {
        getStratedgeConfigurations().then((res) => {
            this.setState({
                stratedge_configurations: res.data
            })
        })
    }

    onSelectElement = (val) => {


        this.setState({
            stratedge_configuration_name: this.state.stratedge_configurations[0],
            activ_id: val.id,
            name_game: val.game.name,
            game_id: val.game.id
        })
    }

    render() {
        const { isOpen, onCancel, games_list = [], current_element, toggleBarButtonItem } = this.props;
        console.log("games_list", games_list);
        let class_name = "configure_course_modal_wrapper ";
        class_name += isOpen ? "configure_course_modal_open" : "";

       const filteredData = games_list.filter(elem => elem.game.name?.toLowerCase().includes(this.state.search_string));

        return (
            <Translation>
                                        {t =>
         
            <div className={`${class_name}`} style={{ zIndex: "999" }} onClick={(e) => {
                if (e.target.className === class_name) {
                    onCancel();
                }
            }}>

                <div className="NE_body">
                    <div className="NE_text">
                        <h3 className="NE_text_body">Serious Game</h3>
                        <h3 className="NE_text_body _close_button" onClick={() => {
                            onCancel();
                        }} >x</h3>
                    </div>
                    <div className="NE_body_center" />
                    <div className="NE_search_bar">
                        <div className="NE_icon_input NE_icon_input_success NE_icon_input_error">
                            <div className="input_wrapper">
                                <input
                                    type="text"
                                    id="test"
                                    placeholder={t("header.search")}
                                    value={this.state.search_string}
                                    onChange={(e) => {
                                        this.setState({
                                            search_string: e.target.value
                                        });
                                    }} />
                                <img src={lens} alt="Input icon" />
                            </div>
                        </div>
                    </div>
                    {this.state.selectElement ?
                        <>
                            <div className="collaps-content">

                            </div>

                        </>
                        :
                        <>
            
            <div className="NE_body_content">
               
                                {filteredData.length === 0 ? (
                                    <Translation>
                                        {t =>
                                            <>
                                                <div className="no-games-container">
                                                    <img className="no-games" src={no_games} alt="No games available" />
                                                    <p className="no-games-text">{t("moderator.no_games")}</p>
                                                </div>
                                            </>
                                        }
                                        </Translation>                            
                ) : (
                filteredData.map((val) => (
                     <div
                       className={`NE_business_game_card ${val.id === this.state.activ_id ? "active" : ""}`}
                       onClick={() => this.onSelectElement(val)}
                    >
                    {val.id === this.state.activ_id && val.game.id === 1 ? (
                        <div>
                            <h2 style={{ marginBottom: "10px" }}>select Config :</h2>
                            <select
                                className="select_config"
                                onChange={(e) => {
                                    this.setState({
                                        stratedge_configuration_name: e.target.value,
                                    });
                                }}
                            >
                                {this.state.stratedge_configurations?.map((elm, index) => (
                                    <option value={elm} key={index}>
                                        {elm}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        <>
                            <div className="card_header">
                                <div className="card_image">
                                            <img src={val.game.logo_path} alt="" />
                                        
                                </div>
                            </div>
                            <div className="card_body">
                                <h1>{val.game.name}</h1>
                                <p>{val.game.description}</p>
                            </div>
                         </>
                    )}
                    </div>
                 ))
                 )}
            </div>
                            <div className="form-actions_mt">
                                <div className="form-action ">
                                    <button
                                        onClick={() => {
                                            this.setState({
                                                selectElement: false,
                                                search_string: ""
                                            });
                                            onCancel();
                                        }}
                                        width="180px" height="56px" className="NE_button_large NE_button_outline" type="reset">{t("moderator.edit_course.cancel_button")}</button>
                                </div>
                                <div className="form-action mr-0 ">
                                    <button className="NE_button_large NE_button_dark"
                                        onClick={() => {
                                            if (filteredData.length > 0) {
                                                const elm = { ...current_element, type: toggleBarButtonItem.value, icon: toggleBarButtonItem.icon, stratedge_configuration_name: this.state.stratedge_configuration_name };

                                                this.props.editTypElement(elm, this.state.activ_id, this.state.game_id);

                                                this.props.changeCurrentCourseElementPropValue(elm, "business_game", {
                                                    game_id: this.state.activ_id,
                                                    name_game: this.state.name_game
                                                });
                                                onCancel();
                                            }
                                        }}
                                        disabled={filteredData.length === 0}
                                            >{t("moderator.edit_course.import_button")}</button>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
                    }
</Translation>
        );

    }
}


const mapStateToProps = state => {
    const { moderator_courses_list: { course_elements }, moderator_configure_course: { current_course, games_list } } = state;
    return { course_elements, current_course, games_list };
};


const mapActionsToProps = {
    loadAllGames,
    changeCurrentElementPropValue,
    pushCurrentElement,
    changeCurrentCourseElementPropValue
};

export default connect(mapStateToProps, mapActionsToProps)(BusinessGameModal);
