import React,{Component} from "react";
import './index.css';

class Checkbox extends Component{
    render() {
        const {id,label,isPartiallyChecked,onClick, onChange,checked, ...rest} = this.props;

        let class_name = "NE_checkbox ";
        class_name += isPartiallyChecked ? "NE_checkbox_partially_checked" : "";

        return (
            <>
                <div className={class_name}>
                    <input type="checkbox" id={id} {...rest} checked={checked} onChange={onChange}/>
                    {label !== null && <label htmlFor={id}>
                        {label}
                    </label>}
                </div>

                {/*<div className="NE_checkbox">*/}
                {/*    <input type="checkbox" name="r2" id="r2" disabled checked/>*/}
                {/*    <label htmlFor="r2">*/}
                {/*        Choice 2*/}
                {/*    </label>*/}
                {/*</div>*/}

                {/*<div className="NE_checkbox NE_checkbox_partially_checked">*/}
                {/*    <input type="checkbox" name="r3" id="r3"/>*/}
                {/*    <label htmlFor="r3">*/}
                {/*        Choice 3*/}
                {/*    </label>*/}
                {/*</div>*/}

                {/*<div className="NE_checkbox NE_checkbox_partially_checked">*/}
                {/*    <input type="checkbox" name="r4" id="r4" disabled checked/>*/}
                {/*    <label htmlFor="r4">*/}
                {/*        Choice 4*/}
                {/*    </label>*/}
                {/*</div>*/}
            </>
        );
    }
}
export default Checkbox;