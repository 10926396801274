import { getSecureAxiosInstance, axiosInstance } from "./axios-instance";

export const uploadClientImage = (clientName, image) => {
  const request_data = new FormData();
  request_data.append("target", "clients");
  request_data.append("sub_dir", clientName);
  request_data.append("image", image);

  return axiosInstance.post(
    "https://www.nowedge.io/lms-php-api/staging/upload_image.php",
    request_data
  );
};

export const uploadCourseImage = (CourseName, image) => {
  const request_data = new FormData();
  request_data.append("target", "courses");
  request_data.append("sub_dir", CourseName);
  request_data.append("image", image);

  return axiosInstance.post(
    "https://www.nowedge.io/lms-php-api/staging/upload_image.php",
    request_data
  );
};

export const uploadDocument = document => {
  const request_data = new FormData();
  request_data.append("doc", document);

  return axiosInstance.post(
    "https://www.nowedge.io/lms-php-api/staging/upload_document.php",
    request_data
  );
};
