import React,{Component} from "react";
import './index.css';
import add from './assets/add.svg';

class ToggleBarButton extends Component{

    state = {
        is_open: false,
    }

    handleToggleClick = () => {
        this.setState({
            ...this.state,
            is_open: !this.state.is_open
        });
    }

    render() {
        const {items,onClick , element} = this.props
        const {is_open} = this.state

        let class_name = "NE_toggle_bar_button ";
        class_name += is_open ? "NE_toggle_bar_button_open" : "";
        return (
            <div className={class_name}>
                <div className="toggle_button" onClick={this.handleToggleClick}>
                    <img src={add} alt="Toggle button" />
                </div>

                <div className="actions_bar">
                    {items?.map((item,index) => {
                        return (
                            <div key={index} className="action" onClick={() => {onClick(item,element);this.handleToggleClick()}}>
                                <img src={item.icon} alt={item.name} />
                                <p>{item.name}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
export default ToggleBarButton;
