import { Cookies } from "react-cookie";
import { all, call, delay, fork, put, takeLatest } from "redux-saga/effects";
import {
  COMPLETE_PROFIL_REGISTER,
  REGISTER_USER,
  REGISTER_GOOGLE,
  REGISTER_FACEBOOK
} from "./action-definitions";
import {
  registerUser,
  registerUserSuccess,
  registerUserFailed,
  completeProfileStep,
  completeProfileStepSuccess,
  completeProfileStepFailed
} from "./action-creators";
import {
  SecureCompleteProfil,
  SecureRegisterUser
} from "../../services/auth.service";
import history from "../../../utils/history";
import {
  uploadClientImage,
  uploadCourseImage
} from "../../services/uploads.service";
import { uid } from "uid";
import { loginUserFailed } from "../login/action-creators";
import i18n from "../../../i18n";
import { closeAlert } from "../admin/clients-list/action-creators";
// import {loginService} from "./services";

/* Define the actions watchers */
export function* watchUserRegister() {
  yield takeLatest(REGISTER_USER, userRegisterWorker);
}

export function* watchGoogleRegister() {
  yield takeLatest(REGISTER_GOOGLE, googleRegisterWorker);
}

export function* watchFacebookRegister() {
  yield takeLatest(REGISTER_FACEBOOK, facebookRegisterWorker);
}

export function* watchUserCompleteProfile() {
  yield takeLatest(COMPLETE_PROFIL_REGISTER, userCompleteProfileWorker);
}

/* Define the actions workers */
function* userRegisterWorker({ payload }) {
  // Get Userinfo infos and history from the payload
  const data = payload.userinfo;

  // Attempt to authenticate
  try {
    const call_result = yield SecureRegisterUser(data);
    yield put(registerUserSuccess(call_result));
    yield call(forwardTo, "/complete");
  } catch (error) {
    const alert_id = uid();
    yield put(registerUserFailed(alert_id, "danger", "User already exists"));

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function* facebookRegisterWorker({ payload }) {

}

function* googleRegisterWorker({ payload }) {

}

function* userCompleteProfileWorker({ payload }) {
  try {
    const image_upload_result = yield uploadClientImage(
      "userImage",
      payload.image
    );

    const call_result = yield SecureCompleteProfil({
      avatar_path: image_upload_result.data.message,
      description: payload.description,
      language: "fr"
    });
    yield completeProfileStepSuccess(call_result);
    forwardTo("/");
  } catch (e) {
    yield put(completeProfileStepFailed(["internal error"]));
  }
}

function forwardTo(location) {
  history.push(location);
}

// Export the combined sagas
export default function* allSagas() {
  yield all([
    fork(watchUserRegister),
    fork(watchGoogleRegister),
    fork(watchFacebookRegister),
    fork(watchUserCompleteProfile)
  ]);
}
