import React, { Component } from "react";
import { Translation } from "react-i18next";
import './index.css'
import { findIndex } from "lodash";

// import iconLens from './assets/Icon-Lens.svg'
import BusinessGameCard from "../BusinessGameCard";
import IconInput from "../IconInput";
import LargeButton from "../LargeButton";
class ChooseBusinessGameModal extends Component {

    state = {
        selected_games: []
    }

    handleSelectGame = (game) => {
        // add or remove game from selected games list
        if (this.state.selected_games.map(g => g.id).includes(game.id)) {
            this.setState({
                ...this.state,
                selected_games: this.state.selected_games.filter(g => g.id !== game.id)
            });
        } else {
            this.setState({
                ...this.state,
                selected_games: [...this.state.selected_games, { ...game, remaining_sessions_count: 1 }]
            });
        }
    }

    handleGameCounterValueChange = (game_id, value) => {
        const targeted_game_index = findIndex(this.state.selected_games, (game) => game.id === game_id);
        const targeted_game = { ...this.state.selected_games[targeted_game_index], remaining_sessions_count: value };

        const updated_selected_games = [...this.state.selected_games];
        updated_selected_games.splice(targeted_game_index, 1, targeted_game);

        this.setState({
            ...this.state,
            selected_games: [...updated_selected_games]
        });
    }

    clearLocalState = () => {
        this.setState({
            selected_games: []
        });
    }

    render() {
        // Get props
        const { gamesList, isOpen, searchString, onClose, onAffect, onSearch } = this.props;
        const { selected_games } = this.state;
        console.log("🚀 ~ file: index.jsx:54 ~ ChooseBusinessGameModal ~ render ~ selected_games:", selected_games)

        let class_name = "choose_business_game_modal_wrapper ";
        class_name += isOpen ? "choose_business_game_modal_open" : "";

        return <Translation>
            {t => {
                return (
                    <div className={class_name} onClick={(e) => {
                        if (e.target.className === class_name) {
                            onClose();
                            this.clearLocalState();
                        }
                    }}>
                        <div className="NE_body">
                            <div className="NE_text">
                                <h3 className="NE_text_body">{t("admin.client-details.statics.business-game-modal.title")}</h3>
                                <h3 className="NE_text_body" onClick={() => { onClose(); this.clearLocalState() }}>x</h3>
                            </div>
                            <div className="NE_search_bar" style={{ marginLeft: "55px", width: "885px" }}>
                                <IconInput id="search_business-game" placeholder={t("admin.client-details.statics.business-game-modal.fields.search_placeholder")} value={searchString} onChange={onSearch} />
                            </div>
                            <div className="NE_body_content">
                                {gamesList?.map(game => {
                                    return <BusinessGameCard key={game.id} isSelected={selected_games.filter(g => g.id === game.id).length === 1} game={game} onClick={this.handleSelectGame} withCounter={true} onCounterChange={this.handleGameCounterValueChange} />
                                })}
                            </div>
                            <div className="form-actions">
                                <div className="form-action">
                                    <LargeButton value={t("admin.client-details.statics.business-game-modal.cancel_button")} onClick={() => { onClose(); this.clearLocalState() }} />
                                </div>

                                <div className="form-action">
                                    <LargeButton disabled={!selected_games.length > 0} button="dark" value={t("admin.client-details.statics.business-game-modal.affect_button")} onClick={() => { onAffect(this.state.selected_games); onClose(); this.clearLocalState() }} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Translation>
    }
}
export default ChooseBusinessGameModal;
