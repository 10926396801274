import { axiosInstance, getSecureAxiosInstance } from "./axios-instance";

export const isAuthenticated = () => {
  // Check if the current Userinfo token exists in local storage
  const currentUserToken = localStorage.getItem("NowEdge.current_user_token");
  const currentUser = JSON.parse(localStorage.getItem("NowEdge.current_user"));


  return currentUserToken ?? false;
};

export const loginSecureFacebook = data => {
  return getSecureAxiosInstance().post("user/authenticate-with-facebook", data);
};

export const loginSecureGoogle = data => {

  return getSecureAxiosInstance().post("user/authenticate-with-google", data);
};

export const loadAllClients = () => {
  return getSecureAxiosInstance().get("admin/clients");
};

export const SecureloginUser = data => {
  return getSecureAxiosInstance().post("user/authenticate", data);
};

export const SecureRegisterUser = data => {
  return getSecureAxiosInstance().post("user/register", data);
};

export const SecureResetUser = data => {
  return getSecureAxiosInstance().post(
    `user/reset-password`,
    {
      email: data.email
    }
  );
};

export const SecureCompleteProfil = data => {
  return getSecureAxiosInstance().post("user/complete-profile", data);
};

export const SecureUpdateProfil = data => {
  return getSecureAxiosInstance().post("user/update-user", data);
};
