import React, { useEffect, useState } from 'react';
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import NormalButton from "../../components/NormalButton";
import {
    changeUpdateUserValues,
    updateUser
} from "../../redux/updateuser/actions-creators";
import styles from './index.module.css';

import { useSelector, useDispatch } from 'react-redux';
import {
    Tabs,
    Tab,
    Form,
    Modal,
    Container,
    Dropdown,
    Button,
} from 'react-bootstrap';
import {
    getParticipants,
    selectParticipant,
    changeStatusParticipant,
    selectQuizStatistics,
    getQuizStatistics,
    getTeams,
    assignmentTeams,
} from '../../redux/studentManagement/actions';
import _ from 'lodash';
import { useRef } from 'react';
import { getTeamsService } from '../../redux/studentManagement/service';
import Dropdown2 from '../../components/Dropdown2';

const AffectationItem = ({
    user = {},
    group = [],
    item = {},
    block = 1,
    onSelect = () => null,
}) => {
    const [selectVal, setSelectVal] = useState();

    useEffect(() => {
        setSelectVal(item.team_name !== undefined ? item.team_name : group[0].team_name || '');
    }, [item.team_name]);

    console.log("---group--->>", group);
    return (
        <div className={styles.affectationContent}>
            <div className="test-affectation-l" style={{ flex: '6' }} >
                <h3>
  {user.full_name
    ? user.full_name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : ""}
</h3>

            </div>
            <div style={{
                flex: '4',
            }} >
                <div>
                    <Dropdown2
                        onChange={(item) => {
                            console.log("🚀 ~ file: index.jsx:57 ~ item:", item)
                            onSelect(item.team_id, item.id, user.id, block);
                        }}
                        value={selectVal}
                        route={'userupdate'}

                        placeholder="Choose a langage"
                        items={group.map((item, index) => ({ id: item?.team_id, name: item?.team_name }))} id="category"
                    />
                </div>
                {/* [{ id: 0, name: 'en', disabled: false }, { id: 1, name: 'fr', disabled: false }] */}

                {/* <Dropdown id="affectation-select">
                    <Dropdown.Toggle id="dropdown-basic" style={{ width: '100%', whiteSpace: 'nowrap' }}>
                        {selectVal}
                        <i className=" fas fa-angle-down ml-2"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {group.map((item_, index) => {
                            return (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => {
                                        setSelectVal(item_.teamName);
                                        onSelect(item.teamId, item_.teamId, user.id, block);
                                    }}>
                                    {item_.teamName}
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>
        </div>
    );
};


const AffectationList = (props) => {

    const { show, onHide, participants = [], t, onCancel } = props;

    const [participants_, setParticipants] = useState([]);

    const dispatch = useDispatch();

    const listF = useRef(null);

    const teams = useSelector((state) => state.StudentManagement.teams);
    const trainingSessionId = useSelector((state) => state.moderator_training_sessions.selected_session.id)

    useEffect(() => {
        const list = [];
        const listP = [];

        if (
            teams.length > 0 &&
            (teams[0].players.length > 0 ||
                teams[1].players.length > 0 ||
                teams[2].players.length > 0 ||
                teams[3].players.length > 0)
        ) {
            teams.map((item) => {
                item.players.map((elem) => {
                    list.push({
                        team_id: item.team_id,
                        team_name: item.team_name,
                        trainingSessionParticipantId: null,
                        user: elem.player_user,
                    });
                    listP.push({
                        team_id: item.team_id,
                        game_session_id: item.game_session_id,
                        user_id: elem?.player_user?.id,
                    })
                });
            });

            listF.current = {
                listP: listP
            }
            return setParticipants(list)
        }
    }, [teams, show]);

    const rendom = () => {
        const listG = teams.map((item) => {
            return { ...item, players: [] };
        });
        let list_ = [];
        let list = [];
        let index = 0;
        while (list_.length !== participants_.length) {
            const item = participants_[Math.floor(Math.random() * participants_.length)];

            const res = list_.find(function (ele) {
                return ele.user.id === item.user.id;
            });
            if (res === undefined) {
                list_.push(item);

                listG[index].players = [...listG[index].players, item.user];
                if (index === 3) index = 0;
                else index += 1;
            }
        }

        listF.current.listP = [];
        listG.map((group, index) => {
            group.players.map((user) => {
                listF.current.listP.push({
                    game_session_id: group.game_session_id,
                    team_id: group.team_id,
                    user_id: user.id
                });
            });
        });


        listG.map((item) => {
            item.players.map((elem) => {
                list.push({
                    team_id: item.team_id,
                    team_name: item.team_name,
                    trainingSessionParticipantId: null,
                    user: elem,
                });
            });
        });

        setParticipants(list);
    };

    const coll = () => {

        dispatch(getParticipants(trainingSessionId));
        dispatch(getTeams(trainingSessionId));
        onCancel();
    }
    const save = () => {
        dispatch(assignmentTeams(listF.current.listP, coll));
    };

    const selectTeam = (teamId, newTeamId, user_id, block) => {

        const objIndex = listF.current.listP.findIndex((obj => obj.user_id === user_id));

        listF.current.listP[objIndex].team_id = newTeamId
    };

    return (

        <div className={styles.form_content}>
            <div className={styles.affectation_title}>
                <div className="test-affectation-t">
                    <h1>Affectation</h1>
                </div>
                <div>
                    <button variant="secondary" onClick={rendom}>
                        <i className="fa fa-random"></i>
                    </button>
                </div>
            </div>

            {
                participants_.map((item, index) => {
                    return (
                        <AffectationItem
                            key={index}
                            user={item.user}
                            group={teams}
                            onSelect={selectTeam}
                            block={2}
                            item={item}
                        />
                    );
                })
            }

            {/* <div className="d-flex  flex-row-reverse mt-3">
                <button variant="primary" className="ml-2" onClick={save}>
                    Enregistrer
                </button>
                <button variant="secondary" onClick={() => onHide(false)}>
                    Retour
                </button>
            </div> */}
            <div className={styles.affectation_footer}>
                <div className={styles.affectation_hwidth}>
                    <NormalButton button={"normal"} value={t("participant.user-info.cancel_button")} onClick={onCancel} />
                </div>
                <div className={styles.affectation_vwidth}>
                    <NormalButton button={"dark"} value={t("participant.user-info.save_button")} onClick={save} />
                </div>
            </div>
        </div>

    );
};



function UserAffectation({ isOpen, onCancel }) {

    const [show, setShow] = useState(false);

    let class_name = styles.header_modal_wrapper;
    class_name += isOpen ? styles.header_modal_open : "";

    const handleSubmit = () => {

    }

    const getTeamsService_ = async () => {

        // await getTeamsService(97);
    }

    useEffect(() => {
        getTeamsService_();

    }, [])


    return (
        <Translation>
            {t =>
                <>
                    <div className={`${styles.header_modal_wrapper} ${isOpen ? styles.header_modal_open : ""}`} onClick={(e) => {
                        if (e.target.className === class_name) {
                            onCancel();
                        }
                    }}>
                        <div className={styles.affectation_page_body}>
                            <div className={styles.containerinfo}>
                                <AffectationList t={t} onCancel={onCancel} />
                            </div>

                        </div>
                    </div>
                </>
            }
        </Translation>
    )
    // }
}

const mapStateToProps = (state) => {
    const { user_information } = state
    return { user_information }
};

const mapActionsToProps = {
    updateUser,
    changeUpdateUserValues,
};

export default connect(mapStateToProps, mapActionsToProps)(UserAffectation);
