import React, {Component} from "react";
import './index.css'
import Header from "../../../components/Header";

class ParticipantProfile extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

    }
    handelScroll = (scrollOffset) => {
        this.myRef.current.scrollLeft += scrollOffset;
    };
    render() {
        return (
            <>
                <div className="participant_page_header">
                    <Header user='participant'/>
                </div>
            <div className="participant_page-body">
                <div className="user_infos_score">
                    <div className="user_infos">
                        <img src="assets/profile-picture-2.png" alt="user profile" />
                        <h1>Welcome Hamza</h1>
                        <p>Complete lessons each day to build your streak and get promoted</p>
                    </div>
                    <div className="user_scores">
                        <h1>Score</h1>
                        <div className="NE_score_gage">
                            <p>0</p>
                            <div className="progress_bar">
                                <div className="progress_level" style={{width: '694px'}} />
                                <div className="progress_points_wrapper">
                                    <div className="progress_points">
                                        <div className="progress_point progress_point_small" style={{marginLeft: '242px'}}>
                                            130
                                        </div>
                                        <div className="progress_point" style={{marginLeft: '391px'}}>
                                            130
                                        </div>
                                        <div className="progress_point next_level_progress_point">
                                            300
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user_metrics">
                    <h1>Your Metrics</h1>
                    <div className="metrics_slider">
                        <div className="slider_action action_prev" ref={this.myRef} onClick={()=>{this.handelScroll(20)}}/>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="metric_card">
                            <div className="metric_points">
                                <p>5</p>
                                <p>xp</p>
                            </div>
                            <p className="description">Write a description</p>
                            <p className="long_description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
                        </div>
                        <div className="slider_action action_next" ref={this.myRef} onClick={()=>{this.handelScroll(-20)}}/>
                    </div>
                </div>
                <div className="community_progress">
                    <div className="header">
                        <div className="title">
                            <h1>Community progress</h1>
                        </div>
                        <div className="metrics_titles">
                            <div className="metric_title">Number of Courses</div>
                            <div className="metric_title">Number of Quizzes</div>
                            <div className="metric_title">Number of Questions</div>
                        </div>
                    </div>
                    <div className="body">
                        <div className="users_progress_wrapper">
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                            <div className="user_progress_row">
                                <div className="progress">
                                    <p className="user_rank">1</p>
                                    <img src="https://i.pravatar.cc/64" alt="user profile" />
                                    <div className="user_score_progress">
                                        <h1>Hamza Mounir</h1>
                                        <div className="NE_score_gage">
                                            {/*                                    <p>0</p>*/}
                                            <div className="progress_bar">
                                                <div className="progress_level" style={{width: '70%'}} />
                                                <div className="progress_points_wrapper">
                                                    <div className="progress_points">
                                                        {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                                        {/*                                                    130*/}
                                                        {/*                                                </div>*/}
                                                        <div className="progress_point" style={{marginLeft: 'calc(70% - 22px)'}}>
                                                            200
                                                        </div>
                                                        {/*                                                <div class="progress_point next_level_progress_point">*/}
                                                        {/*                                                    300*/}
                                                        {/*                                                </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="metrics">
                                    <div className="metric">11</div>
                                    <div className="metric">15</div>
                                    <div className="metric">5</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
export default ParticipantProfile;
