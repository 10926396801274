import {all, fork, put, takeLatest} from "redux-saga/effects";
import {
    ADD_MESSAGE,
    GET_DISCUSSION_TRAINING_SESSION,
    GET_MESSAGES,
    GET_PROGRESS,
    GET_QUIZ,
    LOAD_COURSE,
    LOAD_TRAINING_SESSION,
    SAVE_QUIZ,
    UPDATE_PROGRESS
} from "./action-definitions";
import {
    addMessageError,
    addMessageSuccess,
    getDiscussionTrainingSessionError,
    getDiscussionTrainingSessionSuccess,
    getMessagesError,
    getMessagesSuccess,
    getProgressError,
    getProgressSuccess,
    getQuizError,
    getQuizSuccess,
    loadCourseSideBarData,
    loadTrainingSessionError,
    loadTrainingSessionSuccess,
    saveQuizError,
    saveQuizSuccess,
    updateProgressError,
    updateProgressSuccess
} from "./action-creators";
import {
    addMessage,
    getDiscussionTrainingSession,
    getMessages,
    getProgress,
    getQuizResponses,
    loadTrainingSession,
    saveQuiz,
    updateProgress
} from "../../../services/participant.service";
import {loadCourseContent} from "../../../services/moderator.service";

export function* watchLoadTrainingSessionWorker() {
    yield takeLatest(LOAD_TRAINING_SESSION, loadTrainingSessionWorker);
}

export function* watchGetDiscussionTrainingSessionWorker() {
    yield takeLatest(
        GET_DISCUSSION_TRAINING_SESSION,
        getDiscussionTrainingSessionWorker
    );
}

export function* watchGetMessagesWorker() {
    yield takeLatest(GET_MESSAGES, getMessagesWorker);
}

export function* watchAddMessageWorker() {
    yield takeLatest(ADD_MESSAGE, addMessageWorker);
}

export function* watchGetProgressWorker() {
    yield takeLatest(GET_PROGRESS, getProgressWorker);
}

export function* watchUpdateProgressWorker() {
    yield takeLatest(UPDATE_PROGRESS, updateProgressWorker);
}

export function* watchSaveQuizWorker() {
    yield takeLatest(SAVE_QUIZ, saveQuizWorker);
}

export function* watchGetQuizWorker() {
    yield takeLatest(GET_QUIZ, getQuizWorker);
}

export function* watchLoadCourseWorker() {
    yield takeLatest(LOAD_COURSE, loadCourseWorker);
}

/* Define the actions workers */
function* addMessageWorker({
                               payload: {discussion_id, parent_message_id, content, onFinish}
                           }) {
    console.log(`{ discussion_id, parent_message_id, content, onFinish }`, {
        discussion_id,
        parent_message_id,
        content,
        onFinish
    });
    try {
        const call_result = yield addMessage({
            discussion_id,
            parent_message_id,
            content
        });

        const message = call_result.data;

        yield put(addMessageSuccess(message));
        onFinish();
    } catch (error) {

        yield put(addMessageError(["Cannot load messages."]));
    }
}

/* Define the actions workers */
function* getProgressWorker({payload}) {
    // Get admin data from payload

    // Attempt to call backend to load clients list
    try {
        const call_result = yield getProgress(payload.training_session_id);

        const progress = call_result.data;

        yield put(getProgressSuccess(progress));
    } catch (error) {
        yield put(getProgressError(["Cannot load progress."]));
    }
}

/* Define the actions workers */
function* saveQuizWorker({
                             payload: {training_session_id, quiz_id, question_results}
                         }) {
    // Get admin data from payload
    // Attempt to call backend to load clients list
    try {
        const call_result = yield saveQuiz(
            training_session_id,
            quiz_id,
            question_results
        );

        const quiz = call_result.data;

        yield put(saveQuizSuccess(quiz));
    } catch (error) {
        yield put(saveQuizError(["Cannot save quiz."]));
    }
}

/* Define the actions workers */
function* getQuizWorker({payload: {training_session_id, quiz_id}}) {
    // Get admin data from payload
    // Attempt to call backend to load clients list
    try {
        const call_result = yield getQuizResponses(training_session_id, quiz_id);

        const quiz = call_result.data;

        yield put(getQuizSuccess(quiz));
    } catch (error) {
        yield put(getQuizError(["Cannot load quiz."]));
    }
}

/* Define the actions workers */
function* updateProgressWorker({payload}) {
    // Get admin data from payload

    // Attempt to call backend to load clients list
    try {
        const call_result = yield updateProgress(
            payload.training_session_id,
            payload.progress_id
        );

        const progress = call_result.data;

        yield put(updateProgressSuccess(payload.progress_id));
    } catch (error) {
        yield put(updateProgressError(["Cannot load progress."]));
    }
}

/* Define the actions workers */
function* getMessagesWorker({payload}) {
    // Get admin data from payload

    // Attempt to call backend to load clients list
    try {
        const call_result = yield getMessages(payload.discussion_id);

        const messages = call_result.data;

        // .sort(function (a, b) {
        //   // Turn your strings into dates, and then subtract them
        //   // to get a value that is either negative, positive, or zero.
        //   return new Date(a.posting_time) - new Date(b.posting_time);
        // });

        const originMessages = messages.filter(m => m.parent_message_id === null);

        //
        yield put(getMessagesSuccess({messages, originMessages}));
    } catch (error) {
        yield put(getMessagesError(["Cannot load messages."]));
    }
}

/* Define the actions workers */
function* getDiscussionTrainingSessionWorker({payload}) {
    // Get admin data from payload

    // Attempt to call backend to load clients list
    try {
        const call_result = yield getDiscussionTrainingSession(
            payload.training_session_id
        );

        yield put(getDiscussionTrainingSessionSuccess(call_result.data));
    } catch (error) {
        yield put(getDiscussionTrainingSessionError(["Cannot load discussion."]));
    }
}

/* Define the actions workers */
function* loadTrainingSessionWorker({payload}) {
    // Get admin data from payload

    // Attempt to call backend to load clients list
    try {
        const call_result = yield loadTrainingSession(payload.training_session_id);

        // Dispatch success

        const elements = call_result.data.content
            .filter(c => c.reference_object === "module")
            ?.map(e => {
                const element = {
                    type: "element",
                    label: e.title,
                    id: e.id,
                    element_order: e.element_order
                };

                element.notions = e.notions.map(n => {
                    return {
                        type: "notion",
                        label: n.title,
                        id: n.id,
                        parentId: element.id
                    };
                });

                element.quizzes = e.quizzes.map(q => {
                    return {
                        type: "quiz",
                        label: q.title,
                        id: q.id,
                        parentId: element.id
                    };
                });

                return element;
            });

        const evaluation = call_result.data.content.find(
            c => c.reference_object === "evaluation"
        );

        const documents = call_result.data.content.find(
            c => c.reference_object === "documents"
        );

        const businessGame = call_result.data.content.find(
            c => c.reference_object === "business_game"
        );

        const data = {
            elements: elements.sort(function (a, b) {
                return a?.element_order - b?.element_order;
            }),
        };

        // if (documents?.id) data.documents = {
        //     type: "documents",
        //     label: documents?.title,
        //     id: documents?.id
        // }

        data.documents =  call_result.data.content.filter(
            c => c.reference_object === "documents"
        ).map(doc => ({ type: "documents",
            label: doc?.title,
            id: doc?.id,
            element_order:doc?.element_order

        }));



        data.evaluation =  call_result.data.content.filter(
            c => c.reference_object === "evaluation"
        ).map(elv => ({
            type: "evaluation",
            label: elv?.title,
            id: elv?.id,
            element_order:elv?.element_order

        }));

        // if (evaluation?.id) data.evaluation = {
        //     type: "evaluation",
        //     label: evaluation?.title,
        //     id: evaluation?.id
        // }

         data.businessGame =call_result.data.content.filter(
             c => c.reference_object === "business_game"
         ).map((game)=>({
             type: "business_game",
             id: game?.id,
             element_order:game?.element_order
         }));


        yield put(loadCourseSideBarData(data));

        yield put(loadTrainingSessionSuccess(call_result.data));
    } catch (error) {

        yield put(loadTrainingSessionError(["Cannot load clients list."]));
    }
}

/* Define the actions workers */
function* loadCourseWorker({payload}) {
    // Get admin data from payload

    // Attempt to call backend to load clients list
    try {
        const call_result = yield loadCourseContent(payload.course_id);

        // Dispatch success

        const elements = call_result.data.content
            .filter(c => c.reference_object === "module")
            ?.map(e => {
                const element = {
                    type: "element",
                    label: e.title,
                    id: e.id,
                    element_order: e.element_order
                };

                element.notions = e.notions.map(n => {
                    return {
                        type: "notion",
                        label: n.title,
                        id: n.id,
                        parentId: element.id,
                        element_order:n?.element_order

                    };
                });

                element.quizzes = e.quizzes.map(q => {
                    return {
                        type: "quiz",
                        label: q.title,
                        id: q.id,
                        parentId: element.id,
                        element_order:q?.element_order

                    };
                });

                return element;
            });

        const evaluation = call_result.data.content.filter(
            c => c.reference_object === "evaluation"
        ).map(elv => ({
            type: "evaluation",
            label: elv?.title,
            id: elv?.id,
            element_order:elv?.element_order
        }));

        const documents = call_result.data.content.filter(
            c => c.reference_object === "documents"
        ).map(doc => ({ type: "documents",
            label: doc?.title,
            id: doc?.id,
            element_order:doc?.element_order
        }));


        const businessGame = call_result.data.content.filter(
            c => c.reference_object === "business_game"
        ).map((game)=>({
            type: "business_game",
            id: game?.id,
            element_order:game?.element_order

        }));

        const data = {
            elements: elements.sort(function (a, b) {
                return a?.element_order - b?.element_order;
            }),
            evaluation: evaluation,
            documents: documents,
            businessGame: businessGame
        };
        yield put(loadCourseSideBarData(data));

        yield put(loadTrainingSessionSuccess(call_result.data));
    } catch (error) {

        yield put(loadTrainingSessionError(["Cannot load clients list."]));
    }
}

// Export the combined sagas
export default function* allSagas() {
    yield all([
        fork(watchLoadTrainingSessionWorker),
        fork(watchGetDiscussionTrainingSessionWorker),
        fork(watchGetMessagesWorker),
        fork(watchAddMessageWorker),
        fork(watchGetProgressWorker),
        fork(watchUpdateProgressWorker),
        fork(watchSaveQuizWorker),
        fork(watchGetQuizWorker),
        fork(watchLoadCourseWorker)
    ]);
}
