import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { getConfigColors } from '../redux/config/action-creators';
import CryptoJS from "crypto-js";

const secret_key = '6@3gmqe^bph=5#47f#nefvr!@txod=(_q=nspcr_ak#b=lqt#m';

function decryptIdClinet(encStr) {
    const key = '55a51621a6648525';
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    const dec = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(encStr) },
        keyutf,
        {
            iv: iv
        });
    const decStr = CryptoJS.enc.Utf8.stringify(dec)

    return decStr
}


function LoaderTheme({ }) {

    let { id } = useParams();
    console.log("🚀 ~ file: loaderTheme.jsx:29 ~ LoaderTheme ~ id:", id)
    const original_id = decryptIdClinet(id)
    console.log("🚀 ~ file: loaderTheme.jsx:30 ~ LoaderTheme ~ original_id:", original_id)


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getConfigColors(original_id))
    }, [])
    return <Redirect to="/" />;
}

export default LoaderTheme;