export const LOAD_CLIENT_DETAILS = "ADMIN.CLIENT-DETAILS.LOAD_CLIENT_DETAILS";
export const LOAD_CLIENT_DETAILS_SUCCESS =
  "ADMIN.CLIENT-DETAILS.LOAD_CLIENT_DETAILS_SUCCESS";
export const LOAD_CLIENT_DETAILS_ERROR =
  "ADMIN.CLIENT-DETAILS.LOAD_CLIENT_DETAILS_ERROR";
export const CHANGE_CURRENT_CLIENT_VALUE =
  "ADMIN.CLIENT-DETAILS.CHANGE_CURRENT_CLIENT_VALUE";
export const LOAD_AVAILABLE_GAMES = "ADMIN.CLIENT-DETAILS.LOAD_AVAILABLE_GAMES";
export const LOAD_AVAILABLE_GAMES_SUCCESS =
  "ADMIN.CLIENT-DETAILS.LOAD_AVAILABLE_GAMES_SUCCESS";
export const LOAD_AVAILABLE_GAMES_ERROR =
  "ADMIN.CLIENT-DETAILS.LOAD_AVAILABLE_GAMES_ERROR";
export const LOAD_AVAILABLE_MODERATORS =
  "ADMIN.CLIENT-DETAILS.LOAD_AVAILABLE_MODERATORS";
export const LOAD_AVAILABLE_MODERATORS_SUCCESS =
  "ADMIN.CLIENT-DETAILS.LOAD_AVAILABLE_MODERATORS_SUCCESS";
export const LOAD_AVAILABLE_MODERATORS_ERROR =
  "ADMIN.CLIENT-DETAILS.LOAD_AVAILABLE_MODERATORS_ERROR";
export const AFFECT_GAMES_TO_CLIENT =
  "ADMIN.CLIENT-DETAILS.AFFECT_GAMES_TO_CLIENT";
export const AFFECT_GAMES_TO_CLIENT_SUCCESS =
  "ADMIN.CLIENT-DETAILS.AFFECT_GAMES_TO_CLIENT_SUCCESS";
export const AFFECT_GAMES_TO_CLIENT_ERROR =
  "ADMIN.CLIENT-DETAILS.AFFECT_GAMES_TO_CLIENT_ERROR";
export const AFFECT_MODERATORS_TO_CLIENT =
  "ADMIN.CLIENT-DETAILS.AFFECT_MODERATORS_TO_CLIENT";
export const AFFECT_MODERATORS_TO_CLIENT_SUCCESS =
  "ADMIN.CLIENT-DETAILS.AFFECT_MODERATORS_TO_CLIENT_SUCCESS";
export const AFFECT_MODERATORS_TO_CLIENT_ERROR =
  "ADMIN.CLIENT-DETAILS.AFFECT_MODERATORS_TO_CLIENT_ERROR";
export const REMOVE_MODERATOR_FROM_CLIENT =
  "ADMIN.CLIENT-DETAILS.REMOVE_MODERATOR_FROM_CLIENT";
export const REMOVE_MODERATOR_FROM_CLIENT_SUCCESS =
  "ADMIN.CLIENT-DETAILS.REMOVE_MODERATOR_FROM_CLIENT_SUCCESS";
export const REMOVE_MODERATOR_FROM_CLIENT_ERROR =
  "ADMIN.CLIENT-DETAILS.REMOVE_MODERATOR_FROM_CLIENT_ERROR";
export const PUSH_ALERT = "ADMIN.CLIENTS-LIST.PUSH_ALERT";
export const CLOSE_ALERT = "ADMIN.CLIENTS-LIST.CLOSE_ALERT";
