import React, {Component} from 'react';
import "./index.css";
import OptionsPopup from "../../../OptionsPopup";

class SessionItem extends Component {

    state = {
        is_options_open: false
    }

    toggleOptions = () => {
        this.setState({
            ...this.state,
            is_options_open: !this.state.is_options_open
        });
    }

    render() {
        const {session,options,isSelected,onClick} = this.props;
        const {is_options_open} = this.state;

        let class_name = "NE_session_item ";
        class_name += session.status === "ONGOING" ? "NE_session_item_ongoing " : "";
        class_name += session.status === "TO_COME_UP" ? "NE_session_item_to_come " : "";
        class_name += session.status === "COMPLETED" ? "NE_session_item_completed " : "";

        class_name += isSelected ? "NE_session_item_selected" : "";

        let options_wrapper_class = "options_wrapper ";
        options_wrapper_class += is_options_open ? "options_wrapper_open" : "";

        return (
            // <li className="NE_session_item NE_session_item_selected NE_session_item_ongoing NE_session_item_to_come NE_session_item_completed">
            <li className={class_name} onClick={(e) => {
                if(e.target.className === class_name){
                    onClick(session);
                }
            }}>
                {session.title}
                {
                    options.length > 0 &&
                    <>
                        <span className="actions_btn" onClick={() => {this.toggleOptions();}} />
                          { is_options_open && <div className={"close_modale"} onClick={()=>this.setState({is_options_open:false})} >
                            </div>}
                        <div className={options_wrapper_class}>
                            <OptionsPopup options={options} onClick={this.toggleOptions} />
                        </div>
                    </>
                }
            </li>
        );
    }
}

export default SessionItem;
