import React, { Component } from "react";
import moment from "moment";
import "./index.css";
import { Translation } from "react-i18next";

class CardActionProgress extends Component {
  render() {
    const {
      image = "https://picsum.photos/268/180",
      name,
      description,
      trainingSession,
      onClick = () => {},
    } = this.props;

    return (
      <Translation>
        {
          t =>


      <>
        <div className="NE_action_progress_card" onClick={onClick}>
          <div className="card_header">
            <div className="card_image">
              <img src={trainingSession?.course?.image_path} alt="" height="180"/>
              <div className="card_category">
                {trainingSession?.course?.category?.title}
              </div>
            </div>
            <div className="card_secondary_text">
              From{" "}
              {moment(trainingSession?.training_session?.start_date).format(
                "YYYY-MM-DD"
              )}{" "}
              to{" "}
              {moment(trainingSession?.training_session?.end_date).format(
                "YYYY-MM-DD"
              )}
            </div>
          </div>

          <div className="card_action_body">
            <h4>{trainingSession?.course?.title}</h4>
            {/*<p>{trainingSession?.course?.description}</p>*/}
          </div>

          <div className="card_footer">
            <button>{t("participant.go_to_course_text")}</button>
            <div className="NE_progress_circle">
              <svg>
                <circle
                    strokeDasharray="170 170"
                    strokeDashoffset={Math.abs(
                    trainingSession?.progress * 170 - 170
                  )}
                  r="27"
                  cx="30.5"
                  cy="30.5"
                />
              </svg>

              <div className="progress_infos">
                <p className="progress_percentage">
                  {(trainingSession?.progress * 100).toFixed(0)}%
                </p>
                <p className="progress_ratio">{trainingSession?.done_elements}/{trainingSession?.all_elements}</p>
              </div>
            </div>
          </div>
        </div>
      </>
       }
       </Translation>
    );
  }
}


export default CardActionProgress;
