import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  CHANGE_CURRENT_CLIENT_INFO_VALIDATION_PROP_VALUE,
  CLOSE_ALERT,
  CHANGE_CURRENT_USER_INFO_VALUE,
  REMOVE_CURRENT_CLIENT_INFO_VALIDATION,
  // CHANGE_CURRENT_USER_INFO_VALUE,
  // REMOVE_CURRENT_CLIENT_INFO_VALIDATION,
  UPDATE_USER_CHANGE_PROPS,
  PUSH_ALERT
} from "./actions-definitions";

export const changeUpdateUserValues = (propName, propValue) => ({
  type: UPDATE_USER_CHANGE_PROPS,
  payload: { propName, propValue }
});

export const changeCurrentUserInfoValidationPropValue = (
  propName,
  propValue
) => ({
  type: CHANGE_CURRENT_CLIENT_INFO_VALIDATION_PROP_VALUE,
  payload: { propName, propValue }
});

export const updateUser = (userinfo,callback) => ({
  type: UPDATE_USER,
  payload: { userinfo  , callback}
});

export const updateUserSuccess = userinfo => ({
  type: UPDATE_USER_SUCCESS,
  payload: userinfo
});

export const updateUserFailed = (id, type, message) => ({
  type: UPDATE_USER_FAILED,
  payload: { id, type, message }
});

export const changeCurrentUserInfoValue = current_user => ({
  type: CHANGE_CURRENT_USER_INFO_VALUE,
  payload: current_user
});

export const cleanUpValidation = (propName, propValue) => ({
  type: REMOVE_CURRENT_CLIENT_INFO_VALIDATION,
  payload: { propName, propValue }
});

export const pushAlert = (id, type, message) => ({
  type: PUSH_ALERT,
  payload: { id, type, message }
});

export const closeAlert = id => ({
  type: CLOSE_ALERT,
  payload: id
});
