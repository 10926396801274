import React,{Component} from "react";
import './index.css'

const  NormalInput = (props)=> {

        const {label,message,onChange,isValid,id,value, defaultValue, ...rest} = props;

        let class_name = "NE_normal_input ";

        class_name+= isValid === false ? "NE_normal_input_error" : "";

        return (
            <div className={class_name}>
                <label htmlFor={id}>{label}</label>
                <input id={id} onChange={onChange}  value={value} {...rest}/>
                {(isValid !== undefined) && <p>{message}</p>}
            </div>
        );

}
export default NormalInput;
