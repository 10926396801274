import {
  ADD_ATTACHED_DOCUMENT_SUCCESS,
  DELETE_ATTACHED_DOCUMENTS,
  DELETE_ATTACHED_DOCUMENTS_SUCCESS,
  DELETE_EVALUATION_OPTION,
  EDIT_EVALUATION,
  EDIT_EVALUATION_SUCCESS,
  EDIT_NOTION,
  EDIT_NOTION_SUCCESS,
  LOAD_DATA,
  SET_DATA,
  EDIT_QUIZ,
  IS_LOADING,
  EDIT_QUIZ_SUCCESS,
  DELETE_MODULE,
  DELETE_MODULE_FAILED,
  DELETE_MODULE_SUCCESS,
  DELETE_DOCUMENTS,
  DELETE_DOCUMENTS_FAILED,
  DELETE_DOCUMENTS_SUCCESS,
  DELETE_EVALUATION,
  DELETE_EVALUATION_FAILED,
  DELETE_EVALUATION_SUCCESS,
  ADD_ATTACHED_DOCUMENT,
  ADD_ATTACHED_DOCUMENT_FAILED,
  DELETE_ATTACHED_DOCUMENTS_FAILED,
  DELETE_QUIZ,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAILED,
  ADD_ELEMENT,
  ADD_ELEMENT_COURSE,
  ADD_ELEMENT_COURSE_SUCCESS,
  ADD_ELEMENT_COURSE_FAILED,
  UP_DOWN_ELEMENT_COURSE
} from "./action-types";
import {
  DELETE_GAME_SUCCESS,
  EDIT_COURSE, EDIT_COURSE_SUCCESS, EDIT_DOCUMENTS_ELEMENT_SUCCESS, EDIT_ITEM_COURSE_FAILED,
  EDIT_ITEM_COURSE_SUCCESS,
  EDIT_MODULE,
  EDIT_MODULE_SUCCESS
} from "../configure-course/actions-definitions";

export const initialState = {
  current_course: {
    elements: [
    ],
    isLoading: false
  },
  isLoading: false,
  last_update: +new Date()
};

export default function EditCourseReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state
      };
    case LOAD_DATA:
      action.payload.elements.map((elm) => {
        if (!elm.element_order) {
          return { ...elm, element_order: -1 }
        }

        return elm;

      }).sort((a, b) => (a.element_order > b.element_order) ? 1 : ((b.element_order > a.element_order) ? -1 : 0))

      return {
        ...state,
        isLoading: false,
        current_course: {
          ...action.payload, elements: action.payload.elements.map((elm) => {
            if (!elm.element_order) {
              return { ...elm, element_order: -1 }
            }

            return elm;

          }).sort((a, b) => (a.element_order > b.element_order) ? 1 : ((b.element_order > a.element_order) ? -1 : 0))
        }
      };
    case DELETE_ATTACHED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.map(elem => {
            if (elem.id === action.payload.module_id && elem.type === "MODULE") {
              return {
                ...elem,
                items: elem.items.map((item, index) => {
                  if (item.id === action.payload.notion_id) {
                    return {
                      ...item,
                      attached_documents: item.attached_documents.filter(
                        doc => doc.id !== action.payload.id
                      )
                    };
                  }
                  return item;
                })
              }
            }
            if (elem.id === action.payload.documents_id && elem.type === "DOCUMENTS") {
              return {
                ...elem,
                attached_documents: [...elem.attached_documents.filter((doc, index) => doc.id !== action.payload.id)]
              }
            }
            return elem;
          })
        }
      };
    case DELETE_ATTACHED_DOCUMENTS:
      return {
        ...state,
        isLoading: true
      };
    case DELETE_ATTACHED_DOCUMENTS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case ADD_ATTACHED_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.map(elem => {
            if (elem.id === action.payload.documents_id) {
              return {
                ...elem,
                attached_documents: [
                  ...elem.attached_documents,
                  ...action.payload.data
                ]
              };
            }
            return elem;
          })
        }
      };
    case EDIT_EVALUATION || EDIT_NOTION:
      return { ...state, isLoading: true };

    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    case EDIT_EVALUATION_SUCCESS:
      const {
        resp_list_add_question,
        list_edit_question_option,
        resp_list_add_question_option,
        list_delete_question_option,
        list_delete_question,
        list_edit_question
      } = action.payload;

      let { elements } = state.current_course;

      // list_edit_question.map((elem,index)=>{
      //     elements = elements.map((el)=>{
      //         if(el.id === elem.quiz_id ){
      //             return {...el,questions: el.questions.map((item,index)=>{
      //                     if (item.id === elem.id) return  {...el,...elem}
      //                     return item;
      //                 })}
      //         }
      //         return el;
      //     })
      //     return elem;
      // });

      list_edit_question.map((elem, index) => {
        elements = elements.map(el => {
          if (el.id === elem.quiz_id) {
            return {
              ...el,
              questions: el.questions.map((item, index) => {
                if (item.id === elem.id) return { ...item, title: elem.title, justification: elem.justification };
                return item;
              })
            };
          }
          return el;
        });
      });

      list_delete_question.map((elem, index) => {
        elements = elements.map(el => {
          if (el.id === elem.quiz_id) {
            return {
              ...el,
              questions: el.questions.filter(e => e.id !== elem.id)
            };
          }
          return el;
        });
        return elem;
      });

      resp_list_add_question.map((elem, index) => {
        elements.map(el => {
          if (el.id === elem.quiz_id) {
            const list = el.questions;
            list.push(elem);
            return { ...el, items: list };
          }
          return el;
        });
        return elem;
      });

      list_edit_question_option.map((elem, index) => {
        elements = elements.map(el => {
          if (el.id === elem.quiz_id) {
            const list = el.questions.map(e => {
              if (e.id === elem.question_id) {
                const { options } = e;
                const _list = options.map(elm => {
                  if (elm.id === elem.id) {
                    return elem;
                  }
                  return elm;
                });
                return { ...e, options: _list };
              }

              return e;
            });
            return { ...el, questions: list };
          }

          return el;
        });
      });

      resp_list_add_question_option.map((elem, index) => {
        elements = elements.map(el => {
          if (el.id === elem.quiz_id) {
            const list = el.questions.map(e => {
              if (e.id === elem.question_id) {
                const { options } = e;
                return { ...e, options: [...options, elem] };
              }
              return e;
            });
            return { ...el, questions: list };
          }

          return el;
        });
      });

      list_delete_question_option.map((elem, index) => {
        elements = elements.map(el => {
          if (el.id === elem.quiz_id) {
            const list = el.questions.map(e => {
              if (e.id === elem.question_id) {
                const { options } = e;
                const _list = options.filter(elm => elm.id !== elem.id);
                return { ...e, options: _list };
              }

              return e;
            });
            return { ...el, questions: list };
          }

          return el;
        });
      });

      return {
        ...state,
        isLoading: false,
        current_course: { ...state.current_course, elements }
      };
    case DELETE_EVALUATION_OPTION:
      let { elements: elements_ } = state.current_course;
      elements_ = elements_.map(el => {
        if (el.id === action.payload.quiz_id) {
          const list = el.questions.map((item, index) => {
            if (item.id === action.payload.question_id)
              return {
                ...item,
                options: item.options.filter(e => e.id !== action.payload.id)
              };
            return item;
          });
          return { ...el, questions: list };
        }
        return el;
      });
      return {
        ...state,
        current_course: { ...state.current_course, elements: elements_ }
      };

    case EDIT_NOTION_SUCCESS:
      let { elements: elements_edit_notion } = state.current_course;

      elements_edit_notion = elements_edit_notion.map((elem, index) => {
        if (elem.type === "MODULE") {
          return {
            ...elem,
            items: elem?.items?.map((item, index) => {
              if (item.id === action.payload.id) {

                let { attached_documents = {} } = action.payload;

                attached_documents = attached_documents === null ? {} : attached_documents
                return {
                  ...action.payload, attached_documents: [...item.attached_documents, attached_documents]
                };
              }
              return item;
            })
          };
        }
        return elem;
      });
      return {
        ...state,
        isLoading: false,
        current_course: {
          ...state.current_course,
          elements: elements_edit_notion
        }
      };

    case DELETE_MODULE:
      return { ...state, errors: [], isLoading: true };
    case DELETE_MODULE_SUCCESS:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.filter(
            e => e.id !== action.payload.id
          )
        },
        isLoading: false
      };
    case DELETE_MODULE_FAILED:
      return { ...state, isLoading: false, errors: action.payload };

    case DELETE_DOCUMENTS:
      return { ...state, isLoading: true };
    case DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.filter(
            e => e.id !== action.payload.id
          )
        },
        isLoading: false
      };
    case DELETE_DOCUMENTS_FAILED:
      return { ...state, isLoading: false, errors: action.payload };

    case DELETE_EVALUATION:
      return { ...state, errors: null, isLoading: true };
    case DELETE_EVALUATION_SUCCESS:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.filter(
            e => e.id !== action.payload.id
          )
        },
        isLoading: false
      };
    case DELETE_EVALUATION_FAILED:
      return { ...state, errors: action.payload, isLoading: false };

    case ADD_ATTACHED_DOCUMENT:
      return { ...state, isLoading: true };
    case ADD_ATTACHED_DOCUMENT_FAILED:
      return { ...state, isLoading: false };

    case EDIT_MODULE:
      return {
        ...state,
        isLoading: true
      };

    case EDIT_MODULE_SUCCESS:

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.map((elem, index) => {
            if (elem.id === action.payload.id) {
              return {
                ...elem,
                title: action.payload.title
              };
            }
            return elem;
          })
        },

        isLoading: false
      };


    case EDIT_ITEM_COURSE_SUCCESS:

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.map((elem, index) => {
            if (elem.id === action.payload.id && action.payload.type === elem.type) {
              return {
                ...elem,
                title: action.payload.title,
                published: action.payload.published,
              };
            }
            return elem;
          })
        },

        isLoading: false
      };

    case EDIT_COURSE:

      return { ...state, isLoading: true };
    case EDIT_COURSE_SUCCESS:

      return {
        ...state,
        isLoading: false,
        current_course: {
          ...state.current_course,
          title: action.payload.title,
          image_path: action.payload.image_path,
          description: action.payload.description,
          category: action.payload.category,
        }
      };

    case EDIT_DOCUMENTS_ELEMENT_SUCCESS:

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.map((elem, index) => {
            if (elem.id === action.payload._id) {
              return {
                ...elem,
                title: action.payload.documents_element.title,
                description: action.payload.documents_element.description,
              };
            }
            return elem;
          })
        },

        isLoading: false
      };

    case DELETE_GAME_SUCCESS:
      const _elements = [...state.current_course.elements.filter(
        e => {
          return e.id != action.payload.id
        }
      )]
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: _elements
        },
        is_loading: false
      };

    case UP_DOWN_ELEMENT_COURSE:

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: action.payload
        }
      };


    case EDIT_ITEM_COURSE_FAILED:

      return { ...state, isLoading: false };

    case DELETE_QUIZ:
      return { ...state, isLoading: true };
    case DELETE_QUIZ_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_QUIZ_FAILED:
      return { ...state, isLoading: false };

    case EDIT_QUIZ_SUCCESS:
      return { ...state, last_update: +new Date() };

    case ADD_ELEMENT:
      return { ...state, current_course: { ...state.current_course, elements: [...state.current_course.elements, action.payload] } };

    case ADD_ELEMENT_COURSE:
      return { ...state, isLoading: true };
    case ADD_ELEMENT_COURSE_SUCCESS:
      return { ...state, isLoading: false };
    case ADD_ELEMENT_COURSE_FAILED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
