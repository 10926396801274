import {
  LOAD_ALL_COURSES,
  LOAD_ALL_COURSES_SUCCESS,
  LOAD_ALL_COURSES_ERROR,
  LOAD_ALL_CATEGORIES,
  LOAD_ALL_CATEGORIES_SUCCESS,
  LOAD_ALL_CATEGORIES_FAILED,
  LOAD_COURSE_BY_ID,
  LOAD_COURSE_BY_ID_SUCCESS,
  LOAD_COURSE_BY_ID_FAILED,
  LOAD_ALL_COMMENTS,
  LOAD_ALL_COMMENTS_SUCCESS,
  LOAD_ALL_COMMENTS_FAILED,
  LOAD_COURSE_ELEMENTS_SUCCESS,
  LOAD_COURSE_ELEMENTS_FAILED,
  LOAD_COURSE_ELEMENTS,
  CLONE_COURSE_ELEMENTS,
  CLONE_COURSE_ELEMENTS_SUCCESS,
  CLONE_COURSE_ELEMENTS_FAILED,
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILED, COURSE_CANNOT_DELETED
} from "./action-definitions";

export const loadAllCourses = CoursesData => ({
  type: LOAD_ALL_COURSES,
  payload: { CoursesData }
});

export const loadAllCoursesSuccess = cours => ({
  type: LOAD_ALL_COURSES_SUCCESS,
  payload: cours
});

export const loadAllCoursesError = errors => ({
  type: LOAD_ALL_COURSES_ERROR,
  payload: errors
});

export const loadAllComments = Comments => ({
  type: LOAD_ALL_COMMENTS,
  payload: { Comments }
});

export const loadAllCommentSuccess = Comment => ({
  type: LOAD_ALL_COMMENTS_SUCCESS,
  payload: Comment
});

export const loadAllCommentsError = errors => ({
  type: LOAD_ALL_COMMENTS_FAILED,
  payload: errors
});

export const loadAllCategories = coursCategory => ({
  type: LOAD_ALL_CATEGORIES,
  payload: { coursCategory }
});

export const loadAllCategoriesSuccess = Category => ({
  type: LOAD_ALL_CATEGORIES_SUCCESS,
  payload: Category
});

export const loadAllCategoriesFailed = errors => ({
  type: LOAD_ALL_CATEGORIES_FAILED,
  payload: errors
});

export const loadCourseById = id => ({
  type: LOAD_COURSE_BY_ID,
  payload: { id }
});

export const loadCourseByIdSuccess = course => ({
  type: LOAD_COURSE_BY_ID_SUCCESS,
  payload: course
});

export const loadCourseByIdFailed = errors => ({
  type: LOAD_COURSE_BY_ID_FAILED,
  payload: errors
});

export const loadCourseElements = course_id => ({
  type: LOAD_COURSE_ELEMENTS,
  payload: { course_id }
});

export const loadCourseElementsSuccess = elements => ({
  type: LOAD_COURSE_ELEMENTS_SUCCESS,
  payload: elements
});

export const loadCourseElementsFailed = errors => ({
  type: LOAD_COURSE_ELEMENTS_FAILED,
  payload: errors
});

export const CloneCourseElements = (course, callback) => ({
  type: CLONE_COURSE_ELEMENTS,
  payload: { course, callback }
});

export const CloneCourseElementsSuccess = course => ({
  type: CLONE_COURSE_ELEMENTS_SUCCESS,
  payload: course
});

export const CloneCourseElementsFailed = errors => ({
  type: CLONE_COURSE_ELEMENTS_FAILED,
  payload: errors
});



export const deleteCourse = ( id, callback, dispatchError) => ({
  type: DELETE_COURSE,
  payload: {   id, callback, dispatchError }
});

export const deleteCourseSuccess = ( id) => ({
  type: DELETE_COURSE_SUCCESS,
  payload: {   id }
});

export const deleteCourseFailed = errors => ({
  type: DELETE_COURSE_FAILED,
  payload: errors
});

export const coursesCannotDelete = course => ({
  type: COURSE_CANNOT_DELETED,
  payload: course
});
