import { CONFIG_GET_COLORS_SUCCESS } from "./action-definitions";
import { LOGOUT_USER } from "../login/action-definitions";
import background from "../../modules/images/background1.jpeg";
import background2 from "../../modules/images/background2.jpeg";
import background3 from "../../modules/images/background3.jpeg";
import background4 from "../../modules/images/background4.jpeg";
import mainImage from "../../modules/Participant/Participant-course-list/assets/header_bg2.png";

// Initial state
const INIT_STATE = {
  "logo": "https://www.nowedge.io/images/nowedge-color.svg",
  "primary-color": "#803BE2",
  "secondary-color": "#50B0FF",
  "client_id": 1,
  "background_image1": background,
  "background_image2": background2,
  "background_image3": background3,
  "background_image4": background4,
  "main_image": mainImage,
  "favicon_path": "https://www.nowedge.io/images/nowedge-color.svg"
}




const Config = (state = INIT_STATE, action) => {
  console.log('Config reducer action:', action);
  switch (action.type) {
    case CONFIG_GET_COLORS_SUCCESS:
      const config = action.payload ? action.payload : state;
      const updatedConfig = {
        ...config,
        'client_id': config['client_id'] !== null ? config['client_id'] : 1,
        'logo': config['logo'] !== null ? config['logo'] : 'https://www.nowedge.io/images/nowedge-color.svg',
        'primary-color': config['primary-color'] !== null ? config['primary-color'] : '#803BE2',
        'secondary-color': config['secondary-color'] !== null ? config['secondary-color'] : '#50B0FF',
        'background_image1': config['background_image1'] !== null ? config['background_image1'] : background,
        'background_image2': config['background_image2'] !== null ? config['background_image2'] : background2,
        'background_image3': config['background_image3'] !== null ? config['background_image3'] : background3,
        'background_image4': config['background_image4'] !== null ? config['background_image4'] : background4,
        'main_image': config['main_image'] !== null ? config['main_image'] : mainImage,
        'favicon_path': config['favicon'] !== null ? config['favicon'] : 'https://www.nowedge.io/images/nowedge-color.svg',
      };

      return updatedConfig;
    case LOGOUT_USER:
      console.log('Config reducer - LOGOUT_USER');
      return state;
    default:
      return state;
  }
};


export default Config;
