import React, { Component } from 'react';
import "./index.css";
import icon_check from "./assets/icon-check.svg";
import LargeButton from "../../../../../components/LargeButton";
import { Translation } from 'react-i18next';

class SessionCodeModal extends Component {

  handleCopyButtonClick = async () => {
    await navigator.clipboard.writeText(this.props.code);
  }

  render() {
    const { isOpen, code, onClose } = this.props;

    let class_name = "session_code_modal_wrapper ";
    class_name += isOpen ? "session_code_modal_wrapper_open" : "";

    return (
      <Translation>
        { t =>
          <>

            <div className={class_name}>
              <div className="NE_code_session_modal">
                <div className="header">
                  <span className="btn_close" onClick={() => { onClose(); }}>X</span>
                </div>

                <div className="body">
                  <img src={icon_check} alt="Success" />

                  <p>{t("moderator.training-sessions.statics.sessions-modal.session_code_title")}</p>

                  <div className="session_code">
                    <p>{code}</p>
                  </div>

                  <LargeButton button="dark" value={t("moderator.training-sessions.statics.sessions-modal.session_code_copy")} 
                  onClick={() => { this.handleCopyButtonClick(); }} />
                </div>
              </div>
            </div>
          </>
        }
      </Translation>
    );
  }
}

export default SessionCodeModal;