import React, {Component, useState} from "react";
import './index.css'
// import addDocument from './assets/add-document.svg'
import icon from './assets/icon.svg';


const CollapseCardContainer  = (props)=>{
    const {title,children ,isOpen= true} = props;
    const [is_open, setIsOpen] = useState(isOpen);


    const toggleCollapse = () => {
        setIsOpen(prv => !prv);
    }

    let class_name = "NE_collapsable_cards_container ";
    class_name += is_open ? "NE_collapsable_cards_container_open" : "";

    return(
            <div className={class_name}>
                <div className="header">
                    <div className="title">
                        <img src={icon} alt="Container icon" />
                        <h2>{title}</h2>
                    </div>
                    <span className="btn_collapse" onClick={() => {toggleCollapse()}} />
                </div>

                <div className="body">
                    {(children && children.length > 0) && children.map((child,index) => {
                        return (
                            <div key={index} className="body_element">
                                {child}
                            </div>
                        );
                    })}

                    {(children && !children.length) && <div className="body_element">{children}</div>}
                </div>
            </div>
        )
}
export default CollapseCardContainer;
