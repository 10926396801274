import {
  LOAD_ALL_COURSES,
  LOAD_ALL_COURSES_SUCCESS,
  LOAD_ALL_COURSES_ERROR,
  LOAD_LAST_COMMENT_SUCCESS,
  SUBSCRIBE_TRAINING_SESSION,
  SUBSCRIBE_TRAINING_SESSION_SUCCESS,
  SUBSCRIBE_TRAINING_SESSION_ERROR
} from "./action-definitions";

// Initial state
const INIT_STATE = {
  is_loading: false,
  courses_list: [],
  last_comments: [],
  trainingSession: null
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_ALL_COURSES:
      return { ...state, errors: [], is_loading: true };

    case LOAD_ALL_COURSES_SUCCESS:
      return { ...state, courses_list: [...action.payload], is_loading: false };

    case LOAD_ALL_COURSES_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    case LOAD_LAST_COMMENT_SUCCESS:
      return { ...state, last_comments: [...action.payload] };

    case SUBSCRIBE_TRAINING_SESSION:
      return { ...state, errors: [], is_loading: true };

    case SUBSCRIBE_TRAINING_SESSION_SUCCESS:
      return {
        ...state,
        courses_list: [...state.courses_list, action.payload],
        is_loading: false
      };

    case SUBSCRIBE_TRAINING_SESSION_ERROR:
      return { ...state, errors: action.payload, is_loading: false };

    default:
      return { ...state };
  }
};

export default reducer;
