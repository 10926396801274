import {all, fork, put, takeLatest} from "redux-saga/effects";
import {
    CLONE_COURSE_ELEMENTS,
    DELETE_COURSE,
    LOAD_ALL_CATEGORIES,
    LOAD_ALL_COMMENTS,
    LOAD_ALL_COURSES,
    LOAD_COURSE_ELEMENTS
} from "./action-definitions";
import {
    CloneCourseElementsSuccess,
    deleteCourseFailed,
    deleteCourseSuccess,
    loadAllCategoriesFailed,
    loadAllCategoriesSuccess,
    loadAllCommentSuccess,
    loadAllCoursesError,
    loadAllCoursesSuccess,
    loadCourseElementsSuccess
} from "./action-creators";
import {
    cloneElementsCourseService,
    deleteCourseService,
    loadAllCategories,
    loadAllCommentService,
    loadAllCourses,
    loadElementsCourseService
} from "../../../services/moderator.service";
import history from "../../../../utils/history";

/* Define the actions watchers start*/
export function* watchLoadAllCourses() {
    yield takeLatest(LOAD_ALL_COURSES, loadAllCoursesWorker);
}

export function* watchLoadAllCategories() {
    yield takeLatest(LOAD_ALL_CATEGORIES, loadAllCategoriesWorker);
}

export function* watchLoadAllComments() {
    yield takeLatest(LOAD_ALL_COMMENTS, loadAllCommentsWorker);
}

export function* watchLoadElementsCourse() {
    yield takeLatest(LOAD_COURSE_ELEMENTS, loadElementsCourseWorker);
}

export function* watchCloneElementsCourse() {
    yield takeLatest(CLONE_COURSE_ELEMENTS, cloneElementsCourseWorker);
}

export function* watchDeleteCourse() {
    yield takeLatest(DELETE_COURSE, deleteCourseWorker);
}

/* Define the actions watchers end*/

/* Define the actions workers */
function* loadAllCoursesWorker({payload}) {
    // Get admin data from payload
    // Attempt to call backend to load clients list
    try {
        const call_result = yield loadAllCourses();
        // Dispatch success
        yield put(loadAllCoursesSuccess(call_result.data));
    } catch (error) {
        yield put(loadAllCoursesError(["Cannot load Courses list."]));
    }
}

function* loadAllCategoriesWorker({payload}) {
    try {
        const call_result = yield loadAllCategories();
        yield put(loadAllCategoriesSuccess(call_result.data));
    } catch (error) {
        yield put(loadAllCategoriesFailed(["Cannot load categories"]));
    }
}

//get Comment
function* loadAllCommentsWorker({payload}) {
    try {
        const call_result = yield loadAllCommentService();
        yield put(loadAllCommentSuccess(call_result.data));
    } catch (error) {
    }
}

//get
function* loadElementsCourseWorker({payload: {course_id}}) {
    try {


        const call_result = yield loadElementsCourseService(course_id);
        yield put(loadCourseElementsSuccess(call_result.data));
    } catch (error) {
    }
}

function* cloneElementsCourseWorker({payload: {course, callback}}) {
    try {


        const call_result = yield cloneElementsCourseService(course);
        callback();
        yield put(CloneCourseElementsSuccess(call_result.data));
    } catch (error) {
    }
}


function* deleteCourseWorker({
                                 payload: {
                                     id, callback = () => {
                                     }, dispatchError = () => {
                                     }
                                 }
                             }) {
    try {
        yield deleteCourseService(id);
        yield put(deleteCourseSuccess(id));


        callback();
    } catch (e) {
        if (e.response.data.message === "Can't delete course element that is related to a session") {
            dispatchError(true);
        } else {
            dispatchError(false);
        }
        yield put(deleteCourseFailed("error"));
    }
}


/* Define the actions workers end */

function forwardTo(location) {
    history.push(location);
}

// Export the combined sagas
export default function* allSagas() {
    yield all([
        fork(watchLoadAllCourses),
        fork(watchLoadAllCategories),
        fork(watchLoadAllComments),
        fork(watchLoadElementsCourse),
        fork(watchCloneElementsCourse),
        fork(watchDeleteCourse)
    ]);
}
