export const LOAD_ALL_SESSIONS =
  "MODERATOR.TRAINING-SESSIONS.LOAD_ALL_SESSIONS";
export const LOAD_ALL_SESSIONS_SUCCESS =
  "MODERATOR.TRAINING-SESSIONS.LOAD_ALL_SESSIONS_SUCCESS";
export const LOAD_ALL_SESSIONS_ERROR =
  "MODERATOR.TRAINING-SESSIONS.LOAD_ALL_SESSIONS_ERROR";
export const CHANGE_CURRENT_SESSION_PROP_VALUE =
  "MODERATOR.TRAINING-SESSIONS.CHANGE_CURRENT_SESSION_PROP_VALUE";
export const CHANGE_CURRENT_SESSION_VALIDATION_PROP_VALUE =
  "MODERATOR.TRAINING-SESSIONS.CHANGE_CURRENT_SESSION_VALIDATION_PROP_VALUE";
export const CREATE_SESSION = "MODERATOR.TRAINING-SESSIONS.CREATE_SESSION";
export const CREATE_SESSION_SUCCESS =
  "MODERATOR.TRAINING-SESSIONS.CREATE_SESSION_SUCCESS";
export const CREATE_SESSION_ERROR =
  "MODERATOR.TRAINING-SESSIONS.CREATE_SESSION_ERROR";
export const SET_CURRENT_SESSION =
  "MODERATOR.TRAINING-SESSIONS.SET_CURRENT_SESSION";
export const UPDATE_SESSION = "MODERATOR.TRAINING-SESSIONS.UPDATE_SESSION";
export const UPDATE_SESSION_SUCCESS =
  "MODERATOR.TRAINING-SESSIONS.UPDATE_SESSION_SUCCESS";
export const UPDATE_SESSION_ERROR =
  "MODERATOR.TRAINING-SESSIONS.UPDATE_SESSION_ERROR";
export const DELETE_SESSION = "MODERATOR.TRAINING-SESSIONS.DELETE_SESSION";
export const DELETE_SESSION_SUCCESS =
  "MODERATOR.TRAINING-SESSIONS.DELETE_SESSION_SUCCESS";
export const DELETE_SESSION_ERROR =
  "MODERATOR.TRAINING-SESSIONS.DELETE_SESSION_ERROR";
export const RESET_SELECTED_SESSION_KPIS =
    "MODERATOR.TRAINING-SESSIONS.RESET_SELECTED_SESSION_KPIS";

export const LOAD_SELECTED_SESSION_KPIS =
  "MODERATOR.TRAINING-SESSIONS.LOAD_SELECTED_SESSION_KPIS";
export const LOAD_SELECTED_SESSION_KPIS_SUCCESS =
  "MODERATOR.TRAINING-SESSIONS.LOAD_SELECTED_SESSION_KPIS_SUCCESS";
export const LOAD_SELECTED_SESSION_KPIS_ERROR =
  "MODERATOR.TRAINING-SESSIONS.LOAD_SELECTED_SESSION_KPIS_ERROR";
export const CHANGE_STUDENT_ACTIVATION =
  "MODERATOR.TRAINING-SESSIONS.CHANGE_STUDENT_ACTIVATION";
export const CHANGE_STUDENT_ACTIVATION_SUCCESS =
  "MODERATOR.TRAINING-SESSIONS.CHANGE_STUDENT_ACTIVATION_SUCCESS";
export const CHANGE_STUDENT_ACTIVATION_ERROR =
  "MODERATOR.TRAINING-SESSIONS.CHANGE_STUDENT_ACTIVATION_ERROR";
export const CREATE_COMMENT = "MODERATOR.TRAINING-SESSIONS.CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS =
  "MODERATOR.TRAINING-SESSIONS.CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_ERROR =
  "MODERATOR.TRAINING-SESSIONS.CREATE_COMMENT_ERROR";
export const SET_SELECTED_SESSION =
  "MODERATOR.TRAINING-SESSIONS.SET_SELECTED_SESSION";
export const SET_SELECTED_DASHBOARD =
  "MODERATOR.TRAINING-SESSIONS.SET_SELECTED_DASHBOARD";
export const SET_CURRENT_DASHBOARD_SELECTED_ELEMENT =
  "MODERATOR.TRAINING-SESSIONS.SET_CURRENT_DASHBOARD_SELECTED_ELEMENT";
export const CLOSE_SESSION_CODE_MODAL =
  "MODERATOR.TRAINING-SESSIONS.CLOSE_SESSION_CODE_MODAL";
export const CLOSE_ALERT = "MODERATOR.TRAINING-SESSIONS.CLOSE_ALERT";
export const PUSH_ALERT = "MODERATOR.TRAINING-SESSIONS.PUSH_ALERT";
