import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  LOGIN_CHANGE_PROPS,
  LOGIN_FACEBOOK,
  LOGIN_FACEBOOK_SUCCESS,
  LOGIN_FACEBOOK_FAILED,
  LOGIN_GOOGLE,
  LOGIN_GOOGLE_SUCCESS,
  LOGIN_GOOGLE_FAILED,
  LOGIN_CHANGE_VALIDATION_PROPS
} from "./action-definitions";
import {
  CLOSE_ALERT,
  PUSH_ALERT
} from "../admin/client-details/action-definitions";

// Initial state
const INIT_STATE = {
  // Userinfo,
  email: "",
  password: "",
  code: "",
  is_loading: false,
  current_user: null,
  user: null,
  login_validation: {
    email: {
      is_valid: false,
      error: ""
    },
    password: {
      is_valid: false,
      error: ""
    }
  },
  alerts: []
};

// Reducer
const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, is_loading: true };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        current_user: action.payload,
        is_loading: false,
        error: null
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };
    case REGISTER_USER:
      return { ...state, is_loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, user: action.payload, is_loading: false, error: null };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, is_loading: false };
    case FORGET_PASSWORD:
      return { ...state, is_loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: true,
        is_loading: false,
        error: null
      };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, is_loading: false };
    case LOGIN_CHANGE_PROPS:
      return { ...state, [action.payload.propName]: action.payload.propValue };
    case LOGIN_CHANGE_VALIDATION_PROPS:
      return {
        ...state,
        login_validation: {
          ...state.login_validation,
          [action.payload.propName]: action.payload.propValue
        }
      };
    case LOGIN_FACEBOOK:
      return { ...state, is_loading: true };
    case LOGIN_FACEBOOK_SUCCESS:
      return {
        ...state,
        current_user: action.payload,
        is_loading: false,
        error: null
      };
    case LOGIN_FACEBOOK_FAILED:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };
    case LOGIN_GOOGLE:
      return { ...state, is_loading: true };
    case LOGIN_GOOGLE_SUCCESS:
      return {
        ...state,
        current_user: action.payload,
        is_loading: false,
        error: null
      };
    case LOGIN_GOOGLE_FAILED:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };
    case PUSH_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ]
      };
    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
      };
    case LOGOUT_USER:
      return { ...INIT_STATE };

    default:
      return { ...state };
  }
};

export default Auth;
