import { all, delay, fork, put, takeLatest, take } from "redux-saga/effects";
import {
    ADD_BUSINESS_GAME,
    ADD_COURSE,
    ADD_COURSE_DOCUMENTS_ELEMENT,
    ADD_DOCUMENT,
    ADD_EVALUATION,
    ADD_MODULE,
    ADD_NOTION,
    ADD_QUIZ,
    DELETE_DOCUMENTS,
    DELETE_EVALUATION,
    DELETE_GAME,
    DELETE_MODULE,
    DELETE_NOTION,
    DELETE_QUIZ, EDIT_COURSE,
    EDIT_DOCUMENTS_ELEMENT, EDIT_ITEM_COURSE,
    EDIT_MODULE,
    LOAD_ALL_CATEGORIES,
    LOAD_ALL_GAMES,
    LOAD_COURSE_CONTENT,
    PUBLISH
} from "./actions-definitions";
import {
    addBusinessGameFailed,
    addBusinessGameSuccess,
    addCourseDocumentsElementSuccess,
    addCourseFailed,
    addCourseSuccess,
    addDocumentFailed,
    addDocumentSuccess,
    addEvaluationFailed,
    addEvaluationSuccess,
    addModuleFailed,
    addModuleSuccess,
    addNotionFailed,
    addNotionSuccess,
    addQuizFailed,
    addQuizSuccess,
    closeAlert,
    deleteDocumentsFailed,
    deleteDocumentsSuccess,
    deleteEvaluationFailed,
    deleteEvaluationSuccess,
    deleteGameFailed,
    deleteGameSuccess,
    deleteModuleFailed,
    deleteModuleSuccess,
    deleteNotionFailed,
    deleteNotionSuccess,
    deleteQuizFailed,
    deleteQuizSuccess, editCourseSuccess,
    editDocumentsElementError,
    editDocumentsElementSuccess, editItemCourseSuccess,
    editModuleFailed,
    editModuleSuccess,
    elementAdded,
    loadAllCategoriesError,
    loadAllCategoriesSuccess,
    loadAllGamesError,
    loadAllGamesSuccess,
    loadCourseContentError,
    loadCourseContentSuccess,
    pushAlert
} from "./actions-creators";

import {
    addBusinessGameService,
    addCourseService,
    addDocumentsElement,
    addDocumentService,
    addEvaluationService,
    addModuleService,
    addNotionService,
    addQuizService,
    deleteDocumentsService,
    deleteEvaluationService,
    deleteGameService,
    deleteNotionService,
    deleteQuizService, editBusinessGameService, editCourseService,
    editDocumentService, editEvaluationService,
    editModuleService,
    loadAllCategories,
    loadAllGames,
    loadCourseContent
} from "../../../services/moderator.service";
import history from "../../../../utils/history";
import { uploadCourseImage, uploadDocument } from "../../../services/uploads.service";
import { uid } from "uid";
import i18n from "../../../../i18n";
import { isLoading, loadData } from "../edit-course/actions";

export function* showAlert(message = "", type = "success") {
    const alert_id = uid();
    yield put(pushAlert(alert_id, type, message));
    yield delay(3000);
    yield put(closeAlert(alert_id));
}

export function* watchLoadAllCategories() {
    yield takeLatest(LOAD_ALL_CATEGORIES, loadAllCategoriesWorker);
}

export function* watchAddCourse() {
    yield takeLatest(ADD_COURSE, addCourseWorker);
}
export function* watchEditCourse() {
    yield takeLatest(EDIT_COURSE, editCourseWorker);
}

export function* watchAddModule() {
    yield takeLatest(ADD_MODULE, addModuleWorker);
}

export function* watchDeleteNotion() {
    yield takeLatest(DELETE_NOTION, deleteNotionWorker);
}

export function* watchDeleteQuiz() {
    yield takeLatest(DELETE_QUIZ, deleteQuizWorker);
}

export function* watchDeleteEvaluation() {
    yield takeLatest(DELETE_EVALUATION, deleteEvaluationWorker);
}

export function* watchDeleteModule() {
    yield takeLatest(DELETE_MODULE, deleteModuleWorker);
}

export function* watchDeleteGame() {
    yield takeLatest(DELETE_GAME, deleteGameWorker);
}

export function* watchDeleteDocuments() {
    yield takeLatest(DELETE_DOCUMENTS, deleteDocumentsWorker);
}

export function* watchAddQuiz() {
    yield takeLatest(ADD_QUIZ, addQuizWorker);
}

export function* watchAddEvaluation() {
    yield takeLatest(ADD_EVALUATION, addEvaluationWorker);
}

export function* watchAddBusinessGame() {
    yield takeLatest(ADD_BUSINESS_GAME, addBusinessGameWorker);
}

export function* watchAddNotion() {
    yield takeLatest(ADD_NOTION, addNotionWorker);
}

export function* watchAddDocument() {
    yield takeLatest(ADD_DOCUMENT, addDocumentWorker);
}

export function* watchEditDocument() {
    yield takeLatest(EDIT_DOCUMENTS_ELEMENT, editDocumentWorker);
}

export function* watchEditModule() {
    yield takeLatest(EDIT_MODULE, editModuleWorker);
}

export function* watchAddCourseDocumentsElement() {
    yield takeLatest(
        ADD_COURSE_DOCUMENTS_ELEMENT,
        addCourseDocumentsElementWorker
    );
}

export function* watchLoadCourseContent() {
    yield takeLatest(LOAD_COURSE_CONTENT, loadCourseContentWorker);
}

export function* watchLoadAllGames() {
    yield takeLatest(LOAD_ALL_GAMES, loadAllGamesWorker);
}

export function* watchPublishCourse() {
    yield takeLatest(PUBLISH, publishCourseWorker);
}

export function* watchEditItemCourse() {
    yield takeLatest(EDIT_ITEM_COURSE, watchEditItemCourseWorker);
}

function* publishCourseWorker() {
    yield showAlert("The Course was published successfully");
}

function* loadAllCategoriesWorker() {
    // Attempt to call backend to load all categories
    try {
        const call_result = yield loadAllCategories();

        // Dispatch success
        yield put(loadAllCategoriesSuccess(call_result.data));
    } catch (error) {
        const alert_id = uid();
        yield put(
            loadAllCategoriesError(
                alert_id,
                "danger",
                i18n.t("admin.client-details.messages.load_client_details_error")
            )
        );

        // Dispatch action to close alert after 3s delay
        yield delay(3000);
        yield put(closeAlert(alert_id));
    }
}

function* addCourseWorker({ payload: courseData }) {


    // Attempt to call backend to create a new course
    try {
        const image_upload_result = yield uploadCourseImage(
            courseData.title,
            courseData.image
        );
        const call_result = yield addCourseService(
            image_upload_result.data.message,
            courseData.title,
            courseData.description,
            courseData.category.id
        );

        // Dispatch success
        yield put(addCourseSuccess(call_result.data));
        yield put(elementAdded(false));
    } catch (error) {
        // console.log("--error error response--->",error.response.data.message)
        // console.log("--error error request--->",error.request)
        // console.log("--error error message--->",error.message)
        const alert_id = uid();
        yield put(addCourseFailed(alert_id, "danger", error.response.data.message));

        // Dispatch action to close alert after 3s delay
        yield delay(3000);
        yield put(closeAlert(alert_id));
    }
}
function* editCourseWorker({ payload: { courseData, callback } }) {
    try {
        let image_upload_result = { data: {} };
        if (courseData.img instanceof File) {
            image_upload_result = yield uploadCourseImage(courseData.title, courseData.img);
        } else {
            image_upload_result.data.message = courseData.img;
        }

        const call_result = yield editCourseService(
            courseData.id,
            image_upload_result.data.message,
            courseData.title,
            courseData.description,
            courseData.category.id
        );

        yield put(editCourseSuccess(call_result.data));

        callback();
    } catch (error) {

        const alert_id = uid();
        yield put(addCourseFailed(alert_id, "danger", error.response.data.message));
        yield delay(3000);
        yield put(closeAlert(alert_id));
    }
}

function* addCourseDocumentsElementWorker({ payload }) {
    const {
        course_id, element, element_order, callback = () => {
        }
    } = payload;

    // Attempt to call backend to create course documents element
    try {
        // Call service to upload documents
        const uploaded_documents = [];
        if (!element.attached_documents) throw new Error("ajouter des documents !");
        for (let i = 0; i < element.attached_documents.length; i++) {
            const call_result = yield uploadDocument(
                element.attached_documents.item(i)
            );

            if (call_result.data.status === "success") {
                uploaded_documents.push({
                    title: element.attached_documents.item(i).name,
                    file_path: call_result.data.message
                });
            }
        }

        const call_result = yield addDocumentsElement(
            course_id,
            element.title,
            element_order,
            uploaded_documents,
            element.description
        );

        // Dispatch success
        yield put(addCourseDocumentsElementSuccess(element._id, call_result.data));

        callback();
        // Dispatch alert
        yield put(elementAdded(true));
        const alert_id = uid();
        yield put(pushAlert(alert_id, "success", "Documents created successfully"));
        yield delay(3000);
        yield put(closeAlert(alert_id));
    } catch (error) {
        console.log("----error----", error)
        const alert_id = uid();
        yield put(
            addCourseFailed(
                alert_id,
                "danger",
                error.message
            )
        );

        // Dispatch action to close alert after 3s delay
        yield delay(3000);
        yield put(closeAlert(alert_id));


    }
}

function* loadCourseContentWorker({ payload: course_id }) {
    yield put(isLoading());
    // Attempt to call backend to load targeted course
    try {
        const call_result = yield loadCourseContent(course_id);

        // Prepare course data to be injected in the state
        let current_course = {
            title: call_result.data.course.title,
            category: call_result.data.course.category,
            description: call_result.data.course.description,
            image: call_result.data.course.image_path,
            elements: [],
            id: call_result.data.course.id,
            number_of_training_sessions: call_result.data.number_of_training_sessions
        };
        console.log("call_result", call_result);
        call_result.data.content.map(element => {
            switch (element.reference_object) {
                case "documents":
                    current_course.elements.push({
                        id: element.id,
                        title: element.title,
                        type: "DOCUMENTS",
                        attached_documents: element.attached_documents,
                        element_order: element.element_order,
                        published: element.published,
                        description: element.description
 
                    });
                    break;

                case "business_game":
                    current_course.elements.push({
                        course_id: element.course_id,
                        element_order: element.element_order,
                        game_id: element.game_id,
                        id: element.id,
                        title: element.title,
                        description: "",
                        type: "BUSINESS_GAME",
                        published: element.published,
                        name: element.game_name
                    });
                    break;
                case "evaluation":
                    current_course.elements.push({
                        course_id: element.course_id,
                        element_order: element.element_order,
                        id: element.id,
                        title: element.title,
                        description: "",
                        questions: element.questions,
                        type: "EVALUATION",
                        published: element.published
                    });
                    break;
                case "module":
                    current_course.elements.push({
                        course_id: element.course_id,
                        element_order: element.element_order,
                        id: element.id,
                        title: element.title,
                        description: "",
                        items: [...element.quizzes, ...element.notions].sort((a, b) =>
                            a.element_order > b.element_order ? 1 : -1
                        ),
                        type: "MODULE",
                        published: element.published
                    });
                    break;
                default:
                    current_course.elements.push(element);
                    break;
            }
        });

        // Dispatch success
        yield put(loadCourseContentSuccess(current_course));
        yield put(loadData(current_course));
    } catch (error) {
        const alert_id = uid();
        yield put(
            loadCourseContentError(
                alert_id,
                "danger",
                i18n.t("admin.client-details.messages.load_client_details_error")
            )
        );

        // Dispatch action to close alert after 3s delay
        yield delay(3000);
        yield put(closeAlert(alert_id));
    }
}

function* addModuleWorker({
    payload: {
        _id,
        course_id,
        title,
        description,
        element_order,
        callback = () => {
        }
    }
}) {
    try {
        const call_result = yield addModuleService(
            course_id,
            title,
            description,
            element_order
        );

        yield put(addModuleSuccess(_id, call_result.data));

        callback();
    } catch (e) {
        yield put(addModuleFailed("error"));
    }
}


function* watchEditItemCourseWorker({ payload }) {

    const {
        item,
        type
    } = payload;

    const {
        id,
        title,
        description,
        element_order,
        published
    } = item;

    try {


        switch (type.toLowerCase()) {
            case "module":
                yield editModuleService(
                    id,
                    title,
                    description ?? "",
                    element_order, published);
                break;
            case "document" || "documents":
                yield editDocumentService(
                    id,
                    title,
                    "",
                    element_order,
                    description ?? "",
                    published
                );
                break;
            case "evaluation" || "Evaluation":
                yield editEvaluationService(
                    id,
                    title,
                    description ?? "",
                    element_order,
                    published
                );
                break;
            case "business_game":
                yield editBusinessGameService(
                    id,
                    element_order,
                    published
                );
                break;
        }

        yield put(editItemCourseSuccess({
            id,
            title,
            published,
            type: item.type
        }));


    } catch (error) {
        yield put(editModuleFailed(error));
    }
}

function* addNotionWorker({
    payload: {
        Notion, callback = () => {
        }
    }
}) {
    try {
        const uploaded_documents = [];
        const docs = Notion.doc; //Object.values(Notion.doc)

        for (let i = 0; i < docs.length; i++) {
            const call_result = yield uploadDocument(docs[i]);

            if (call_result.data.status === "success") {
                uploaded_documents.push({
                    title: docs[i].name,
                    file_path: call_result.data.message
                });
            }
        }

        const call_result = yield addNotionService(
            Notion.module_id,
            Notion.title,
            Notion.media,
            Notion.element_order,
            Notion.description,
            uploaded_documents
        );

        yield put(addNotionSuccess(Notion._id, call_result.data));
        yield put(elementAdded(true));

        callback();
    } catch (e) {

        yield put(addNotionFailed("error"));
    }
}

function* deleteNotionWorker({
    payload: {
        module_id, id, callback = () => {
        }
    }
}) {
    try {
        yield deleteNotionService(id);

        yield put(deleteNotionSuccess(module_id, id));

        callback();
    } catch (e) {

        yield put(deleteNotionFailed("error"));
    }
}

function* deleteQuizWorker({
    payload: {
        module_id, id, callback = () => {
        }
    }
}) {
    try {
        yield deleteQuizService(id);

        yield put(deleteQuizSuccess(module_id, id));

        callback();
    } catch (e) {

        yield put(deleteQuizFailed("error"));
    }
}

function* deleteEvaluationWorker({
    payload: {
        _id, id, callback = () => {
        }
    }
}) {
    try {
        if (id) yield deleteEvaluationService(id);

        yield put(deleteEvaluationSuccess(_id, id));

        callback();
    } catch (e) {

        yield put(deleteEvaluationFailed("error"));
    }
}

function* deleteModuleWorker({ payload: { id } }) {
    try {
        // yield deleteModuleService(id);
        //
        yield put(deleteModuleSuccess(id));
    } catch (e) {

        yield put(deleteModuleFailed("error"));
    }
}

function* deleteGameWorker({ payload: { id, _id } }) {
    try {
        yield deleteGameService(id);

        yield put(deleteGameSuccess(id));

        // yield put(loadCourseContent(module.course_id));
    } catch (e) {

        yield put(deleteGameFailed("error"));
    }
}

function* deleteDocumentsWorker({ payload: { id, _id } }) {
    try {
        yield deleteDocumentsService(id);

        yield put(deleteDocumentsSuccess(_id));
    } catch (e) {

        yield put(deleteDocumentsFailed("error"));
    }
}

function* addQuizWorker({
    payload: {
        List, module, index, callback = () => {
        }
    }
}) {
    try {
        const questions = [];

        List.forEach((question, ind) => {
            const item = {
                title: question.question,
                element_order: ind,
                options: [],
                justification: question.justification
            };

            question.items.forEach((opt, i) => {
                item.options.push({
                    content: opt.text,
                    is_correct: opt.question_option ? 1 : 0,
                    element_order: i
                });
            });
            questions.push(item);
        });

        const call_result = yield addQuizService(
            module.id,
            "Quiz",
            index,
            questions
        );

        yield put(addQuizSuccess(module._id, call_result.data));
        yield put(elementAdded(true));
        callback();
    } catch (e) {

        yield put(addQuizFailed("error"));
    }
}

function* addEvaluationWorker({
    payload: {
        element, course_id, List, index, callback = () => {
        }
    }
}) {
    try {
        const questions = [];

        List.forEach((question, ind) => {
            const item = {
                title: question.question,
                element_order: ind,
                options: [],
                justification: question.justification
            };

            question.items.forEach((opt, i) => {
                item.options.push({
                    content: opt.text,
                    is_correct: opt.question_option ? 1 : 0,
                    element_order: i
                });
            });
            questions.push(item);
        });


        const call_result = yield addEvaluationService(
            course_id,
            "Evaluation",
            index,
            questions
        );

        yield put(addEvaluationSuccess({ ...element, List }, call_result.data));

        callback(List);
        // yield put(elementAdded(true));

        yield showAlert("Evaluation created successfully");
    } catch (e) {

        yield put(addEvaluationFailed("error"));
    }
}

function* addBusinessGameWorker({
    payload: {
        course_id,
        element_order,
        game_id,
        _id,
        client_affectation_id,
        stratedge_configuration_name,
        callback = () => {
        }
    }
}) {
    try {
        const call_result = yield addBusinessGameService({
            client_affectation_id,
            course_id,
            game_id,
            element_order,
            stratedge_configuration_name
        });

        yield put(addBusinessGameSuccess(_id, call_result.data));
        yield put(elementAdded(true));
        callback();
    } catch (e) {

        yield put(addBusinessGameFailed("error"));
    }
}

function* addDocumentWorker({ payload }) {
    const { document } = payload;
    try {
        const call_result = yield addDocumentService(
            document.course_id,
            document.title,
            document.media_path,
            document.element_order,
            document.attached_documents
        );


        const new_document = {
            course_id: call_result.data.course_id,
            title: call_result.data.title,
            media_path: call_result.data.media_path,
            attached_documents: call_result.data.attached_documents,
            element_order: call_result.data.element_order
        };
        yield put(elementAdded(true));
        yield put(addDocumentSuccess(new_document));
        // forwardTo('/');
    } catch (error) {
        yield put(addDocumentFailed("error"));
    }
}

function* editDocumentWorker({ payload }) {
    const {
        id,
        title,
        media_path,
        element_order,
        description,
        published
    } = payload;
    try {
        const call_result = yield editDocumentService(id,
            title,
            media_path,
            element_order,
            description, published);

        yield put(editDocumentsElementSuccess(id, {
            title,
            media_path,
            element_order,
            description, published
        }));

    } catch (error) {
        yield put(editDocumentsElementError("error"));
    }
}


function* editModuleWorker({ payload }) {
    const {
        id,
        title,
        description,
        element_order
    } = payload;
    try {
        const call_result = yield editModuleService(
            id,
            title,
            description,
            element_order);

        yield put(editModuleSuccess({
            id,
            title,
            description,
            element_order
        }));

    } catch (error) {

        console.log("---error---", error)
        yield put(editModuleFailed(error));
    }
}

function* loadAllGamesWorker() {
    // Attempt to call backend to load all categories
    try {
        const call_result = yield loadAllGames();

        // Dispatch success
        yield put(loadAllGamesSuccess(call_result.data));
    } catch (error) {
        const alert_id = uid();
        yield put(
            loadAllGamesError(
                alert_id,
                "danger",
                i18n.t("admin.client-details.messages.load_client_details_error")
            )
        );

        // Dispatch action to close alert after 3s delay
        yield delay(3000);
        yield put(closeAlert(alert_id));
    }
}

function forwardTo(location) {
    history.push(location);
}

// Export the combined sagas
export default function* allSagas() {
    yield all([
        fork(watchLoadAllCategories),
        fork(watchAddNotion),
        fork(watchDeleteNotion),
        fork(watchDeleteQuiz),
        fork(watchDeleteEvaluation),
        fork(watchDeleteModule),
        fork(watchDeleteGame),
        fork(watchDeleteDocuments),
        fork(watchAddModule),
        fork(watchAddDocument),
        fork(watchAddCourse),
        fork(watchAddCourseDocumentsElement),
        fork(watchLoadCourseContent),
        fork(watchLoadAllGames),
        fork(watchAddQuiz),
        fork(watchAddEvaluation),
        fork(watchAddBusinessGame),
        fork(watchPublishCourse),
        fork(watchEditDocument),
        fork(watchEditModule),
        fork(watchEditItemCourse),
        fork(watchEditCourse),

    ]);
}
