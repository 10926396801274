import React, {Component} from 'react';
import './index.css';

import history from "../../../utils/history";


const SwitchButton  = ({trainingSessionID,  isActiveSession, isActiveCourse}) => {
    const onClickCourse = () => {
        history.push(`/moderator/course/${trainingSessionID}`);
    }
    const onClickSession = () => {
        history.push(`/moderator/training-sessions/${trainingSessionID}`);
    }

    return (
        <div className="NE_switch_button">
            <ul>

                <li className={`switch_button_item ${isActiveCourse ? "switch_button_item_active" : ""}`} onClick={onClickCourse}>Cours</li>
                <li className={`switch_button_item ${isActiveSession ? "switch_button_item_active" : ""}`}  onClick={onClickSession} >Session</li>
            </ul>
        </div>
    );
}

export default SwitchButton;
