import React, { Component } from "react";
import { connect } from "react-redux";
import './index.css'
// import './modal.css'
import logo from '../../assets/logo.png';
import addImage from '../../assets/add_image.svg';
import NormalInput from "../../../components/NormalInput";
import NormalButton from "../../../components/NormalButton";
import Checkbox from "../../../components/Checkbox";
import { loginUser, changeLoginValues } from "../../../redux/login/action-creators";
import { Link } from 'react-router-dom';
import { urlFacebook, urlGoogle } from "../../../services/instance";
import { Translation } from 'react-i18next';
import Textarea from "../../../components/Textarea";
import { changeRegisterValues, completeProfileStep, registerUser } from "../../../redux/register/action-creators";
import ImageRegisterModal from "./components";


class completeProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_modal_open: false,
        }
    }

    handleImageModalClick = () => {
        this.setState({
            is_modal_open: true
        });
    }

    handleImageModalCancel = () => {
        this.setState({
            is_modal_open: false,
        });
    }

    handleValueChange = (propName, propValue) => {
        const { changeRegisterValues } = this.props
        changeRegisterValues(propName, propValue);
    }

    handleImageRegisterModal = (data) => {
        const { changeRegisterValues } = this.props
        changeRegisterValues("image", data);

    }

    handleValidSubmit = () => {
        this.props.completeProfileStep({ 'description': this.props.register.description, 'image': this.props.register.image });
    };

    render() {
        //get Variable from props
        const { description, image, alerts, onCloseAlert } = this.props.register;


        return (
            <Translation>
                {t =>
                    <>
                        <div className="split left">
                            <div className="centered">
                                <div className="NE_contain">
                                    <img className="NE_header_logo" src={this.props.Config.logo} alt="Logo" />
                                    <div>
                                        <h3 className="NE_header_">Complete profile</h3>
                                        <p className="NE_paragraph">Save it for later</p>
                                        <p className="NE_paragraph"><a href="/">Skip &gt;&gt;</a></p>
                                        <div className="NE_label">
                                            <div className="NE_header_addImg" onClick={this.handleImageModalClick}>
                                                <img src={addImage} alt="Add image icon" />
                                                <p className="NE_text" >Add a picture</p>
                                            </div>
                                        </div>
                                        <div className="NE_textArea">
                                            <Textarea value={description} isValid={true} label={"Description"} placeholder={"Tell us a little bit about you"} name='description' onChange={(event) => { this.handleValueChange("description", event.target.value) }} />
                                        </div>
                                        <div className="NE_label">
                                            <NormalButton button='dark' value='Save' onClick={this.handleValidSubmit} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="split right">
                            <div className="centered">
                            </div>
                        </div>
                        <ImageRegisterModal isOpen={this.state.is_modal_open} onCancel={this.handleImageModalCancel} onChange={this.handleImageRegisterModal} />
                    </>
                }
            </Translation>
        );
    }
}
const mapStateToProps = (state) => {
    const { register, Config } = state;
    return { register, Config };
};

const mapActionsToProps = {
    registerUser,
    completeProfileStep,
    changeRegisterValues
};
// export default Login;
export default connect(mapStateToProps, mapActionsToProps)(completeProfile);

