import Axios from "axios";
import { authHeaderKey } from "../../utils/utils";

/* Define Axios configs */
const axiosBaseConfig = {
  baseURL:
    process.env.REACT_APP_ENV === "prod"
      ? process.env.REACT_APP_BACKEND_URL_PROD
      : process.env.REACT_APP_BACKEND_URL,
  headers: {
    Accept: "application/json"
  }
};

// Export configured axios instances
export const axiosInstance = Axios.create(axiosBaseConfig);

export const getSecureAxiosInstance = () => {
  return Axios.create({
    ...axiosBaseConfig,
    headers: { ...axiosBaseConfig.headers, Authorization: authHeaderKey() }
  });
};
