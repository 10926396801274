import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  LOGIN_CHANGE_PROPS,
  LOGIN_FACEBOOK,
  LOGIN_FACEBOOK_SUCCESS,
  LOGIN_FACEBOOK_FAILED,
  LOGIN_GOOGLE,
  LOGIN_GOOGLE_SUCCESS,
  LOGIN_GOOGLE_FAILED,
  LOGOUT_USER,
  LOGIN_CHANGE_VALIDATION_PROPS,
  LOGIN_PUSH_ALERT,
  LOGIN_CLOSE_ALERT
} from "./action-definitions";
import {
  CLOSE_ALERT,
  PUSH_ALERT
} from "../admin/clients-list/action-definitions";

export const changeLoginValues = (propName, propValue) => ({
  type: LOGIN_CHANGE_PROPS,
  payload: { propName, propValue }
});

export const loginUser = userinfo => ({
  type: LOGIN_USER,
  payload: userinfo
});

export const loginUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});

export const loginUserFailed = (id, type, message) => ({
  type: LOGIN_USER_FAILED,
  payload: { id, type, message }
});

export const forgetPassword = username => ({
  type: FORGET_PASSWORD,
  payload: { username }
});

export const forgetPasswordSuccess = passwordResetStatus => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus
});

export const forgetPasswordFailed = error => ({
  type: FORGET_PASSWORD_FAILED,
  payload: error
});

export const loginFacebook = code => ({
  type: LOGIN_FACEBOOK,
  payload: code
});

export const loginFacebookSuccess = user => ({
  type: LOGIN_FACEBOOK_SUCCESS,
  payload: user
});

export const loginFacebookFailed = (id, type, message) => ({
  type: LOGIN_FACEBOOK_FAILED,
  payload: { id, type, message }
});

export const loginGoogle = code => ({
  type: LOGIN_GOOGLE,
  payload: code
});

export const loginGoogleSuccess = user => ({
  type: LOGIN_GOOGLE_SUCCESS,
  payload: user
});

export const loginGoogleFailed = (id, type, message) => ({
  type: LOGIN_GOOGLE_FAILED,
  payload: { id, type, message }
});

export const logout = () => ({
  type: LOGOUT_USER,
  payload: null
});

export const changeLoginValidationValues = (propName, propValue) => ({
  type: LOGIN_CHANGE_VALIDATION_PROPS,
  payload: { propName, propValue }
});

export const pushAlert = (id, type, message) => ({
  type: LOGIN_PUSH_ALERT,
  payload: { id, type, message }
});

export const closeAlert = id => ({
  type: LOGIN_CLOSE_ALERT,
  payload: id
});
