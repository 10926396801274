import { all, call, delay, fork, put, takeLatest } from "redux-saga/effects";
import { UPDATE_USER } from "./actions-definitions";
import { SecureUpdateProfil } from "../../services/auth.service";
import { uploadClientImage } from "../../services/uploads.service";
import {
  updateUserSuccess,
  updateUserFailed,
  pushAlert,
  closeAlert
} from "./actions-creators";
import { loginUserSuccess } from "../login/action-creators";
import { uid } from "uid";

function* showAlert(message = "", type = "success") {
  const alert_id = uid();
  yield put(pushAlert(alert_id, type, message));
  yield delay(3000);
  yield put(closeAlert(alert_id));
}

/* Define the actions watchers */
export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, UpdateUserWorker);
}

/* Define the actions workers */

function checkURL(url) {
  if (typeof url === "string")
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  // else if (typeof(url) === "string")
  //     return
  return false;
}

function* UpdateUserWorker({ payload }) {
  const { userinfo , callback = ()=>null } = payload;
  try {
    const imageTrue = checkURL(userinfo.avatar_path);
    const username = userinfo.email.substring(
      0,
      userinfo.email.lastIndexOf("@")
    );
    let data_image = userinfo.avatar_path;
    if (typeof data_image === "object") {
      data_image = yield uploadClientImage(username, userinfo.avatar_path);
      data_image = data_image.data.message;
    }

    const data = {
      avatar_path: data_image,
      description: userinfo.description,
      full_name: userinfo.full_name,
      ...(userinfo.newpassword && {
        password: userinfo.password,
        new_password: userinfo.newpassword
      }),
      language: userinfo.language
    };

    const data_result = yield SecureUpdateProfil(data);
    // localStorage.setItem('NowEdge.current_user_token', data_result.data.token);
    //
    // // Decode the access token
    // const {details: token_user} = jwtDecode(data_result.data.token);
    //
    // // Store the current Userinfo in the local storage
    localStorage.setItem(
      "NowEdge.current_user",
      JSON.stringify(data_result.data)
    );
    //
    //
    //
    yield put(loginUserSuccess(data_result.data));
    yield put(updateUserSuccess(data));
    callback();
    yield showAlert("Profile updated successfully");
  } catch (e) {
    //
    yield showAlert(e.response.data.message, "danger");
    yield put(updateUserFailed());
  }
}

function forwardTo(location) {
  // history.push(location);
}

// Export the combined sagas
export default function* allSagas() {
  yield all([fork(watchUpdateUser)]);
}
