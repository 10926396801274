import React,{Component} from "react";
import './index.css'
import OptionsPopup from "../OptionsPopup";
import {Translation} from "react-i18next";
import login from "../../modules/Login";

class ClientInfoCard extends Component {

    constructor(props) {
        super(props);
        this.edit = React.createRef();
    }

    state = {
      is_options_popup_open: false
    };


    componentDidMount() {
        window.addEventListener('click', this.backDropHandler);
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.backDropHandler);

    }

    backDropHandler =(e)=>{
        if (!this.edit.current?.contains(e.target)) {
           this.setState({
               is_options_popup_open:false
           })
        }
    }

    toggleOptionsPopup = () => {
      this.setState({
          is_options_popup_open: !this.state.is_options_popup_open
      });
    };

    render() {
        const {image,name,description,phone,address,options,onClick} = this.props;

        const {is_options_popup_open} = this.state;

        return <Translation>
            {t => {
                return <div className="NE_client_info_card" onClick={() => {onClick();}}>
                    <div className="header">
                    <span className="actions_btn" onClick={(e) => {
                        e.stopPropagation();

                        this.toggleOptionsPopup();
                    }} />
                        <img src={image} alt="Client profile" />
                        <h1>{name}</h1>
                        <p>{description}</p>
                        <div ref={this.edit} className={`actions_wrapper ${is_options_popup_open ? "actions_wrapper_open" : ""}`} >
                            <OptionsPopup onClick={this.toggleOptionsPopup} options={options} />
                        </div>
                    </div>

                    <div className="body">
                        <div className="phone">
                            <p>{phone}</p>
                        </div>

                        <div className="address">
                            <p>{address}</p>
                        </div>
                    </div>
                </div>
            }}
        </Translation>
    }
}
export default ClientInfoCard;
