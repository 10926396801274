import React, { Component,useEffect, useState } from "react";
import { Trans, Translation } from "react-i18next";
import './index.scss';
import useGetGame from "../../../utils/useGetGame";
import trophet from "../EtudiantBusinessGame/assets/Group_10279.svg";
import UserAffectation from "../../modules/UserAffectation";
const ElementProgressOverview = ({ element }) => {


    const { game, url } = useGetGame(element)
    console.log("----element---->>", element);
    console.log("🚀 ~ file: index.jsx:12 ~ ElementProgressOverview ~ game:", game)
    const [showAffectation, setShowAffectation] = useState(false);

    const element_percentage = element?.completed_participants !== undefined ? (element?.completed_participants * 100) : (element?.correct_answers * 100);

        const onCancel = () => {
        setShowAffectation(!showAffectation)
    }

    return <>
        <UserAffectation isOpen={showAffectation} onCancel={onCancel} />
        <Translation>
        {t => {
            let average_progress_title = "UNDEFINED";

            switch (element?.type) {
                case "BUSINESS_GAME":
                    average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.business_game_title");
                    break;

                case "DOCUMENT":
                    average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.document_title");
                    break;

                case "NOTION":
                    average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.notion_title");
                    break;

                case "EVALUATION":
                    average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.average_evaluation_score_title");
                    break;

                case "QUIZ":
                    average_progress_title = t("moderator.training-sessions.statics.session-dashboards.dashboard-global.average_quiz_score_title");
                    break;

                default:
                    break;
            }

            return (
                <div className="NE_element_progress_overview">
                    <Translation>{t =>
                        <div className="affectation-container">
                            {game?.title === "StratEdge" && (
                                <button className="btn_affectation" onClick={() => setShowAffectation(true)}>
                                    🧑🏻‍💼👩🏻‍💼{t("moderator.affecter_user")}
                   
                                </button>
                            )}
                        </div>}</Translation>
                    <div class="NE_business_game_content_content">
                        
                        {game?.title && <div class="NE_business_game_content">

                            <img src={trophet} alt="" />
                            <h1></h1>
                            <Translation>
                                {t =><>
                             <h1></h1>
                            <div class="textsize"><p></p></div>
                                    <a  href={url} target="_blank" style={{ textDecoration: 'none' }}>
                                <p>{t("moderator.go_to_page")}</p>
                                    </a>

                                </>}

                            </Translation>
                         </div>}
                        {element && <div className="average_progress">
                            <div className="progress_infos">
                                <p className="title"> {element?.title}</p>
                                <p className="percentage">{element_percentage}%</p>
                            </div>
                            <div className="progress_bar">
                                <div className="progress_level" style={{ width: `${element_percentage}%` }} />
                            </div>
                        </div>
                        }
                    </div>
                    {(element?.type === "QUIZ" || element?.type === "EVALUATION") && <>
                        {element?.questions.map(question => {
                            const question_percentage = Math.floor(question.correct_answers * 100);

                            return (
                                <div key={question.id} className="question_progress">
                                    <p className="question_number">{question.title}</p>
                                    <div className="progress_bar">
                                        <div className="progress_level" style={{ width: `${question_percentage}%` }} />
                                    </div>
                                    <p className="percentage">{question_percentage}%</p>
                                </div>
                                 );
                        })}
                    </>}
                  
                </div>
            );
        }}
    </Translation></>

}
export default ElementProgressOverview;