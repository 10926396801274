import React, {Component} from "react";
import "./index.css"
import addClient from './assets/icon-add-client.svg';
import  {Translation} from "react-i18next";

class AddClientCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const {onClick} = this.props;

        return (
            <Translation>
                {t =>
                    <>
                        <div className="NE_client_addition_card">
                            <div className="card_header">
                                <div className="card_action">
                                    <img src={addClient} onClick={onClick} alt="Addition icon" />
                                </div>
                            </div>

                            <div className="card_body">
                                <h1>{t("admin.clients-list.statics.add-client-card.title")}</h1>
                                {/*<p>Supporting description for the card goes here like a breeze.</p>*/}
                            </div>

                            <div className="card_footer">
                                <button onClick={onClick}>{t("admin.clients-list.statics.add-client-card.add_button")}</button>
                            </div>
                        </div>
                    </>
                }
            </Translation>

        )
        ;
    }
}
// Exporting the components
export default AddClientCard;
