import React,{Component} from "react";
import './index.css'

class ToggleButton extends Component{

    render() {
        const {checked,onChange,...rest} = this.props;
        return (
            <div className="NE_toggle_button">
                <label className="switch">
                    <input type="checkbox" checked={checked} onChange={onChange} {...rest} />
                    <span className="slider round" />
                </label>
            </div>
        );
    }
}
export default ToggleButton;