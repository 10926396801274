import {all, call, delay, fork, put, takeLatest} from "redux-saga/effects";
import { FORGET_PASSWORD } from "./action-definitions";
import {
  forgetPassword,
  forgetPasswordSuccess,
  forgetPasswordFailed,
  closeAlert, pushAlert
} from "./action-creators";
import { SecureResetUser } from "../../services/auth.service";
import {uid} from "uid";
// import {loginService} from "./services";

export function* showAlert(message = "", type = "success") {
  const alert_id = uid();
  yield put(pushAlert(alert_id, type, message));
  yield delay(3000);
  yield put(closeAlert(alert_id));
}

/* Define the actions watchers */
export function* watchUserReset() {
  yield takeLatest(FORGET_PASSWORD, userResetWorker);
}

/* Define the actions workers */
function* userResetWorker({ payload }) {
  // Get Userinfo infos and history from the payload
  const data = payload.userinfo;

  // Attempt to authenticate
  try {
    const call_result = yield SecureResetUser(data);
    yield put(forgetPasswordSuccess(call_result));
    yield showAlert("Password has been reset");

  } catch (error) {
    yield put(
      forgetPasswordFailed(["Votre email ou mot de passe sont erronés."])
    );
  }
}

// Export the combined sagas
export default function* allSagas() {
  yield all([fork(watchUserReset)]);
}
