import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translation } from "react-i18next";
import "./index.css";
import Header from "../../../components/Header";
import SessionsSidebar from "../../../components/SessionsSidebar";
import SwitchButton from "../../../components/SwitchButton";
import {
  changeCurrentSessionPropValue,
  changeCurrentSessionValidationPropValue,
  changeStudentActivation,
  closeAlert,
  closeSessionCodeModal,
  createComment,
  createSession,
  deleteSession,
  loadAllSessions,
  loadSelectedSessionKpis, resetSelectedSessionKpis,
  setCurrentDashboardSelectedElement,
  setCurrentSession,
  setSelectedDashboard,
  setSelectedSession,
  updateSession
} from "../../../redux/moderator/training-sessions/action-creators";
import SessionModal from "./components/SessionModal";
import { date_schema, session_title_schema } from "../../../validation/validations";
import ElementsList from "../../../components/ElementsList";
import ElementProgressOverview from "../../../components/ElementProgressOverview";
import SessionCodeModal from "./components/SessionCodeModal";
import NormalButton from "../../../components/NormalButton";
import StudentsList from "../../../components/StudentsList";
import StudentProgressOverview from "../../../components/StudentProgressOverview";
import CommentsTree from "../../../components/CommentsTree";
import moment from "moment";


const TrainingSessions = (props) => {




  const [is_session_modal_open, setIs_session_modal_open] = useState(false);
  const [session_modal_action, setSession_modal_action] = useState("CREATE");
  const [sessions_sidebar_search_string, setSessions_sidebar_search_string] = useState("");
  const [sessions_sidebar_filter_value, setSessions_sidebar_filter_value] = useState(null);
  const [active_element, setactive_element] = useState(null);
  const { current_user } = useSelector((state) => state.login);


  const {
    current_session_validation,
    current_session,
    selected_session,
    current_dashboard_selected_element,
    sessions_list,
    selected_dashboard,
    selected_session_kpis,
    is_loading,
    session_code_modal,
    alerts

  } = useSelector(state => state.moderator_training_sessions);
  const dispatch = useDispatch();

const moderatorTrainingSessions = useSelector(state => state.moderator_training_sessions);


  useEffect(() => {
    dispatch(setSelectedSession(null))
    dispatch(setSelectedDashboard(null))
    dispatch(resetSelectedSessionKpis())
  }, [props.match.params.trainingSessionID]);


  useEffect(() => {
    const { trainingSessionID } = props.match.params;

    dispatch(loadAllSessions(trainingSessionID));
  }, []);

  useEffect(() => {

    const disccussionId = new URLSearchParams(props.location.search.substring(1)).get(
      "disccussion"
    );
    const sessionId = new URLSearchParams(props.location.search.substring(1)).get(
      "session"
    );

    if (disccussionId && sessionId) {

      const currentSession = sessions_list.find(sl => "" + sl.id === "" + sessionId);
      if (currentSession) {
        handleSessionItemClick(currentSession)
        handleDashboardChange("DASHBOARD_DISCUSSION")
      }
    }

  }, [sessions_list]);

  useEffect(() => {

    const disccussionId = new URLSearchParams(props.location.search.substring(1)).get(
      "disccussion"
    );


    if (disccussionId) {

      const disc = selected_session_kpis?.dashboard_discussion.find(d => "" + d.id === disccussionId)
      if (disc) {
        handleDashboardElementChange(disc)
        setactive_element(disc)
      }
    }

  }, [selected_session_kpis?.dashboard_discussion]);

  const handleCloseAlert = (alert_id) => {
    // Dispatch action to close the targeted alert
    dispatch(closeAlert(alert_id));
  }

  const handleSessionsSidebarSearchStringChange = (search_string) => {
    setSessions_sidebar_search_string(search_string)
  }

  const handleSessionsSidebarFilterChange = (filter_value) => {
    setSessions_sidebar_filter_value(filter_value)
  }

  const handleCreateSessionClick = () => {
    dispatch(setCurrentSession({}));

    setIs_session_modal_open(true)
    setSession_modal_action("CREATE")
  }

  const handleSessionModalClose = () => {
    setIs_session_modal_open(false)
  }

  const handleCurrentSessionPropValueChange = (propName, propValue) => {
    // Dispatch action to change the current session
    dispatch(changeCurrentSessionPropValue(propName, propValue));
  }

  const validateCurrentSessionProp = (propName, propValue) => {

    switch (propName) {
      case 'title':
        const { error: title_error } = session_title_schema.validate(propValue);
        dispatch(changeCurrentSessionValidationPropValue(propName, {
          is_valid: !title_error,
          error: title_error ? title_error.message : ""
        }));
        break;

      case 'start_date':
        const { error: start_date_error } = date_schema.validate(propValue);
        dispatch(changeCurrentSessionValidationPropValue(propName, {
          is_valid: true,
          error: start_date_error ? start_date_error.message : ""
        }));
        break;

      case 'end_date':
        const { error: end_date_error } = date_schema.validate(propValue);
        dispatch(changeCurrentSessionValidationPropValue(propName, {
          is_valid: true,
          error: end_date_error ? end_date_error.message : ""
        }));
        break;

      default:
        break;
    }
  }

  const handleCurrentSessionPropChange = (propName, propValue) => {

    // Dispatch action to change current session prop value
    dispatch(changeCurrentSessionPropValue(propName, propValue));

    validateCurrentSessionProp(propName, propValue);
  };

  const isFormValid = () => {
    return current_session_validation?.title?.is_valid && current_session_validation?.start_date?.is_valid && current_session_validation?.end_date?.is_valid;
  };

  const handleCreateSession = () => {
    // Dispatch action to create new session
    const { trainingSessionID } = props.match.params;
    dispatch(createSession({
      ...current_session,
      course_id: parseInt(trainingSessionID)
    }));

    clearLocalState();
  }

  const handleUpdateSession = () => {
    // Dispatch action to update session
    let session = current_session;

    session.end_date = moment(new Date(session.end_date)).format("YYYY-MM-DD");
    session.start_date = moment(new Date(session.start_date)).format("YYYY-MM-DD");

    dispatch(updateSession(session));

    clearLocalState();
  }

  const handleSessionsSidebarEditOptionClick = (session) => {
    // Dispatch event to set the current session
    dispatch(setCurrentSession(session));

    // Validate current session
    validateCurrentSessionProp("title", session.title);
    validateCurrentSessionProp("start_date", session.start_date);
    validateCurrentSessionProp("end_date", session.end_date);

    setIs_session_modal_open(true)
    setSession_modal_action("UPDATE")

  }

  const handleSessionsSidebarDeleteOptionClick = (session) => {
    // Dispatch action to delete session
    dispatch(deleteSession(session.id));
  }

  const handleSessionItemClick = (session) => {
    // Dispatch action to set selected session
    dispatch(setSelectedSession(session));

    // Dispatch action to get selected session KPIs
    dispatch(loadSelectedSessionKpis(session.id));
  }

  const handleSessionCodeModalClose = () => {
    dispatch(closeSessionCodeModal());
  }

  const handleDashboardChange = (selected_dashboard) => {
    // Dispatch action to set selected dashboard
    dispatch(setSelectedDashboard(selected_dashboard));
  }

  const handleDashboardElementChange = (selected_element) => {
    // Dispatch action to set current dashboard selected element
    dispatch(setCurrentDashboardSelectedElement(selected_element));
  }

  const handleStudentActivationChange = (is_active) => {
    // Dispatch action to change current element activation
    dispatch(changeStudentActivation(selected_session.id, current_dashboard_selected_element.id, is_active));
  }

  const handleCopyButtonClick = async () => {
    await navigator.clipboard.writeText(selected_session.training_session_code);
  }

  const handleAddComment = (discussion_id, parent_id, content) => {
    // Dispatch action to create new comment
    dispatch(createComment(discussion_id, parent_id, content));

  }

  const clearLocalState = () => {
    setIs_session_modal_open(false)
    setSession_modal_action("CREATE")
    setSessions_sidebar_search_string("")
    setSessions_sidebar_filter_value(null)
  }


  // Get state variables


  // Filter sessions list based on search string
  let filtered_sessions_list = sessions_list.filter(session => session.title.toLowerCase().includes(sessions_sidebar_search_string.toLowerCase()));

  // Filter sessions list based on current filter value
  if (sessions_sidebar_filter_value) {
    if (sessions_sidebar_filter_value.code !== "ALL") {
      filtered_sessions_list = filtered_sessions_list.filter(session => session.status === sessions_sidebar_filter_value.code);
    }
  }

  return <Translation>
    {t => {
      return (
        <div className="training_sessions_page">
          <div className="page_header">
            <Header isLoading={is_loading} alerts={alerts} onCloseAlert={handleCloseAlert} />
            <div className="switch_sections_wrapper">
              <SwitchButton trainingSessionID={props.match.params.trainingSessionID}
                isActiveSession={true} />
            </div>
          </div>
          <div className="page_body">
            <div className="content">
              <SessionsSidebar
                sessionsList={filtered_sessions_list}
                selectedSession={selected_session}
                onSearchChange={handleSessionsSidebarSearchStringChange}
                onFilterChange={handleSessionsSidebarFilterChange}
                onEditSessionOptionClick={handleSessionsSidebarEditOptionClick}
                onDeleteSessionOptionClick={handleSessionsSidebarDeleteOptionClick}
                onCreateSessionClick={handleCreateSessionClick}
                onSessionItemClick={handleSessionItemClick}
                current_user={current_user}
              />

              <div className="session_dashboards">
                {selected_session && <>
                  <div className="header">
                    <div className="session_infos">
                      <h1>{selected_session.title}</h1>
                       <h4>{selected_session.start_date} - {selected_session.end_date}</h4>

                      <h3>{t("moderator.training-sessions.statics.session-dashboards.session_code_title")}: <span
                        onClick={handleCopyButtonClick}>{selected_session.training_session_code}</span>
                      </h3>
                    </div>

                    <div className="dashboards_tabs">
                      <ul className="tabs">
                        <li>
                          <NormalButton
                            button={selected_dashboard && selected_dashboard === "DASHBOARD_GLOBAL" ? "dark" : null}
                            value={t("moderator.training-sessions.statics.session-dashboards.tabs.dashboard_global_title")}
                            onClick={() => {
                              handleDashboardChange("DASHBOARD_GLOBAL")
                            }} />
                        </li>
                        <li>
                          <NormalButton
                            button={selected_dashboard && selected_dashboard === "DASHBOARD" ? "dark" : null}
                            value={t("moderator.training-sessions.statics.session-dashboards.tabs.dashboard")}
                            onClick={() => {
                              handleDashboardChange("DASHBOARD")
                            }} />
                        </li>
                        <li>
                          <NormalButton
                            button={selected_dashboard && selected_dashboard === "DASHBOARD_DISCUSSION" ? "dark" : null}
                            value={t("moderator.training-sessions.statics.session-dashboards.tabs.dashboard_discussion")}
                            onClick={() => {
                              handleDashboardChange("DASHBOARD_DISCUSSION")
                            }} />
                        </li>
                      </ul>

                    </div>
                  </div>

                  <div className="body">
                    {selected_dashboard && selected_dashboard === "DASHBOARD_GLOBAL" && <>
                      <ElementsList elements={selected_session_kpis?.global_dashboard}
                        onActiveElementChange={handleDashboardElementChange} />
                      {console.log("current_dashboard_selected_element",current_dashboard_selected_element)}
                      <ElementProgressOverview selected_session={selected_session} current_session={current_session} element={current_dashboard_selected_element} />
                    </>}

                    {selected_dashboard && selected_dashboard === "DASHBOARD" && <>
                      <StudentsList elements={selected_session_kpis?.dashboard}
                        onActiveElementChange={handleDashboardElementChange} />
                      <StudentProgressOverview element={current_dashboard_selected_element}
                        onActivationChange={handleStudentActivationChange} />
                    </>}

                    {selected_dashboard && selected_dashboard === "DASHBOARD_DISCUSSION" && <>
                      <ElementsList elements={selected_session_kpis?.dashboard_discussion}
                        onActiveElementChange={handleDashboardElementChange} active_element={active_element} />
                      <div className="comments_container">
                        {current_dashboard_selected_element && <>
                          <h1>{current_dashboard_selected_element?.comments_count} {t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.discussion_title")}</h1>
                          <CommentsTree
                            commentsList={current_dashboard_selected_element?.messages}
                            discussionId={current_dashboard_selected_element?.id}
                            onAddComment={handleAddComment} />
                        </>}
                      </div>
                    </>}
                  </div>
                </>}

              </div>
            </div>
          </div>

          <SessionModal
            isOpen={is_session_modal_open}
            currentSession={current_session}
            currentSessionValidation={current_session_validation}
            action={session_modal_action}
            isFormValid={isFormValid()}
            onChange={handleCurrentSessionPropChange}
            onSubmit={session_modal_action === "CREATE" ? handleCreateSession : handleUpdateSession}
            onClose={handleSessionModalClose}
          />

          <SessionCodeModal isOpen={session_code_modal?.is_open} code={session_code_modal?.code}
            onClose={handleSessionCodeModalClose} />
        </div>
      );
    }}
  </Translation>


}


export default TrainingSessions;
