import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "./index.css";
import Header from "../../../components/Header";
import Loader from "../../../components/Loader";
import { useDispatch } from "react-redux";

import profilePic from "./assets/profile-picture-2.png";
import { Translation } from "react-i18next";
const ParticipantEditProfile = () => {
  const dispatch = useDispatch();
  useEffect(() => {}, []);
  const { is_loading } = useSelector((state) => state.participant_points);
  return (
    <Translation>
      {
      t=>
      <>
      
    <div className="participant_points">
      <Loader isOpen={is_loading} />

      <div className="page_header">
        <Header user="participant" />
      </div>

      <div class="page_body_profile">
        <div class="content">
          <div class="NE_header_edit">
            <img src={profilePic} alt="" />
            <p>{t("participant.user-info.change_picture_text")}</p>
          </div>
          <div class="h">
            <div class="NE_normal_textarea NE_normal_textarea_success NE_normal_textarea_error">
              <label for="test">Description</label>
              <textarea id="test" placeholder="Placeholder"></textarea>
            </div>
          </div>
          <div class="h">
            <div class="NE_normal_input NE_normal_input_error NE_normal_input_success">
              <label for="test">{t("participant.user-info.fullname")}</label>
              <input
                type="text"
                id="test"
                placeholder="Placeholder"
                value="Value"
              />
            </div>
          </div>
          <div class="h">
            <div class="NE_normal_input NE_normal_input_error NE_normal_input_success">
              <label for="test">Email</label>
              <input
                type="text"
                id="test"
                placeholder="Placeholder"
                value="Value"
              />
            </div>
          </div>
          <div class="h">
            <div class="NE_normal_input NE_normal_input_error NE_normal_input_success">
              <label for="test">{t("participant.user-info.password")}</label>
              <input
                type="text"
                id="test"
                placeholder="Placeholder"
                value="Value"
              />
              <p class="change_password">{t("participant.user-info.password_change")}</p>
            </div>
          </div>
          <div class="h">
            <div class="NE_normal_input NE_normal_input_error NE_normal_input_success">
              <label for="test">{t("participant.user-info.password")}</label>
              <input
                type="text"
                id="test"
                placeholder="Placeholder"
                value="Value"
              />
            </div>
          </div>
          <div class="hh">
            <div class="h">
              <div class="h_">
                <div class="NE_normal_input NE_normal_input_error NE_normal_input_success">
                  <label for="test">{t("participant.user-info.new_password")}</label>
                  <input
                    type="text"
                    id="test"
                    placeholder="Placeholder"
                    value="Value"
                  />
                </div>
              </div>
              <div class="h_">
                <div class="NE_normal_input NE_normal_input_error NE_normal_input_success">
                  <label for="test">{t("participant.user-info.repeat_new_password")}</label>
                  <input
                    type="text"
                    id="test"
                    placeholder="Placeholder"
                    value="Value"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="hhh">
            <div class="hwidth">
              <button class="NE_button">{t("participant.user-info.cancel_button")}</button>
            </div>
            <div class="vwidth">
              <button class="NE_button NE_button_dark">{t("participant.user-info.save_button")}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
      }
    </Translation>
  );
};
export default ParticipantEditProfile;
