import React,{Component} from "react";
import './index.css'
import facebook_logo from './assets/Facebook.svg'
import google_logo from './assets/Google.svg'

class SocialButton extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        const {type,value,onClick} = this.props;

        let class_name = "NE_social_button ";
        let social_img = null;

        switch (type.toLowerCase()){
            case "facebook":
                class_name += "NE_facebook_social_button";
                social_img = facebook_logo;
                break;

            case "google":
                class_name += "NE_google_social_button";
                social_img = google_logo;
                break;

            default:
                break;
        }

        return (
            <div className={class_name} onClick={onClick}>
                <div className="social_logo">
                    <img src={social_img} alt="social platform logo"/>
                </div>
                <div className="social_text">
                    {value}
                </div>
            </div>
        );
    }
}
export default SocialButton