import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DocumentLine from "../DocumentLine";
import ReactHtmlParser from 'react-html-parser';

import "./index.css";

function getVideoId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
}
const addTargetToLinks = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');

  const links = doc.querySelectorAll('a');

  links.forEach((link) => {
    link.setAttribute('target', '_blank');
  });

  return doc.body.innerHTML;
};
const NotionItem = ({
  clickedItem,
  discussions,
  onClickItem,
  callbackfn = ""
}) => {
  const [notion, setNotion] = useState(false);

  const { trainingSession } = useSelector(
    state => state.participant_course_item
  );
  useEffect(() => {
    setNotion(
      trainingSession?.content
        ?.find(c => c.id === clickedItem?.parentId)
        ?.notions?.find(e => e.id === clickedItem?.id)
    );
  }, [clickedItem]);

  return (
    <div
      style={{
        flex: 3,
        display: "flex",
        paddingBottom: 100,
        marginLeft: 19,
        marginBottom: "0px"
      }}
      className="NE_notionItem_container"
    >
      <div className="NE_notionItem_header">
        {/*<h2 className="NE_notionItem_header_h2">{notion?.title}</h2>*/}
        {callbackfn()}
      </div>

      <div className="NE_notionItem_body_video">
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          {notion?.media_path && <iframe

              src={`https://www.youtube.com/embed/${getVideoId(notion?.media_path)}?rel=0`}
              frameBorder="0"
              title="YouTube video player"
              style={{
                  width: "100%",
                  height: 696
              }}
              allowFullScreen="allowFullscreen"
              mozallowfullscreen="mozallowFullscreen"
              msAllowFullscreen="msAllowFullscreen"
              oAllowFullscreen="oAllowFullscreen"
              webKitAllowFullscreen="webKitAllowFullscreen"
          />}
      </div>
      <div className="NE_notionItem_header_text">
        <h2>{notion?.title}</h2>
        <p>
          
          {ReactHtmlParser(addTargetToLinks(notion?.content))}</p>
        {notion?.attached_documents?.length >0 && <h2>Documents</h2>}

        {notion?.attached_documents?.map(document => (
          <DocumentLine
            type={""}
            title={document?.title}
            // size="31.1kb"
            fileUrl={document?.file_path}
          />
        ))}
      </div>
    </div>
  );
};

export { NotionItem };
