import React,{Component} from "react";
import './index.css';

class NormalInputWithActionButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {label,actionText,message,onChange,onActionClick,isValid,id,...rest} = this.props;

        let class_name = "NE_normal_input_with_action_button ";
        class_name+= isValid === false ? "NE_normal_input_with_action_button_error" : "";


        return (
            <div
                className={class_name}>
                <label htmlFor={id}>{label}</label>
               <div className="controller">
                   <input type="text" id={id} onChange={onChange} {...rest} />
                   {(isValid !== undefined) && <p>{message}</p>}
                   <button onClick={onActionClick}>{actionText}</button>
               </div>
            </div>
        );
    }
}
export default NormalInputWithActionButton;
