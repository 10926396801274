import React, {Component} from "react";
import {EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg"
import './index.css';

class DraftEditor extends Component{
    constructor(props){
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
        };
    }

    onEditorStateChange = (editorState) => {
        // console.log(editorState)
        this.setState({
            editorState,
        });
        this.props.getDataFromChild(editorState.getCurrentContent().getPlainText('\u0001'));
    };



    render(){
        const { editorState } = this.state;

        return <div className="NE_draft_editor NE_normal_textarea NE_normal_textarea_success NE_normal_textarea_error">
            <Editor
                editorState={editorState}
                onEditorStateChange={this.onEditorStateChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                }}
            />
        </div>
    }
}
export default DraftEditor;