import {
  LOAD_ALL_COURSES,
  LOAD_ALL_COURSES_SUCCESS,
  LOAD_LAST_COMMENT_SUCCESS,
  LOAD_ALL_COURSES_ERROR,
  SUBSCRIBE_TRAINING_SESSION,
  SUBSCRIBE_TRAINING_SESSION_SUCCESS,
  SUBSCRIBE_TRAINING_SESSION_ERROR
} from "./action-definitions";

export const loadAllCourses = CoursesData => ({
  type: LOAD_ALL_COURSES,
  payload: { CoursesData }
});

export const loadAllCoursesSuccess = cours => ({
  type: LOAD_ALL_COURSES_SUCCESS,
  payload: cours
});

export const loadLastCommentsSuccess = comments => ({
  type: LOAD_LAST_COMMENT_SUCCESS,
  payload: comments
});

export const loadAllCoursesError = errors => ({
  type: LOAD_ALL_COURSES_ERROR,
  payload: errors
});
export const subscribeTrainingSession = (code, callback) => ({
  type: SUBSCRIBE_TRAINING_SESSION,
  payload: { code, callback }
});

export const subscribeTrainingSessionSuccess = course => ({
  type: SUBSCRIBE_TRAINING_SESSION_SUCCESS,
  payload: course
});
export const subscribeTrainingSessionError = errors => ({
  type: SUBSCRIBE_TRAINING_SESSION_ERROR,
  payload: errors
});
