import {
    ADD_ATTACHED_DOCUMENT,
    ADD_ATTACHED_DOCUMENT_FAILED,
    ADD_ATTACHED_DOCUMENT_SUCCESS,
    ADD_ELEMENT,
    ADD_ELEMENT_COURSE,
    ADD_ELEMENT_COURSE_FAILED,
    ADD_ELEMENT_COURSE_SUCCESS,
    DELETE_ATTACHED_DOCUMENTS,
    DELETE_ATTACHED_DOCUMENTS_FAILED,
    DELETE_ATTACHED_DOCUMENTS_SUCCESS,
    DELETE_DOCUMENTS,
    DELETE_DOCUMENTS_FAILED,
    DELETE_DOCUMENTS_SUCCESS,
    DELETE_EVALUATION,
    DELETE_EVALUATION_FAILED,
    DELETE_EVALUATION_OPTION,
    DELETE_EVALUATION_SUCCESS,
    DELETE_MODULE,
    DELETE_MODULE_FAILED,
    DELETE_MODULE_SUCCESS,
    DELETE_NOTION,
    DELETE_NOTION_FAILED,
    DELETE_NOTION_SUCCESS,
    DELETE_QUIZ,
    DELETE_QUIZ_FAILED,
    DELETE_QUIZ_SUCCESS,
    EDIT_ATTACHED_DOCUMENT,
    EDIT_ATTACHED_DOCUMENT_FAILED,
    EDIT_ATTACHED_DOCUMENT_SUCCESS,
    EDIT_EVALUATION,
    EDIT_EVALUATION_FAILED,
    EDIT_EVALUATION_SUCCESS,
    EDIT_NOTION,
    EDIT_NOTION_FAILED,
    EDIT_NOTION_SUCCESS,
    EDIT_QUIZ,
    EDIT_QUIZ_FAILED,
    EDIT_QUIZ_SUCCESS,
    IS_LOADING,
    LOAD_DATA,
    UP_DOWN_ELEMENT_COURSE
} from "./action-types";

export const loadData = data => ({
    type: LOAD_DATA,
    payload: data
});

export const isLoading = (value = true) => ({
    type: IS_LOADING,
    payload: value
});

export const addAttachedDocument = (files, doc) => ({
    type: ADD_ATTACHED_DOCUMENT,
    payload: {files, doc}
});

export const addAttachedDocumentSuccess = (data, documents_id) => ({
    type: ADD_ATTACHED_DOCUMENT_SUCCESS,
    payload: {data, documents_id}
});

export const addAttachedDocumentFailed = error => ({
    type: ADD_ATTACHED_DOCUMENT_FAILED,
    payload: error
});

export const deleteAttachedDocuments = data => ({
    type: DELETE_ATTACHED_DOCUMENTS,
    payload: data
});

export const deleteAttachedDocumentsSuccess = data => ({
    type: DELETE_ATTACHED_DOCUMENTS_SUCCESS,
    payload: data
});

export const deleteAttachedDocumentFailed = error => ({
    type: DELETE_ATTACHED_DOCUMENTS_FAILED,
    payload: error
});

export const editAttachedDocuments = data => ({
    type: EDIT_ATTACHED_DOCUMENT,
    payload: data
});

export const editAttachedDocumentsSuccess = data => ({
    type: EDIT_ATTACHED_DOCUMENT_SUCCESS,
    payload: data
});

export const editAttachedDocumentFailed = error => ({
    type: EDIT_ATTACHED_DOCUMENT_FAILED,
    payload: error
});

export const editEvaluation = (
    list_add_question,
    list_add_question_option,
    list_edit_question,
    list_edit_question_option,
    list_delete_question_option,
    list_delete_question
) => ({
    type: EDIT_EVALUATION,
    payload: {
        list_add_question,
        list_add_question_option,
        list_edit_question,
        list_edit_question_option,
        list_delete_question_option,
        list_delete_question
    }
});

export const editEvaluationSuccess = data => ({
    type: EDIT_EVALUATION_SUCCESS,
    payload: data
});

export const editEvaluationFailed = error => ({
    type: EDIT_EVALUATION_FAILED,
    payload: error
});

export const deleteEvaluationOption = option => ({
    type: DELETE_EVALUATION_OPTION,
    payload: option
});

export const editNotion = notion => ({
    type: EDIT_NOTION,
    payload: notion
});

export const editNotionSuccess = notion => ({
    type: EDIT_NOTION_SUCCESS,
    payload: notion
});

export const editNotionFailed = error => ({
    type: EDIT_NOTION_FAILED,
    payload: error
});

export const editQuiz = (
    list_add_question,
    list_add_question_option,
    list_edit_question,
    list_edit_question_option,
    list_delete_question_option,
    list_delete_question,
    module
) => ({
    type: EDIT_QUIZ,
    payload: {
        list_add_question,
        list_add_question_option,
        list_edit_question,
        list_edit_question_option,
        list_delete_question_option,
        list_delete_question,
        module
    }
});

export const editQuizSuccess = data => ({
    type: EDIT_QUIZ_SUCCESS,
    payload: data
});

export const editQuizFailed = error => ({
    type: EDIT_QUIZ_FAILED,
    payload: error
});

export const deleteModule = id => ({
    type: DELETE_MODULE,
    payload: {id}
});

export const deleteModuleSuccess = id => ({
    type: DELETE_MODULE_SUCCESS,
    payload: {id}
});

export const deleteModuleFailed = errors => ({
    type: DELETE_MODULE_FAILED,
    payload: errors
});

export const deleteDocuments = (id, _id) => ({
    type: DELETE_DOCUMENTS,
    payload: {id, _id}
});

export const deleteDocumentsSuccess = id => ({
    type: DELETE_DOCUMENTS_SUCCESS,
    payload: {id}
});

export const deleteDocumentsFailed = errors => ({
    type: DELETE_DOCUMENTS_FAILED,
    payload: errors
});

export const deleteEvaluation = id => ({
    type: DELETE_EVALUATION,
    payload: {id}
});

export const deleteEvaluationSuccess = id => ({
    type: DELETE_EVALUATION_SUCCESS,
    payload: {id}
});

export const deleteEvaluationFailed = errors => ({
    type: DELETE_EVALUATION_FAILED,
    payload: errors
});

export const deleteQuiz = (module_id, id, callback) => ({
    type: DELETE_QUIZ,
    payload: {module_id, id, callback}
});

export const deleteQuizSuccess = (module_id, id) => ({
    type: DELETE_QUIZ_SUCCESS,
    payload: {module_id, id}
});

export const deleteQuizFailed = errors => ({
    type: DELETE_QUIZ_FAILED,
    payload: errors
});

export const deleteNotion = (module_id, id, course_id, callback) => ({
    type: DELETE_NOTION,
    payload: {module_id, id, course_id, callback}
});

export const deleteNotionSuccess = (module_id, id) => ({
    type: DELETE_NOTION_SUCCESS,
    payload: {module_id, id}
});

export const deleteNotionFailed = errors => ({
    type: DELETE_NOTION_FAILED,
    payload: errors
});

export const addElement = data => ({
    type: ADD_ELEMENT,
    payload: data
});


export const addElementCourse = data => ({
    type: ADD_ELEMENT_COURSE,
    payload: {data}
});

export const addElementCourseSuccess = data => ({
    type: ADD_ELEMENT_COURSE_SUCCESS,
    payload: data
});
export const addElementCourseFailed = errors => ({
    type: ADD_ELEMENT_COURSE_FAILED,
    payload: errors
});


export const upDownElementCourse = elem => ({
    type: UP_DOWN_ELEMENT_COURSE,
    payload: elem
});



