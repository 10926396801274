import React, {Component} from "react";
import {Translation} from "react-i18next";
import './index.css'
import Counter from "../Counter";
import {shortenString} from "../../../utils/utils";

class BusinessGameCard extends Component{

    render() {
        // Get props
        const {game, withCounter, isSelected, onClick, onCounterChange} = this.props;

        let class_name = "NE_business_game_card";
        class_name += isSelected ? "NE_business_game_card_selected" : "";

        return <Translation>
            {t => {
                return (
                    <div className={class_name} onClick={() => {onClick && onClick(game);}}>
                        <div className="card_header">
                            <div className="card_image">
                                <img src={game?.image} alt="Business game" />
                            </div>
                        </div>
                        <div className="card_body">
                            <h1>{game?.name}</h1>
                            {game?.remaining_sessions_count && <p>{t("business-game-card.sessions_title")}: {game?.remaining_sessions_count}</p>}
                            <p>{shortenString(game?.description,60)}</p>

                            {withCounter && <div className="counter_wrapper">
                                <p>{t("business-game-card.sessions_count_title")}</p>
                                <Counter initialValue={1} min={1} step={1} onChange={(value) => {onCounterChange(game.id,value)}} />
                            </div>}

                        </div>
                    </div>
                );
            }}
        </Translation>
    }
}
export default BusinessGameCard;