import array_move, {moveElementService} from "./array_move";
import {upDownElementCourse} from "../app/redux/moderator/edit-course/actions";
import {useDispatch, useSelector} from "react-redux";
import {editOrdersModuleService} from "../app/services/moderator.service";
import {useEffect, useState} from "react";


export default function useMoveElementCourse({element, current_course}) {
    const {elements,id:current_course_id} = useSelector((state)=>state.editCourse.current_course);

    const dispatch = useDispatch();
    const [indexItem, setIndexItem] = useState();
    const length  = current_course.elements.length ;


  useEffect(()=>{
      const indexItem_ = elements.sort((a,b)=> a.element_order - b.element_order ).findIndex(elm => elm.id === element.id && element.type === elm.type);
      setIndexItem(_=>indexItem_);
  })

    const moveElement = (type="up") => {


        const index = elements.findIndex(elm => elm.id === element.id && element.type === elm.type );

        let list = [];

        if (element.element_order < elements.length && type === "up") {
                if (elements.length - 1 ===  index) return;
              list = array_move(elements, index, index + 1 ).map((elm, index) => ({
                  ...elm,
                  element_order: index
              }));


        }else if (element.element_order !== 0){
            if (index === 0) return;
                list = array_move(elements, index,  index - 1).map((elm, index) => ({
                    ...elm,
                    element_order: index
                }));


        }

         dispatch(upDownElementCourse(list.filter(elm => elm.id)))

         const modules =  list.filter(element=> element.type === "MODULE").map(elm => ({id:elm.id,element_order:elm.element_order}));
         const evaluations =  list.filter(element=> element.type === "EVALUATION").map(elm => ({id:elm.id,element_order:elm.element_order}));
         const games =  list.filter(element=> element.type === "BUSINESS_GAME").map(elm => ({id:elm.id,element_order:elm.element_order}));
         const documents =  list.filter(element=> element.type === "DOCUMENTS").map(elm => ({id:elm.id,element_order:elm.element_order}));


         editOrdersModuleService(current_course_id ,modules,evaluations,games,documents).then()
    }

    return [moveElement,indexItem ,length]
}

