import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./index.css";
import Header from "../../../components/Header";
import {
    dispatchClickedItem,
    getDiscussionTrainingSession,
    getProgress,
    loadTrainingSession,
    updateProgress
} from "../../../redux/participant/participant-course-item/action-creators";
import EtudiantQuiz from "../../../components/EtudiantQuiz";
import CourseSidebar from "../../../components/CourseSidebar";
import Loader from "../../../components/Loader";
import EtudiantEvaluation from "../../../components/EtudiantEvaluation";
import EtudiantBusinessGame from "../../../components/EtudiantBusinessGame";
import EtudiantDocument from "../../../components/EtudiantDocument";
import {NotionItem} from "../../../components/NotionItem/NotionItem";
import EtudiantDiscussion from "../../../components/EtudiantDiscussion";
import {useTranslation} from "react-i18next";

const TRC = ({onClickItem, discussions, clickedItem, active_btn}) => {
     console.log("clickedItem2",clickedItem)
    const [state, setState] = useState(1);
    const {t} =  useTranslation();



    return (<div className="NE_notionItem_header_button">

        <div className={`  ${clickedItem?.type !== "discussion" ? "active_button" : ""} disabled_button`}
             onClick={() => {
                 setState(1);
                 onClickItem(clickedItem?.course);
             }}
        >
            <span>{t("course")}</span>
        </div>
        <div className={`${clickedItem?.type === "discussion" ? "active_button" : ""} disabled_button`} onClick={() => {

            const disc = discussions?.find((d) => d.reference_object === "module" && d.element_id === clickedItem.parentId);
            onClickItem({
                type: "discussion",
                ...disc,
                course: clickedItem
            })
            setState(2);
        }}>
            <span>{t("discussion")}</span>
        </div>
    </div>)
}


const ParticipantCourseItem = (props) => {

    const {
        is_loading,
        trainingSession,
        courseSidebar,
        clickedItem,
        discussions,
        progress
    } = useSelector(state => state.participant_course_item)

  
    const trainingSessionID = props.match.params.trainingSessionID;

    const dispatch = useDispatch();

    useEffect(() => {
        const trainingSessionID = props.match.params.trainingSessionID;
        dispatch(loadTrainingSession(trainingSessionID));
        dispatch(getDiscussionTrainingSession(trainingSessionID));
        dispatch(getProgress(trainingSessionID));
    }, []);

    useEffect(() => {

        const disccussionId = new URLSearchParams(props.location.search.substring(1)).get("disccussion");
        if (disccussionId) {
            const disc = discussions?.find((d) => "" + d.id === "" + disccussionId);


            if (disc) {

                let clickedItem = null;

                if (disc.reference_object === "notion" || disc.reference_object === "quiz") {
                    courseSidebar?.elements?.map(e => {
                            if (disc.reference_object === "notion") {
                                const elem = e.notions.find(n => n.id === disc.element_id)
                                if (elem) clickedItem = elem
                            }
                            if (disc.reference_object === "quizzes") {

                                const elem = e.quizzes.find(n => n.id === disc.element_id)
                                if (elem) clickedItem = elem
                            }
                        }
                    )
                }
                else if (disc.reference_object === "evaluation") {
                    const elem = courseSidebar?.evaluation?.find(n => n.id === disc.element_id)
                    if (elem) clickedItem = elem

                }
                else if (disc.reference_object === "business_game") {
                    const elem = courseSidebar?.businessGame?.find(n => n.id === disc.element_id)
                    if (elem) clickedItem = elem
                }
                else if (disc.reference_object === "documents") {
                    const elem = courseSidebar?.documents?.find(n => n.id === disc.element_id)
                    if (elem) clickedItem = elem

                }


                dispatch(dispatchClickedItem({
                    type: "discussion",
                    ...disc,
                    course: clickedItem
                }))
            }
        }
    }, [discussions, courseSidebar]);

    
    
    return (
        <div className="participant_item_page">
            <Loader isOpen={is_loading}/>

            <div className="page_header">
                <Header user="participant"/>
            </div>

            <div className="page_body">
                <div className="content" style={{paddingBottom: 20}}>
                    <CourseSidebar
                        clickedItem={clickedItem}
                        titleCourse={trainingSession?.course?.title}
                        imgCourse={trainingSession?.course?.image_path}
                        descriptionCourse={trainingSession?.course?.description}
                        progress={trainingSession?.progress}
                        sidebarData={courseSidebar}
                        done_elements={trainingSession?.done_elements}
                        onClickItem={(item) => {
                            const progressItem = progress?.progress_details?.find((p) => p.element_id === item?.id && p?.reference_object === item?.type)
                            if (progressItem?.element_status === 0) {
                                dispatch(updateProgress(trainingSessionID, progressItem.id))
                            }

                            dispatch(dispatchClickedItem(item));
                        }}
                        disccussion={new URLSearchParams(props.location.search.substring(1)).get(
                            "disccussion"
                        )}
                        listContent={trainingSession.content}
                    />

                    {clickedItem?.type === "discussion" && (
                        <EtudiantDiscussion
                            callbackfn={() => <TRC clickedItem={clickedItem}
                                                   discussions={discussions}
                                                   onClickItem={(item) => {
                                                       dispatch(dispatchClickedItem(item));
                                                   }}/>}

                            clickedItem={clickedItem}
                            discussions={discussions}
                            onClickItem={(item) => {
                                dispatch(dispatchClickedItem(item));
                            }}
                        />
                    )}

                    {clickedItem?.type === "notion" && (
                        <NotionItem
                            callbackfn={() => <TRC clickedItem={clickedItem}
                                                   discussions={discussions}
                                                   onClickItem={(item) => {
                                                       dispatch(dispatchClickedItem(item));
                                                   }}/>}
                            clickedItem={clickedItem}

                        />
                    )}
                    {clickedItem?.type === "quiz" && (
                        <EtudiantQuiz
                            callbackfn={() => <TRC clickedItem={clickedItem}
                                                   discussions={discussions}
                                                   onClickItem={(item) => {
                                                       dispatch(dispatchClickedItem(item));
                                                   }}/>}
                            clickedItem={clickedItem}
                        />
                    )}
                    {clickedItem?.type === "business_game" && (
                        <EtudiantBusinessGame
                            trainingSession={trainingSession}
                            callbackfn={() => <TRC clickedItem={clickedItem}
                                                   discussions={discussions}
                                                   onClickItem={(item) => {
                                                       dispatch(dispatchClickedItem(item));
                                                   }}/>}
                            clickedItem={clickedItem}

                        />
                    )}
                    {clickedItem?.type === "evaluation" && (
                        <EtudiantEvaluation
                            callbackfn={() => <TRC clickedItem={clickedItem}
                                                   discussions={discussions}
                                                   onClickItem={(item) => {
                                                       dispatch(dispatchClickedItem(item));
                                                   }}/>}
                            clickedItem={clickedItem}
                        />
                    )}
                    {clickedItem?.type === "documents" && (
                        <EtudiantDocument
                            callbackfn={() => <TRC clickedItem={clickedItem}
                                                   discussions={discussions}
                                                   onClickItem={(item) => {
                                                       dispatch(dispatchClickedItem(item));
                                                   }}/>}
                            clickedItem={clickedItem}


                        />
                    )}
                </div>
            </div>
        </div>
    );
}


export default ParticipantCourseItem;
