import React, {useEffect, useState} from "react";
import './index.css'
import addDocument from "./assets/add-document.svg"
import imageCloud from "./assets/icon-upload-cloud.svg"

const DocumentsUploader2 = (props) => {
    const {activeDelete = true} = props;

    const documentsUploadInput = React.createRef();
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        props.onChange(documents);
    }, [documents]);


    const handleDocumentsChange = (e) => {

        setDocuments([...documents, ...e.target.files])

    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDocumentsDrop = (e) => {

        e.preventDefault();
        e.stopPropagation();

    }

    return (<>

            <div className="NE_documents_uploader">

                <div className="drop_zone"
                     onDragOver={handleDragOver}
                     onDrop={handleDocumentsDrop}
                >
                    {/*<label htmlFor="upload-button">*/}
                    <img src={addDocument} alt="Add document icon"/>
                    <h1>Attached Documents</h1>
                    <p>Drag &amp; drop or <span onClick={() => {
                        documentsUploadInput.current.click();
                    }}>browse</span> your file here</p>
                    <input ref={documentsUploadInput} id="upload-button" type="file" accept=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.pptm,.xlsm"
                           multiple onChange={handleDocumentsChange} hidden/>
                    {/*</label>*/}
                </div>

                <div className="upload_vault">
                    {documents?.map((item, index) => {
                        return (
                            <div className="upload_item" key={index}>
                                <div className="upload_item_icon">
                                    <img src={imageCloud} alt="Upload"/>
                                </div>
                                <div className="upload_item_content">
                                    <div className="upload_item_header">
                                        <h1>{item.title ? item.title : (item.name ? item.name : "")}</h1>
                                        {activeDelete && <button onClick={(e) => {
                                            e.preventDefault();
                                            documents.splice(index, 1);
                                            setDocuments([...documents]);
                                        }}>Cancel</button>}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )

}
export default DocumentsUploader2;
