export const authHeaderKey = () => {
  const token = localStorage.getItem("NowEdge.current_user_token");
  return token ? `Bearer ${token}` : "";
};

export const shortenString = (str, len) => {
  if (str.length > len) {
    return str.substr(0, len - 3).concat("...");
  }

  return str;
};
