export const LOAD_ALL_COURSES = "MODERATOR.LOAD_ALL_COURSES";
export const LOAD_ALL_COURSES_SUCCESS = "MODERATOR.LOAD_ALL_COURSES_SUCCESS";
export const LOAD_ALL_COURSES_ERROR = "MODERATOR.LOAD_ALL_COURSES_ERROR";

export const LOAD_COURSE_BY_ID = "MODERATOR.LOAD_COURSE_BY_ID";
export const LOAD_COURSE_BY_ID_SUCCESS = "MODERATOR.LOAD_COURSE_BY_ID_SUCCESS";
export const LOAD_COURSE_BY_ID_FAILED = "MODERATOR.LOAD_COURSE_BY_ID_FAILED";

export const LOAD_ALL_CATEGORIES = "MODERATOR.LOAD_ALL_CATEGORIES";
export const LOAD_ALL_CATEGORIES_SUCCESS =
  "MODERATOR.LOAD_ALL_CATEGORIES_SUCCESS";
export const LOAD_ALL_CATEGORIES_FAILED =
  "MODERATOR.LOAD_ALL_CATEGORIES_FAILED";

export const LOAD_ALL_COMMENTS = "MODERATOR.LOAD_ALL_COMMENTS";
export const LOAD_ALL_COMMENTS_SUCCESS = "MODERATOR.LOAD_ALL_COMMENTS_SUCCESS";
export const LOAD_ALL_COMMENTS_FAILED = "MODERATOR.LOAD_ALL_COMMENTS_SUCCESS";

export const LOAD_COURSE_ELEMENTS = "MODERATOR.LOAD_COURSE_ELEMENTS";
export const LOAD_COURSE_ELEMENTS_SUCCESS =
  "MODERATOR.LOAD_COURSE_ELEMENTS_SUCCESS";
export const LOAD_COURSE_ELEMENTS_FAILED =
  "MODERATOR.LOAD_COURSE_ELEMENTS_FAILED";

export const CLONE_COURSE_ELEMENTS = "MODERATOR.CLONE_COURSE_ELEMENTS";
export const CLONE_COURSE_ELEMENTS_SUCCESS =
  "MODERATOR.CLONE_COURSE_ELEMENTS_SUCCESS";
export const CLONE_COURSE_ELEMENTS_FAILED =
  "MODERATOR.CLONE_COURSE_ELEMENTS_FAILED";
export const COURSE_CANNOT_DELETED =
  "MODERATOR.COURSE_CANNOT_DELETED";



export const DELETE_COURSE = "MODERATOR.DELETE_COURSE";
export const DELETE_COURSE_SUCCESS = "MODERATOR.DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILED = "MODERATOR.DELETE_COURSE_FAILED";
