import React,{Component} from "react";
import './index.css';

const Textarea = ({label,message,onChange,isValid,id,placeholder,value,maxLength=1000, ...rest})=>{


        return (
            <div className="NE_normal_textarea NE_normal_textarea_success NE_normal_textarea_error">
                <label htmlFor={id}>{label}</label>
                <textarea id={id} placeholder={placeholder} onChange={onChange} value={value || ''} {...rest} maxLength={maxLength}/>
                {(isValid !== undefined) && <p>{message}</p>}
            </div>
        );

}
export default Textarea;
